import { all } from 'redux-saga/effects'
import CodeSagas from './CodeSagas'
import BannerSaga from './BannerSaga'
import UploadImageSagas from './UploadImageSagas'
import memberSaga from './memberSaga'
import menuSaga from './menuSaga'

export default function* rootSaga(getState) {
  yield all([CodeSagas(), BannerSaga(), UploadImageSagas(), memberSaga(), menuSaga()])
}
