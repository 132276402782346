import { BANNER_GET_BANNER_SUCCESS, BANNER_GET_PACKAG_SUCCESS, BANNER_CLOSE_POPUP } from '../actions/types'

const INIT_STATE = {
  data: [],
  carData: {
    ECO: {
      max_luggages: 3,
      max_persons: 3,
    },
    SUV: {
      max_luggages: 4,
      max_persons: 4,
    },
    VAN: {
      max_luggages: 6,
      max_persons: 6,
    },
  },
  package: [],
  status: 0,
  displayPopup: true,
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BANNER_GET_BANNER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case BANNER_GET_PACKAG_SUCCESS: {
      return {
        ...state,
        package: action.payload,
      }
    }

    case BANNER_CLOSE_POPUP: {
      document.body.classList.remove('modal-open')
      return {
        ...state,
        displayPopup: false,
      }
    }

    default:
      return state
  }
}
