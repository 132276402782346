import React, { Component } from 'react'

import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import TermModalFooter from '../../components/Modal/termModalFooter'

class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="footer-desktop d-none d-md-flex">
          <TermModalFooter />
          <a href="/privacy-policy" className="ml-5">
            Privacy Policy
          </a>
          <div className="mr-auto"></div>
          <span className="pull-right">Copyright ©2020 White Glove Delivery by World Reward Solutions.</span>
        </footer>
      </div>
    )
  }
}

export default Footer
