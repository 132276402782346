import React from 'react'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function PopupLayout(props) {
  return (
    <div>
      <div
        className={'modal fade ' + (props.display ? 'show' : '')}
        id="consent_form"
        tabIndex="999999"
        role="dialog"
        style={props.display ? { display: 'block' } : {}}
      >
        <div className="modal-dialog modal-dialog-centered " role="document">
          <div className="modal-content ">{props.children}</div>
        </div>
      </div>
    </div>
  )
}

export default PopupLayout
