import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactHtmlParser from 'react-html-parser'
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  CarouselCaption,
} from 'reactstrap'
import imgCar from '../assets/imgs/car-1.png'
import { Translation } from 'react-i18next'
import logo from '../assets/imgs/white-glove.png'
import { menuListFetch } from '../actions'
import restaurant1 from '../assets/imgs/JW.png'
import restaurant2 from '../assets/imgs/supanniga.png'
import restaurant3 from '../assets/imgs/coffeebeans.png'
import restaurant4 from '../assets/imgs/wineconnection.png'
import restaurant5 from '../assets/imgs/somboon.png'
import restaurant6 from '../assets/imgs/dusitthani.png'
import restaurant7 from '../assets/imgs/Plu.png'
import restaurant8 from '../assets/imgs/WL.png'
import restaurant9 from '../assets/imgs/attarote.png'
import restaurant10 from '../assets/imgs/HongBao.png'
import restaurant11 from '../assets/imgs/Nara.png'
import restaurant12 from '../assets/imgs/SKH.png'
import restaurant13 from '../assets/imgs/BanyanTree.png'
import restaurant14 from '../assets/imgs/SushiMori.jpg'
import restaurant15 from '../assets/imgs/Kumpoon.png'
import restaurant16 from '../assets/imgs/Jambo.png'
import restaurant17 from '../assets/imgs/Chatrium-Hotel.png'
import FoodForFight from '../assets/imgs/FoodForFighter.png'

import AMEX from '../assets/imgs/american-express-logo.png'
import UOB from '../assets/imgs/LogoPVB-01.png'
import WISDOM from '../assets/imgs/WISDOM-LOGO-Asset.png'

import { Link } from 'react-router-dom'
import { popupError, GetCodeValid, popupSuccess, resetDiscountCode } from '../actions'
import { LinkTo } from '@storybook/addon-links'
import CookieConsent, { Cookies } from 'react-cookie-consent'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      showTermModal: false,
      menuIndex: 0,
      maxMenu: 0,
      menuList: [],
      stateType: 0,
      validationCodeFromServer: {},
      code: '',
      restaurant_Item_ref: [],
      codeInputLock: false,
      valid: false,
      flagValidCouponCode: false,
      selectRestaurant: '',
      bannerShow: true,
      activeIndex: 0,
      animating: false,
      restaurant: [],
      banner: [],
    }
  }

  componentDidMount() {
    this.props.menuListFetch()
    this.props.resetDiscountCode()
  }

  componentDidUpdate() {
    var selectedCoupon =
      this.props.coupon.validationCodeFromServer || this.props.coupon.validationCodeDisCountFromMonday[0]

    //read ref item of coupon
    //TODO : if blank , all menu is always pass
    // console.log(selectedCoupon)
    //TODO: pass selected coupon to form
    if (!this.state.valid && selectedCoupon && selectedCoupon.hasOwnProperty('id')) {
      // let refMenu =  selectedCoupon.hasOwnProperty("link_to_item") && selectedCoupon.link_to_item ? JSON.parse(selectedCoupon.link_to_item).linkedPulseIds || [] : []
      let refMenu =
        selectedCoupon.hasOwnProperty('text5') && selectedCoupon.text5
          ? selectedCoupon.text5.replace(/"/g, '').split(',') || []
          : selectedCoupon.hasOwnProperty('text61') && selectedCoupon.text61
          ? selectedCoupon.text61.replace(/"/g, '').split(',') || []
          : []
          
      if (refMenu.length === 1) {
        this.setState({
          showTermModal: true,
          valid: true,
          selectedCoupon: selectedCoupon,
        })
      } else {
        this.setState({
          valid: true,
          showModal: true,
          selectedCoupon: selectedCoupon,
        })
      }
    }
  }

  onChange = val => {
    let str_code = val.target.value
    let str_code_length = str_code.length
    this.setState({
      code: str_code,
    })
    //  code:str_code.toUpperCase()
    if (str_code_length >= 4 && !this.state.flagValidCouponCode) {
      this.setState({
        stateType: 1,
      })
    } else {
      this.setState({
        stateType: 0,
      })
    }
  }
  prevMenu = () => {
    this.setState({
      menuIndex: this.state.menuIndex === 0 ? this.state.maxMenu - 1 : this.state.menuIndex - 1,
    })
  }

  nextMenu = () => {
    this.setState({
      menuIndex: this.state.menuIndex === this.state.maxMenu - 1 ? 0 : this.state.menuIndex + 1,
    })
  }
  checkCodeManual = () => {
    // this.props.GetCodeValid(this.state.code.toUpperCase())
    this.props.GetCodeValid(this.state.code)
  }

  toggle = () => {
    this.setState({ bannerShow: !this.state.bannerShow })
  }

  formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  openError = () => {
    this.props.popupError('ขออภัย ขณะนี้ร้านอาหารดังกล่าว ไม่เปิดให้บริการ')
    this.setState({
      showModal: false,
    })
  }
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    if (nextProps.showModal === true && 'no - couponValid') {
      this.props.clickMenu(false)
      this.setState({
        showModal: true,
      })
    }
  }

  next = () => {
    if (this.state.animating) return
    const nextIndex = this.state.activeIndex === this.props.menu.banner.length - 1 ? 0 : this.state.activeIndex + 1

    this.setState({
      activeIndex: nextIndex,
    })
  }

  previous = () => {
    if (this.state.animating) return
    const nextIndex = this.state.activeIndex === 0 ? this.props.menu.banner.length - 1 : this.state.activeIndex - 1
    this.setState({
      activeIndex: nextIndex,
    })
  }

  goToIndex = newIndex => {
    if (this.state.animating) return
    this.setState({
      activeIndex: newIndex,
    })
  }

  setAnimating = bool => {
    this.setState({
      animating: bool,
    })
  }

  render() {
    const { menu } = this.props

    console.log("MENU INDEX: ",this.state.menuIndex)

    let lang = window.localStorage.getItem('i18nextLng') ? window.localStorage.getItem('i18nextLng') : 'th'
    if (menu && menu.list && menu.list.length === 0) {
      return <div>Loading...E</div>
    }

    //let ref_item = this.state.selectedCoupon && this.state.selectedCoupon.hasOwnProperty("link_to_item") && this.state.selectedCoupon.link_to_item && JSON.parse(this.state.selectedCoupon.link_to_item).linkedPulseIds ?  JSON.parse(this.state.selectedCoupon.link_to_item).linkedPulseIds.map( item => item.linkedPulseId) || [] : []
    let ref_item =
      this.state.selectedCoupon &&
      this.state.selectedCoupon.hasOwnProperty('text5') &&
      this.state.selectedCoupon.text5 &&
      this.state.selectedCoupon.text5.replace(/"/g, '').split(',').length > 0
        ? this.state.selectedCoupon.text5
            .replace(/"/g, '')
            .split(',')
            .map(item => item) || []
        : this.state.selectedCoupon &&
          this.state.selectedCoupon.hasOwnProperty('text61') &&
          this.state.selectedCoupon.text61 &&
          this.state.selectedCoupon.text61.replace(/"/g, '').split(',').length > 0
        ? this.state.selectedCoupon.text61
            .replace(/"/g, '')
            .split(',')
            .map(item => item) || []
        : []
    // console.log(ref_item)

    if (this.state.selectedCoupon !== undefined && !this.state.codeInputLock && this.state.maxMenu !== 0) {
      if (ref_item.length > 0) {

        console.log("REF MENU : ", menu.list.filter(item => {
          return (
            item.long_text &&
            item.status &&
            (JSON.parse(item.status).index === 1 || JSON.parse(item.status).index === 0) &&
            ref_item.findIndex(data => item.id === data) !== -1
          )
        }))
        
        this.setState({
          maxMenu:
            menu.list.filter(
              item =>
                item.long_text &&
                item.status &&
                (JSON.parse(item.status).index === 1 || JSON.parse(item.status).index === 0) &&
                ref_item.findIndex(data => item.id === data) !== -1
            ).length,
          menuList: menu.list.filter(item => {
            return (
              item.long_text &&
              item.status &&
              (JSON.parse(item.status).index === 1 || JSON.parse(item.status).index === 0) &&
              ref_item.findIndex(data => item.id === data) !== -1
            )
          }),
          ref_item: ref_item,
          codeInputLock: true,
        })
      }
    }

    // debugger

    if (
      menu.list.filter(item => item.long_text.text && item.status && item.status.value && ( item.status.value.index === 1 || item.status.value.index === 0 )).length !== 0 &&
      this.state.maxMenu === 0 &&
      ref_item.length === 0
    ) {
      if (this.state.selectRestaurant) {
        
        this.setState({
          maxMenu: menu.list.filter(
            item =>
              item.long_text.text &&
              item.status &&
              item.status.value &&
              (item.status.value.index === 1 || item.status.value.index === 0) &&
              item.text.text &&
              item.text.text.search(this.state.selectRestaurant) !== -1
          ).length,
          menuList: menu.list.filter(
            item =>
              item.long_text.text &&
              item.status &&
              item.status.value &&
              (item.status.value.index === 1 || item.status.value.index === 0) &&
              item.text.text &&
              item.text.text.search(this.state.selectRestaurant) !== -1
          ),
        })
      } else {
        this.setState({
          maxMenu:
            menu.list.filter(item => item.long_text.text && item.status && item.status.value && ( item.status.value.index === 1 || item.status.value.index === 0 ) ).length - 1,
            menuList: menu.list.filter(item => item.long_text.text && item.status && item.status.value && (item.status.value.index === 1 || item.status.value.index === 0)),
        })
      }
    }

    // debugger

    if (this.state.menuList.length === 0 && !this.state.selectRestaurant) {
      return <div>Loading...</div>
    }
    if (this.state.showModal === true && this.state.menuList.length === 0) {
      this.openError()
    }
    // console.log(this.state.showModal, this.state.menuList.length, document.body.classList)
    //Filtert Link to pulse id
    if (this.state.showModal === true && this.state.menuList.length > 0) {
      document.body.classList.add('modal-open-manual')
    } else {
      document.body.classList.remove('modal-open-manual')
    }
    let index = 0

    let restaurant = [], last_restaurant = []
    
    for (let index = 0; index < this.props.menu.restaurant.length - 2; index += 2) {
      restaurant.push([this.props.menu.restaurant[index], this.props.menu.restaurant[index + 1]])
    }
    if (this.props.menu.restaurant.length % 2) {
      last_restaurant.push([this.props.menu.restaurant[this.props.menu.restaurant.length - 1]])
    } else {
      last_restaurant.push([
        this.props.menu.restaurant[this.props.menu.restaurant.length - 2],
        this.props.menu.restaurant[this.props.menu.restaurant.length - 1],
      ])
    }
    // console.log('Cookies', Cookies.get('CookieConsent'))

    // console.log(this.state.ref_item, this.state.menuIndex, this.state.menuList)

    return (
      <div>
        <div className="bg-circle"></div>
        <div className="home-detail w-90">
          <div className="title">
            WHITE GLOVE <img alt="" className="d-md-none" src={logo} />
            DELIVERY
            <div style={{ clear: 'both' }}></div>
          </div>
          <div className="description">
            <Translation>{(t, { i18n }) => t('description.first')}</Translation>
            <br />
            <Translation>{(t, { i18n }) => t('description.second1')}</Translation> &ensp;
            <Translation>{(t, { i18n }) => t('description.second2')}</Translation>
            {/* <b></b> */}
            {/* &ensp;<Translation>{(t, { i18n }) => t('description.second3')}</Translation> */}
          </div>
          <p>
            <Translation>{(t, { i18n }) => t('description.third')}</Translation>
          </p>
        </div>

        <div className="home-bar d-none d-md-flex">
          <div className="div-img-restaurant">
            <div className="row">
              <div
                className="col-10 textRestaurant text-center font-italic bg-white"
                style={{ borderTopRightRadius: '30px' }}
              >
                - <Translation>{(t, { i18n }) => t('premiumlist')}</Translation> -
                <div className="desktopLogo">
                  <img alt="" className="desktopLogoImg" src={logo} />
                </div>
              </div>
            </div>

            {this.props.menu && this.props.menu.restaurant.length > 0
              ? restaurant.map(item => {
                  return (
                    <div className="row">
                      <div className="col-5 text-center bg-white">
                        <div
                          className="res"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              selectRestaurant: item[0] ? item[0].slug : '',
                              maxMenu: 0,
                            })
                          }}
                        >
                          <img alt="" src={item[0] ? item[0].img : ''} />
                        </div>
                      </div>
                      <div className="col-5 text-center bg-white">
                        <div
                          className="res"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              selectRestaurant: item[1] ? item[1].slug : '',
                              maxMenu: 0,
                            })
                          }}
                        >
                          <img alt="" src={item[1] ? item[1].img : ''} style={{ paddingTop: '10px' }} />
                        </div>
                      </div>
                    </div>
                  )
                })
              : ''}

            {/* Last row of restaurant*/}
            {
              <div className="row last">
                <div className="col-5 text-center bg-white">
                  <div
                    className="res"
                    onClick={() => {
                      this.setState({
                        showModal: true,
                        selectRestaurant: last_restaurant[0][0] ? last_restaurant[0][0].slug : '',
                        maxMenu: 0,
                      })
                    }}
                  >
                    <img alt="" src={last_restaurant[0][0] ? last_restaurant[0][0].img : ''} />
                  </div>
                </div>
                <div className="col-5 text-center bg-white" style={{ borderBottomRightRadius: '30px' }}>
                  <div
                    className="res"
                    onClick={() => {
                      this.setState({
                        showModal: true,
                        selectRestaurant: last_restaurant[0][1] ? last_restaurant[0][1].slug : '',
                        maxMenu: 0,
                      })
                    }}
                  >
                    <img alt="" src={last_restaurant[0][1] ? last_restaurant[0][1].img : ''} />
                  </div>
                </div>
              </div>
            }

            <div className="row btnBookingM">
              <div className="col-10 text-center">
                <input
                  type="text"
                  name={'code'}
                  className="form-control my-lg-5 buttonBookMD"
                  onChange={val => this.onChange(val)}
                  value={this.state.code}
                  placeholder={lang === 'th' ? 'กรอกรหัสคูปองสำหรับลูกค้าองค์กร' : 'Coupon Codes - Corporate customers'}
                />
                <button
                  className="btn-next buttonBookMD"
                  onClick={() => {
                    this.state.stateType === 0
                      ? this.setState({
                          showModal: true,
                          selectRestaurant: '',
                          maxMenu: 0,
                          menuIndex: 0,
                        })
                      : this.props.GetCodeValid(this.state.code)
                  }}
                >
                  {this.state.stateType === 0 ? 'BOOK NOW' : 'BOOK'}
                </button>
              </div>
            </div>
          </div>

          <div className="div-img-car">
            <img alt="" src={imgCar} className="w-100" />
          </div>
        </div>

        <div className="home-bar-mobile d-block d-md-none">
          <div className="div-img-car">
            <img alt="" src={imgCar} className="w-100" />
          </div>
          <div className="home-tab-mobile">
            <div>
              <InputGroup>
                <Input
                  type="text"
                  name={'code'}
                  className="form-control-top my-3 "
                  onChange={val => this.onChange(val)}
                  value={this.state.code}
                  placeholder={
                    lang === 'th' ? 'กรอกรหัสคูปองสำหรับลูกค้าองค์กร' : 'Coupon Codes - Corporate customers '
                  }
                />
                <InputGroupAddon addonType="append">
                  <button
                    className="btn-next-upper"
                    disabled={this.state.code && this.state.code.length > 3 ? false : true}
                    onClick={() => {
                      if (this.state.code) {
                        this.props.GetCodeValid(this.state.code)
                      } else {
                        this.props.popupError(lang === 'th' ? 'กรุณากรอกรหัสคูปอง' : 'Please typing your coupon code.')
                      }
                    }}
                  >
                    {'BOOK'}
                  </button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            <div></div>
          </div>

          <div className="div-img-restaurant">
            <div className="row">
              <div
                class="col-12 text-center font-italic bg-white"
                style={{
                  borderTopLeftRadius: '30px',
                  borderTopRightRadius: '30px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                }}
              >
                - <Translation>{(t, { i18n }) => t('premiumlist')}</Translation> -
              </div>
            </div>
            {this.props.menu && this.props.menu.restaurant.length > 0
              ? restaurant.map(item => {
                  return (
                    <div className="row">
                      <div class="res col-6 text-center bg-white">
                        <img
                          alt=""
                          className="d-md-none"
                          src={item[0] ? item[0].img : ''}
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              selectRestaurant: item[0] ? item[0].slug : '',
                              maxMenu: 0,
                            })
                          }}
                        />
                      </div>
                      <div class="res col-6 text-center bg-white">
                        <img
                          alt=""
                          className="d-md-none"
                          src={item[1] ? item[1].img : ''}
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              selectRestaurant: item[1] ? item[1].slug : '',
                              maxMenu: 0,
                            })
                          }}
                        />
                      </div>
                    </div>
                  )
                })
              : ''}
            {
              <div className="row">
                <div class="col-6 text-center bg-white" style={{ borderBottomLeftRadius: '30px' }}>
                  <img
                    alt=""
                    className="d-md-none"
                    src={last_restaurant[0][0] ? last_restaurant[0][0].img : ''}
                    onClick={() => {
                      this.setState({
                        showModal: true,
                        selectRestaurant: last_restaurant[0][0] ? last_restaurant[0][0].slug : '',
                        maxMenu: 0,
                      })
                    }}
                  />
                </div>
                <div class="col-6 text-center bg-white" style={{ borderBottomRightRadius: '30px' }}>
                  <img
                    alt=""
                    className="d-md-none"
                    src={last_restaurant[0][1] ? last_restaurant[0][1].img : ''}
                    onClick={() => {
                      this.setState({
                        showModal: true,
                        selectRestaurant: last_restaurant[0][1] ? last_restaurant[0][1].slug : '',
                        maxMenu: 0,
                      })
                    }}
                  />
                </div>
              </div>
            }
          </div>
          <div className="home-tab-mobile">
            <div>
              <button
                className="btn-next"
                onClick={() => {
                  this.state.stateType === 0
                    ? this.setState({
                        showModal: true,
                        selectRestaurant: '',
                        maxMenu: 0,
                        menuIndex: 0,
                      })
                    : this.props.GetCodeValid(this.state.code)
                }}
              >
                {this.state.stateType === 0 ? 'BOOK NOW' : 'BOOK'}
              </button>
            </div>
          </div>
        </div>

        <div className="home-clear-space"></div>
        <Modal isOpen={this.state.showTermModal} className={`modal-md`}>
          <div className="detail-1">
            <div className="modal-header mt-3">
              <span className="modal-title" id="consent_form_title">
                Terms & Conditions
              </span>
            </div>

            <div className="modal-body">
              <b>Food Delivery - Terms and conditions</b>
              <br />
              <br />
              <ol>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.1')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => ReactHtmlParser(t('consent.2'))}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.3')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.4')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.5')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.6')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.7')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.8')}</Translation>
                </li>
                <li>
                  <Translation>{(t, { i18n }) => t('consent.9')}</Translation>
                </li>
              </ol>

              <div className="home-bar-mobile d-block">
                <div className="home-tab-mobile">
                  <div className="mb-3">
                    <Link
                      to={
                        '/booking/' +
                        (this.state.ref_item
                          ? this.state.menuList[this.state.menuIndex].id
                          : this.state.menuList[this.state.menuIndex]
                          ? this.state.menuList[this.state.menuIndex].id
                          : 0)
                      }
                    >
                      <button className="btn-booking w-100 mt-4">
                        {' '}
                        <Translation>{(t, { i18n }) => t('ACCEPTANDBOOKING')}</Translation>{' '}
                      </button>
                    </Link>
                    <button
                      type="button"
                      id="btn-cancel"
                      class="btn-booking-clear  w-100  mt-4"
                      ref={input => (this.btnShowModal = input)}
                      onClick={() => {
                        // console.log(this.state)
                        this.setState({ showModal: true, showTermModal: false })
                      }}
                    >
                      <Translation>{(t, { i18n }) => t('Back')}</Translation>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal isOpen={this.state.showModal && 'no - couponValid'} className="modal-lg  modalModal food-menu-modal">
          <ModalBody>
            <div className="modal-header mt-4">
              <span className="modal-title" id="consent_form_title">
                MENU
              </span>
              <p>{this.state.selectedCoupon ? this.state.selectedCoupon.name : ''}</p>
            </div>
            <div className="forNav">
              <div className="navButton">
                <div
                  className="left"
                  onClick={() => {
                    this.prevMenu()
                  }}
                >
                  <span class="icon-arrow-left"></span>
                </div>
                <div
                  className="right"
                  onClick={() => {
                    this.nextMenu()
                  }}
                >
                  <span class="icon-arrow-right"></span>
                </div>
              </div>
              <div className="home-menu-list">
                <div className="border1"></div>
                <div className="border2"></div>
                <center className="menu-detail">
                  {this.state.menuList.map((item, index)=> {
                    let menuOrderList = []
                    if (item.long_text && item.status && (item.status.value.index === 1 || item.status.value.index === 0)) {
                      const menuOrder = lang === 'th' ? item.long_text : item.long_text5
                      if (menuOrder && menuOrder.text) {
                        menuOrderList = menuOrder.text.split('\n')
                      } else if (!menuOrder) {
                        const menuOrder2 = lang === 'th' ? item.long_text5 : item.long_text
                        if (menuOrder2 && menuOrder2.text) {
                          menuOrderList = menuOrder2.text.split('\n')
                        }
                      }
                      // debugger
                      return (
                          <div style={{ display: this.state.menuIndex === index ? 'block' : 'none' }} className="menu-list">
                          
                          <br />
                          <br />
                          <br />
                          <span className="menu-name">{item.text.text.replace(/"/gi, '')}</span>
  
                          <span className="menu-set-name">Set {this.formatNumber(item.text4.text.replace(/"/gi, ''))}</span>
                          <br />
                          {menuOrderList.map(data => {
                            // debugger
                            return (
                              <p>
                                {data}
                                <br />
                                <br />
                              </p>
                            )
                          })}
                          <div style={{ display: 'none' }}>{index++}</div>
                        </div>
                      )

                    } else {
                      return false
                    }
                  })}
                </center>
              </div>

              <div className="home-bar d-flex mb-4">
                <div className="home-tab  mx-auto">
                  <div className="mb-4">
                    <center>
                      <button
                        type="button"
                        id="btn-cancel"
                        class="btn-booking-clear mr-4"
                        onClick={() => {
                          this.setState({ showModal: false, menuIndex: 0})
                        }}
                      >
                        BACK
                      </button>
                      {/*
                                  <Link to={"/booking/" + this.state.menuList[this.state.menuIndex].id}>
                                    <button className="btn-booking">SELECT</button>
                                  </Link>
                                  */}
                      <button
                        className="btn-booking"
                        onClick={() => {
                          this.setState({ showModal: false, showTermModal: true })
                        }}
                      >
                        SELECT
                      </button>
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {this.props.menu.banner &&
        this.props.menu.banner.length &&
        this.props.menu.banner.length !== 0 &&
        this.state.bannerShow ? (
          <Modal isOpen={this.state.bannerShow} toggle={this.toggle} centered={true} className={'modal-lg'}>
            <Carousel activeIndex={this.state.activeIndex} next={this.next} previous={this.previous}>
              <CarouselIndicators
                className="Indicator"
                items={this.props.menu.banner}
                activeIndex={this.state.activeIndex}
                onClickHandler={this.goToIndex}
              />

              {this.props.menu.banner.map(item => {
                return (
                  <CarouselItem
                    className="custom-tag align-middle"
                    onExiting={() => this.setAnimating(true)}
                    onExited={() => this.setAnimating(false)}
                    key={item.header}
                  >
                    <div className="row banner-modal">
                      <div className="col"></div>
                      <div className="col">
                        <img className="img-banner" src={item.src} />{' '}
                      </div>
                      <div className="col"></div>
                    </div>
                    <CarouselCaption
                      className="text-banner d-block text-dark"
                      captionText={lang === 'th' ? item.caption_th : item.caption_en}
                      captionHeader={item.header}
                    />
                  </CarouselItem>
                )
              })}
              <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
            </Carousel>
            <div
              style={{ textAlign: 'center', background: ' #fff', borderRadius: '0px 0px 15px 15px' }}
              className={'py-3'}
            >
              {' '}
              <Button color="secondary" size="lg" onClick={this.toggle}>
                {' '}
                <Translation>{(t, { i18n }) => t('close')}</Translation>{' '}
              </Button>{' '}
            </div>
          </Modal>
        ) : (
          ''
        )}

        <a href="https://www.facebook.com/FoodForFightersTH" target="_blank">
          <div className={'floating_text'} style={Cookies.get('CookieConsent') ? {} : { marginBottom: '80px' }}>
            <p>
              {' '}
              *รายได้ส่วนหนึ่งนำไปสบทบ <br />
              ทุนโครงการข้าวเพื่อหมอ{' '}
            </p>
          </div>

          <div className={'floating_logo'} style={Cookies.get('CookieConsent') ? {} : { marginBottom: '80px' }}>
            <img alt={''} src={FoodForFight} />
          </div>
        </a>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup, banner, menu }) => {
  return { coupon, popup, banner, menu }
}

export default connect(mapStateToProps, {
  popupError,
  resetDiscountCode,
  GetCodeValid,
  popupSuccess,
  menuListFetch,
})(Home)
