import React, { Component } from 'react'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import '../../assets/css/app.css'
import { popupHidden, getBanner, getPackage, menuListFetch } from '../../actions'
import { Link } from 'react-router-dom'

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

class SelectMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuIndex: 0,
      maxMenu: 0,
      menuList: [],
      modalSeleteMenu: false,
      firstname: null,
      lastname: null,
      phone: null,
      email: null,
      bookingDate: null,
      bookingTime: null,
      address: null,
      errorRequireFirstName: false,
      errorRequireLastName: false,
      errorRequirePhone: false,
      errorRequireEmail: false,
      errorRequireBookingDate: false,
      minDate: addDays(new Date(), 1),
      minTime: addDays(new Date(), 1),
      datetime: '',
      errorRequireBookingTime: false,
      errorRequireAddress: false,
    }
  }
  componentDidMount() {
    // this.props.getBanner();
    this.props.menuListFetch()
  }

  prev = () => {
    this.setState({
      menuIndex: this.state.menuIndex === 0 ? this.state.maxMenu : this.state.menuIndex - 1,
    })
  }

  next = () => {
    this.setState({
      menuIndex: this.state.menuIndex === this.state.maxMenu ? 0 : this.state.menuIndex + 1,
    })
  }

  handleInputChange = (value, name) => {
    this.setState({ [name]: value })
  }

  onchangeDate = date => {
    var diff = (date.getTime() - new Date().getTime()) / (1000 * 3600 * 24)

    this.setState({
      datetime: date,
      bookingDate: date,
      minTime: diff >= 1 ? new Date('Sat Jan 11 2020 00:00:00 GMT+0700') : addDays(new Date(), 1),
      bookingTime: date.getHours() + ':' + date.getMinutes(),
      errorRequireBookingDate: date !== '' && diff >= 1 ? false : true,
    })
  }

  render() {
    const { menu } = this.props
    if (menu && menu.list && menu.list.length === 0) {
      return <div>Loading...</div>
    }
    if (menu.list.filter(item => item.long_text).length !== 0 && this.state.maxMenu === 0) {
      this.setState({
        maxMenu: menu.list.filter(item => item.long_text).length - 1,
        menuList: menu.list.filter(item => item.long_text),
      })
    }

    let index = 0
    return (
      <div>
        <div className="bg-circle"></div>
        {this.state.modalSeleteMenu === false ? (
          <div className="modal-dialog selectModal h-90">
            <div className="modal-content">
              <div className="modal-body">
                <div className="forNav">
                  <div className="navButton">
                    <div
                      className="left"
                      onClick={() => {
                        this.prev()
                      }}
                    >
                      {'<'}
                    </div>
                    <div
                      className="right"
                      onClick={() => {
                        this.next()
                      }}
                    >
                      {'>'}
                    </div>
                  </div>
                  <div className="home-menu-list w-80">
                    <div className="border1 w-100  h-90">
                      <div className="border2 w-100 h-100">
                        <center>
                          {menu &&
                            menu.list.map(item => {
                              let menuOrderList = []
                              if (item.long_text) {
                                const menuOrder = JSON.parse(item.long_text)
                                if (menuOrder.text) {
                                  menuOrderList = menuOrder.text.split('\n')
                                }
                              } else {
                                return false
                              }
                              return (
                                <div style={{ display: this.state.menuIndex === index ? 'block' : 'none' }}>
                                  <br />
                                  <h4>{item.text}</h4>
                                  <br />
                                  <h5>Set {item.text4}</h5>
                                  <br />
                                  {menuOrderList.map(data => {
                                    return (
                                      <p>
                                        {data}
                                        <br />
                                        <br />
                                      </p>
                                    )
                                  })}
                                  <div style={{ display: 'none' }}>{index++}</div>
                                </div>
                              )
                            })}
                        </center>
                      </div>
                    </div>
                  </div>

                  <div className="home-bar d-none d-md-flex">
                    <div className="home-tab">
                      <div>
                        <div>
                          <Link to={'/booking/' + 123456}>
                            <button className="btn-next">SELECT</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="home-bar-mobile d-block d-md-none">
                    <div className="home-tab-mobile">
                      <div>
                        <Link to={'/booking/' + 123456}>
                          <button className="btn-next">SELECT</button>
                        </Link>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container booking-page">
            <div className="page-circle"></div>
            <div className="title-page">Booking</div>
            <form onSubmit={this.handleSubmit}>
              <div className="booking-form">
                <h3>Personal Information</h3>
                <div>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    className="form-control"
                    style={this.formStyle}
                    placeholder={'Firstname'}
                    onChange={e => {
                      this.handleInputChange(e.target.value, 'firstname')
                      this.setState({
                        errorRequireFirstName: e.target.value.trim() !== '' ? false : true,
                      })
                    }}
                    value={this.state.firstname}
                  />
                  {this.state.errorRequireFirstName === true ? (
                    <p style={{ color: 'red' }}>*Please enter first name</p>
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <div>
                  <input
                    type="text"
                    id="lastname"
                    name="lastname"
                    className="form-control"
                    style={this.formStyle}
                    placeholder={'Lastname'}
                    onChange={e => {
                      this.handleInputChange(e.target.value, 'lastname')
                      this.setState({
                        errorRequireLastName: e.target.value.trim() !== '' ? false : true,
                      })
                    }}
                    value={this.state.lastname}
                  />
                  {this.state.errorRequireLastName === true ? (
                    <p style={{ color: 'red' }}>*Please enter Last Name</p>
                  ) : (
                    ''
                  )}
                </div>
                <br />
                <div>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    className="form-control"
                    style={this.formStyle}
                    placeholder={'Phone'}
                    onChange={e => {
                      this.handleInputChange(e.target.value, 'phone')
                      this.setState({
                        errorRequirePhone: e.target.value.trim() !== '' ? false : true,
                      })
                    }}
                    value={this.state.phone}
                  />
                  {this.state.errorRequirePhone === true ? <p style={{ color: 'red' }}>*Please enter Phone</p> : ''}
                </div>
                <br />
                <div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="form-control"
                    style={this.formStyle}
                    placeholder={'Email'}
                    onChange={e => {
                      this.handleInputChange(e.target.value, 'email')
                      this.setState({
                        errorRequireEmail: e.target.value.trim() !== '' ? false : true,
                      })
                    }}
                    value={this.state.email}
                  />
                  {this.state.errorRequireEmail === true ? <p style={{ color: 'red' }}>*Please enter Email</p> : ''}
                </div>
                <br />
                <div>
                  <DatePicker
                    id="datepicker"
                    className="form-control"
                    placeholderText={'BookingDate'}
                    dateFormat="dd/MM/yyyy - HH:mm"
                    style={{ width: '100% !important' }}
                    autoComplete={'off'}
                    // ค้างให้แสดงเป็น ช่วงเวลา 11-12  และ 16-18  มั้ง!!
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="time"
                    minTime={this.state.minTime}
                    maxTime={new Date('Sat Jan 11 2020 23:59:59 GMT+0700')}
                    selected={this.state.datetime}
                    minDate={this.state.minDate !== undefined ? new Date(this.state.minDate) : addDays(new Date(), 1)}
                    maxDate={this.state.maxDate === '' ? '' : new Date(this.state.maxDate)}
                    onChange={this.onchangeDate}
                  />
                  {this.state.errorRequireBookingDate === true ? (
                    <p style={{ color: 'red' }}>*Please choice booking date</p>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="home-clear-space"></div>
      </div>
    )
  }
}

const mapStateToProps = ({ coupon, popup, banner, menu }) => {
  return { coupon, popup, banner, menu }
}

export default connect(mapStateToProps, {
  popupHidden,
  getBanner,
  getPackage,
  menuListFetch,
})(SelectMenu)
