import {
  BANNER_GET_BANNER,
  BANNER_GET_BANNER_SUCCESS,
  MEMBER_POST_DETAIL,
  MEMBER_POST_DETAIL_SUCCESS,
  MEMBER_POST_DETAIL_BOOKING,
  MEMBER_POST_DETAIL_BOOKING_SUCCESS,
  OMISE_PAY_SERVER,
  OMISE_PAY_SERVER_SUCCESS,
  TRANSACTION_DETAIL_FETCH,
  TRANSACTION_DETAIL_FETCH_SUCCESS,
  OMISE_PAY_UPDATE_STATUS,
} from './types'

export const getBanner = () => {
  return {
    type: BANNER_GET_BANNER,
  }
}

export const setBanner = payload => {
  return {
    type: BANNER_GET_BANNER_SUCCESS,
    payload: payload,
  }
}

export const createCustomerTransaction = payload => {
  return {
    type: MEMBER_POST_DETAIL,
    payload: payload,
  }
}
export const setCustomerTransaction = payload => {
  return {
    type: MEMBER_POST_DETAIL_SUCCESS,
    payload: payload,
  }
}
export const createCustomerTransactionAndBooking = payload => {
  return {
    type: MEMBER_POST_DETAIL_BOOKING,
    payload: payload,
  }
}

export const setCustomerTransactionAndBooking = payload => {
  return {
    type: MEMBER_POST_DETAIL_BOOKING_SUCCESS,
    payload: payload,
  }
}

export const payOmise = payload => {
  return {
    type: OMISE_PAY_SERVER,
    payload: payload,
  }
}

export const payOmiseResult = payload => {
  return {
    type: OMISE_PAY_SERVER_SUCCESS,
    payload: payload,
  }
}

export const updateOmise = payload => {
  return {
    type: OMISE_PAY_UPDATE_STATUS,
    payload: payload,
  }
}

export const getTransactionDetail = payload => {
  return {
    type: TRANSACTION_DETAIL_FETCH,
    payload: payload,
  }
}

export const setTransactionDetail = payload => {
  return {
    type: TRANSACTION_DETAIL_FETCH_SUCCESS,
    payload: payload,
  }
}
