import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {
  popupLoading,
  submitBookingMonday,
  submitToCore,
  resetDiscountCode,
  menuListFetch,
  getBanner,
  popupError,
  createCustomerTransactionAndBooking,
  GetCodeValid,
  submitBeforeBookingMonday,
  popupSuccess,
  checkDiscountCode,
  createCustomerTransaction,
} from '../../actions'

class PaymentGB extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketNo: props.ticketNo,
      bookingNo: props.bookingNo,
      price: props.price,
      name: props.transaction.name,
      email: props.transaction.text0,
      payment_url: props.transaction.text00,
      credit_no: '',
      flag_payment: false,
      expirationMonth: '',
      expirationYear: '',
      securityCode: '',
    }
  }

  componentDidMount() {}

  formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  onChangeCreditCard = event => {
    var visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
    var amex = new RegExp('^3[47][0-9]{13}$')
    var mastercard = new RegExp('^5[1-5][0-9]{14}$')
    var onlyNum = new RegExp('^[0-9]+$')
    //  // console.log(event.target.value  ,  )
    if (visa.test(event.target.value) || amex.test(event.target.value) || mastercard.test(event.target.value)) {
      this.setState({
        flag_payment: true,
      })
    }
    if (event.target.value === '' || onlyNum.test(event.target.value)) {
      this.setState({
        credit_no: event.target.value,
      })
    }
  }

  onClickCheckout = event => {
    event.preventDefault()

    // check first 6 digit credit card
    if (this.props.card_only_data) {
      let first_six_digit = this.state.credit_no.slice(0, 6)
      
      if (this.props.card_only_data.allowDigit && this.props.card_only_data.allowDigit.length > 0) {
        if (this.props.card_only_data.allowDigit.indexOf(first_six_digit) < 0) {
          this.props.popupError(this.props.card_only_data.noAllowMsg)
          return 0
        }
      }
    }

    if (!this.state.flag_payment) {
      this.props.popupError('ข้อมูลเลขบัตรไม่ถูกต้อง')
      return 0
    }

    if (
      !this.state.credit_no ||
      !this.state.expirationMonth ||
      !this.state.expirationYear ||
      !this.state.securityCode
    ) {
      this.props.popupError('ข้อมูลไม่ครบถ้วน')
      return 0
    }

    this.props.popupLoading()
    var publicKey = 'eHAMBEn6Rpl1kEb0G5SfkpZxJja6EyWs'
    // console.log(this.state)

    var dataReq = {
      rememberCard: false,
      card: {
        number: this.state.credit_no,
        expirationMonth: this.state.expirationMonth,
        expirationYear: this.state.expirationYear,
        securityCode: this.state.securityCode,
        name: this.state.name,
      },
    }

    var chargeReq = {
      amount: parseInt(this.state.price),
      referenceNo: this.state.ticketNo,
      detail: this.state.payment_url.replace(/"/g, ''),
      customerName: this.state.name,
      customerEmail: this.state.email.replace(/"/g, ''),
      merchantDefined1: 'White Glove - Food delivery',
      card: {
        token: '',
      },
      otp: 'Y',
      responseUrl: 'https://md-sl.lifestyle-privilege.com/fd_gbPrimepayUpdating',
      backgroundUrl: 'https://md-sl.lifestyle-privilege.com/fd_gbPrimepayUpdating',
      booking: this.state.booking,
    }

    let token = axios
      .post('https://api.gbprimepay.com/v1/tokens', dataReq, {
        headers: {
          Authorization: 'Basic ' + btoa(publicKey + ':'),
        },
      })
      .then(res => {
        let responseData = res.data
        chargeReq.card.token = responseData.card.token

        let charge = axios
          .post('https://md-sl.lifestyle-privilege.com/fd_gbPrimepayChargeing', chargeReq, {})
          .then(res => {
            let responseData = res.data
            // console.log(responseData)

            if (responseData) {
              window.location = '/paymentOTP?gbpReferenceNo=' + responseData.gbpReferenceNo
            } else {
              this.props.popupError(
                'ทำรายการไม่สำเร็จ กรุณาเลือกช่องทางอื่น หรือ ติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'
              )
            }
          })
      })
  }

  render() {
    return (
      <div style={{ color: 'black' }}>
        <form className="payment-form" id="payment-customer" onSubmit={this.onClickCheckout}>
          <div>
            <div align="center">
              <label>
                Payment Card :
                <img
                  className="logo-small"
                  src="https://api.globalprimepay.com//dist/images/visa-mastercard-jcb_logo.png"
                  width="75px"
                />
              </label>
            </div>
            <div className="card-wrapper d-none" data-jp-card-initialized="true">
              <div className="jp-card-container">
                <div className="jp-card jp-card-unknown">
                  <div className="jp-card-front">
                    <div className="jp-card-logo jp-card-elo">
                      <div className="e">e</div>
                      <div className="l">l</div>
                      <div className="o">o</div>
                    </div>
                    <div className="jp-card-logo jp-card-visa">Visa</div>
                    <div className="jp-card-logo jp-card-visaelectron">
                      Visa
                      <div className="elec">Electron</div>
                    </div>
                    <div className="jp-card-logo jp-card-mastercard">Mastercard</div>
                    <div className="jp-card-logo jp-card-maestro">Maestro</div>
                    <div className="jp-card-logo jp-card-amex"></div>
                    <div className="jp-card-logo jp-card-discover">discover</div>
                    <div className="jp-card-logo jp-card-dinersclub"></div>
                    <div className="jp-card-logo jp-card-dankort">
                      <div className="dk">
                        <div className="d"></div>
                        <div className="k"></div>
                      </div>
                    </div>
                    <div className="jp-card-logo jp-card-jcb">
                      <div className="j">J</div>
                      <div className="c">C</div>
                      <div className="b">B</div>
                    </div>
                    <div className="jp-card-lower">
                      <div className="jp-card-shiny"></div>
                      <div className="jp-card-cvc jp-card-display">•••</div>
                      <div className="jp-card-number jp-card-display">•••• •••• •••• ••••</div>
                      <div className="jp-card-name jp-card-display"></div>
                      <div
                        className="jp-card-expiry jp-card-display"
                        data-before="month/year"
                        data-after="valid
thru"
                      >
                        ••/••{' '}
                      </div>
                    </div>
                  </div>
                  <div className="jp-card-back">
                    <div className="jp-card-bar"></div>
                    <div className="jp-card-cvc jp-card-display">•••</div>
                    <div className="jp-card-shiny"></div>
                  </div>
                </div>
              </div>
            </div>
            <hr />

            <div className="row">
              <div className="col-sm-12">
                <label>Card Number</label>
                <div className="card-number-area">
                  <img
                    id="logo_payment"
                    className="logo_payment"
                    src="https://api.globalprimepay.com/dist/images/all_payment.png"
                  />
                  <input
                    className="form-control mb-15 unknown"
                    placeholder="Card number"
                    type="tel"
                    name="number_card"
                    onKeyDown={e => e.keyCode > 47 && e.keyCode < 58}
                    value={this.state.credit_no}
                    maxLength="16"
                    onChange={e => {
                      this.onChangeCreditCard(e)
                    }}
                    id="number_card"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <label className="label-payment">
                  <span> Expiry Date (mm/yyyy)</span>
                </label>
                <div className="row">
                  <div className="col-sm-6 pr-tb-0">
                    <select
                      className="form-control form-control-lg mb-15"
                      name="expiredMonth"
                      defaultValue={{ value: '' }}
                      value={this.state.expirationMonth}
                      onChange={({ target: { value } }) => this.setState({ expirationMonth: value })}
                    >
                      <option value="" disabled="disabled">
                        เดือน
                      </option>
                      <option value="01">01</option>
                      <option value="02">02</option>
                      <option value="03">03</option>
                      <option value="04">04</option>
                      <option value="05">05</option>
                      <option value="06">06</option>
                      <option value="07">07</option>
                      <option value="08">08</option>
                      <option value="09">09</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                      <option value="12">12</option>
                    </select>
                  </div>
                  <div className="col-sm-6 pl-tb-0">
                    <select
                      className="form-control form-control-lg select-year mb-15"
                      name="expiredYear"
                      defaultValue={{ value: '' }}
                      value={this.state.expirationYear}
                      onChange={({ target: { value } }) => this.setState({ expirationYear: value })}
                    >
                      <option value="" disabled="disabled">
                        ปี
                      </option>
                      <option value="20">2020</option>
                      <option value="21">2021</option>
                      <option value="22">2022</option>
                      <option value="23">2023</option>
                      <option value="24">2024</option>
                      <option value="25">2025</option>
                      <option value="26">2026</option>
                      <option value="27">2027</option>
                      <option value="28">2028</option>
                      <option value="29">2029</option>
                      <option value="30">2030</option>
                      <option value="31">2031</option>
                      <option value="32">2032</option>
                      <option value="33">2033</option>
                      <option value="34">2034</option>
                      <option value="35">2035</option>
                      <option value="36">2036</option>
                      <option value="37">2037</option>
                      <option value="38">2038</option>
                      <option value="39">2039</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <label className="fix-label d-flex justify--between-md " htmlFor="">
                  CVV
                </label>
                <input
                  type="password"
                  className="col-xs-3 form-control mb-15"
                  name="cvc"
                  id="cvc"
                  maxLength="3"
                  placeholder="CVV"
                  value={this.state.securityCode}
                  onChange={({ target: { value } }) => this.setState({ securityCode: value })}
                />
              </div>
            </div>

            <div
              id="rememberCard"
              className="d-flex f-col input-g f-row-md align--center-md"
              style={{ display: 'none' }}
            ></div>
            <div className="text-center mb-5">
              <button className="btn btn-danger d-block" type="submit" data-button="btn-paynow">
                Pay Now {this.formatNumber(this.props.price.replace(/"/g, ''))} THB
              </button>
            </div>
            <p className="text-center power-by-text">
              <span>Secured by:</span>
              <img
                className="logo-small"
                src="https://api.globalprimepay.com/dist/images/logo-small.png"
                width="25px"
              />{' '}
              GBPrimePay
            </p>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ coupon, popup, banner, imageUpload }) => {
  return { coupon, popup, banner, imageUpload }
}

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
})(PaymentGB)
