import React, { Component, Suspense } from 'react'
import { connect } from 'react-redux'

import '../assets/css/app.css'
import '../assets/css/app-lt-375.css'
import '../assets/css/app-lt-320.css'
import '../assets/css/app-mt-768.css'
import '../assets/css/app-mt-1024.css'
import '../assets/css/modal.css'
import '../assets/fonts/stylesheet.css'

import 'bootstrap/dist/css/bootstrap.min.css'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import MainForm from './Form/mainfrom'
import SelectMenu from './Form/SelectMenu'

import Home from './home'
import Payment from './Form/Payment'
import PaymentConfirmOTP from './Form/PaymentConfirmOTP'
import PaymentOTP from './Form/PaymentOTP'
import VerifyPayment from './Form/VerifyPayment'
import PrivacyPolicy from './privacy-policy'
import ConsentModal from './Modal/consentModal'
import SuccessModal from './Modal/successModal'
import PopupBackdrop from './Modal/PopupBackdrop'
import PopupLoading from './Modal/PopupLoading'
import PopupError from './Modal/PopupError'
import { popupHidden, getBanner, getPackage, menuListFetch } from '../actions'
import './../assets/css/Loading.css'
import BannerModal from './Modal/bannerModal'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CookieConsent, { Cookies } from 'react-cookie-consent'

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: 3,
      clickMenu: false,
    }
  }
  componentDidMount() {
    //  this.props.getBanner();
    this.props.menuListFetch()
  }

  clickMenu = event => {
    this.setState({
      clickMenu: event,
    })
  }

  privacyPolicy() {
    alert('xxx')
  }

  render() {
    let bg = {
      minHeight: window.innerHeight - 110,
    }
    let a_href = {
      fontSize: '14px',
      color: '#fff !important',
    }
    const popup = this.props.popup
    const resultTicket = this.props.coupon.ticketId

    return (
      <Router>
        <div>
          <div className="wrapper-content d-flex w-100 h-100 mx-auto flex-column">
            <Suspense
              fallback={
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              }
            >
              <Header clickMenu={this.clickMenu} />
            </Suspense>
            <PopupError
              display={popup.display === 'error'}
              msg={popup.msg}
              closePopup={() => this.props.popupHidden()}
            />
            <SuccessModal
              display={popup.display === 'success'}
              closePopup={() => this.props.popupHidden()}
              ticketId={resultTicket}
            />
            <PopupLoading display={popup.display === 'loading'} />
            <ConsentModal display={popup.display === 'consent'} closePopup={() => this.props.popupHidden()} />
            <PopupBackdrop display={popup.display !== 'hidden'} />
            <div className="home-page" id="page-limousineService" style={bg}>
              <Suspense
                fallback={
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              ></Suspense>

              <Suspense
                fallback={
                  <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                }
              >
                <Switch>
                  <Route path="/test">
                    <SelectMenu />
                    {/* <MainForm data={dataprop} /> */}
                  </Route>

                  <Route path="/booking/:menu_id" component={MainForm}>
                    {}
                  </Route>
                  <Route path="/payment/:ticketNo" component={Payment}>
                    {/* <Payment /> */}
                  </Route>
                  <Route path="/paymentConfirmOTP" component={PaymentConfirmOTP}>
                    {}
                  </Route>

                  <Route path="/paymentOTP" render={props => <PaymentOTP {...props} />} />

                  <Route path="/verify-payment" component={VerifyPayment}>
                    {}
                  </Route>
                  <Route path="/privacy-policy" component={PrivacyPolicy}>
                    {}
                  </Route>

                  <Route path="/">
                    {/*<HomeComingSoon/>*/}
                    {/* <BannerModal/> */}
                    <Home clickMenu={this.clickMenu} showModal={this.state.clickMenu} />
                  </Route>
                </Switch>
              </Suspense>
            </div>
            <Footer />
            <CookieConsent
              location="bottom"
              buttonText="Accept & Close"
              cookieName="CookieConsent"
              style={{ background: '#000', alignItems: 'center', padding: '5px' }}
              buttonStyle={{
                background: 'red',
                color: '#FFF',
                fontSize: '14px',
                borderRadius: '5px',
                padding: '10px 20px',
              }}
              expires={14}
            >
              We use cookies on this website. Cookies are small text files, stored on your computer. We use cookies to
              provide statistics that help us give you the best experience of our site. <br />
              By continuing to use the site without changing settings, you are agreeing to our use of cookies.{' '}
              <span style={a_href} onClick={() => (window.location.href = '/privacy-policy')}>
                <u>Read Privacy Policy</u>
              </span>
            </CookieConsent>
          </div>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = ({ coupon, popup, banner, menu }) => {
  return { coupon, popup, banner, menu }
}

export default connect(mapStateToProps, {
  popupHidden,
  getBanner,
  getPackage,
  menuListFetch,
})(App)
