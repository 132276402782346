import React from 'react'
import PopupLayout from './layoutModal'
import '../../assets/css/Loading.css'

function PopupLoading(props) {
  return (
    <PopupLayout display={props.display}>
      <div className="modal-body text-center">
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div>กำลังส่งข้อมูลไปยังระบบ</div>
      </div>
    </PopupLayout>
  )
}

export default PopupLoading
