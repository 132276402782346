import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import axios from 'axios'
import { UPLOAD_IMAGE, UPLOAD_IMAGE_MONDAY, OMISE_PAY_SERVER } from '../actions/types'
import { uploadImageSuccess, payOmiseResult } from '../actions'

const uploadImageRequest = async payload => {
  return await axios
    .post(`https://uat-api-partner.lifestyle-privilege.com/upload-slip/${payload.id}`, payload.payload, {
      headers: authorizationHeaderWithMultipartFormData('Bearer'),
    })
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

export const authorizationHeaderWithMultipartFormData = tokenType => {
  let auth = JSON.parse(localStorage.getItem('partner-admin'))
  if (auth && auth.token) {
    return { 'Content-Type': `multipart/form-data` }
  } else {
    return {}
  }
}

const omisePayServerRequest = async payload => {
  return await axios
    .post(`https://md-sl.lifestyle-privilege.com/fd_omiseChargeing`, payload)
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

const postUpdateImageValue = async payload => {
  let column_values = {
    link7: `${payload.url}`,
    status9: { index: 19 },
  }

  const body = {
    query: `mutation($board: Int!, $item_id: Int!,  $column_values: JSON!) {
		change_multiple_column_values (board_id:  $board, item_id:  $item_id, column_values:  $column_values) {
			id
			column_values {
			id
			value
			}
	  }
	  } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_CustomerTransaction),
      item_id: Number(payload.id),
      column_values: JSON.stringify(column_values),
    },
  }

  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

// WORKER
function* uploadImage({ payload }) {
  try {
    const data = yield call(uploadImageRequest, payload)
    yield put(uploadImageSuccess(data.files[0]))
    //	yield put(postUpdateImageValue(data.files[0],payload.id));
  } catch (error) {}
}

function* uploadImageMonday({ payload }) {
  try {
    yield put(postUpdateImageValue(payload))
  } catch (error) {}
}

function* omisePayServer({ payload }) {
  try {
    const data = yield call(omisePayServerRequest, payload)
    yield put(payOmiseResult(data))
  } catch (error) {}
}

// WATCHER
export function* uploadImageWatcher() {
  yield takeEvery(UPLOAD_IMAGE, uploadImage)
}

export function* uploadImageMondayWatcher() {
  yield takeEvery(UPLOAD_IMAGE_MONDAY, uploadImageMonday)
}

export function* omisePayServerWatcher() {
  yield takeEvery(OMISE_PAY_SERVER, omisePayServer)
}

export default function* rootSaga() {
  yield all([fork(uploadImageWatcher), fork(uploadImageMondayWatcher), fork(omisePayServerWatcher)])
}
