import { navigate } from '@reach/router'
import axios from 'axios'

import axiosRetry from 'axios-retry'
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

axios.defaults.baseURL = 'https://md-sl.lifestyle-privilege.com/request'
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error.response)
    switch (error.response.status) {
      case 401:
        navigate('/')
        break
      case 404:
      case 403:
        navigate('/')
        break
      default:
        break
    }
    return Promise.reject(error.response)
  }
)

export default axios
