import {
  BANNER_GET_BANNER_SUCCESS,
  MEMBER_POST_DETAIL_SUCCESS,
  MEMBER_POST_DETAIL_BOOKING_SUCCESS,
  OMISE_PAY_SERVER_SUCCESS,
  TRANSACTION_DETAIL_FETCH_SUCCESS,
} from '../actions/types'

const INIT_STATE = {
  data: {},
  status: 0,
  transaction: {},
  payment: {
    status: '',
    ticketNo: '',
    couponCode: '',
  },
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case BANNER_GET_BANNER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case MEMBER_POST_DETAIL_SUCCESS: {
      return {
        ...state,
        transaction: action.payload,
      }
    }

    case MEMBER_POST_DETAIL_BOOKING_SUCCESS: {
      return {
        ...state,
        transaction: action.payload,
      }
    }

    case OMISE_PAY_SERVER_SUCCESS: {
      return {
        ...state,
        payment: {
          status: action.payload.status,
          ticketNo: action.payload.ref_1,
          couponCode: action.payload.coupon_code,
        },
      }
    }

    case TRANSACTION_DETAIL_FETCH_SUCCESS: {
      return {
        ...state,
        transaction: action.payload,
      }
    }

    default:
      return state
  }
}
