import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import '../../assets/css/app.css'
import '../../assets/css/booking-page.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  popupLoading,
  submitBookingMonday,
  submitToCore,
  resetDiscountCode,
  menuListFetch,
  getBanner,
  popupError,
  checkDiscountCodeFirst,
  createCustomerTransactionAndBooking,
  GetCodeValid,
  submitBeforeBookingMonday,
  popupSuccess,
  checkDiscountCode,
  createCustomerTransaction,
} from '../../actions'
import { Translation } from 'react-i18next'
import DatePicker from 'react-datepicker'

import c_person from '../../assets/imgs/c_person.png'
import c_car from '../../assets/imgs/c_car.png'
import c_lugguage from '../../assets/imgs/c_lugguage.png'
import plus from '../../assets/imgs/p.png'
import cal from '../../assets/imgs/cal.png'
import ThankForBooking from './ThankForBooking'
import TermModal from '../Modal/termModal'
import 'react-datepicker/dist/react-datepicker.css'
import Geolocated from '../geolocated '

import { Link } from 'react-router-dom'
import moment from 'moment'

function addDays(date, days) {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

function discountCal(type, defaultPrice, value) {
  let price = 0
  if (type === 0) {
    price = defaultPrice - value
  } else {
    price = defaultPrice - (defaultPrice / 100) * value
  }
  //
  return price > 0 ? price : 0
}

function boundedDiscount(val) {
  if (val < 0) return 0
  else if (val > 350) return 350
  else return val
}

class MainForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstname: '',
      lastname: '',
      phone: '',
      email: '',
      facebook: '',
      line: '',
      wechat: '',
      bookingTime: '',
      person: '',
      luggage: '',
      car_type: '1',
      car_pic: 5,
      carArrayMapCoupon: ['', '', '', '', '', '', ''],
      carArray: [
        '',
        'Camry/Fortuner - 1,100 Baht/Way',
        '',
        'H1 - 2,200 Baht/Way',
        'Alphard - 3,000 Baht/Way',
        '',
        'Benz E-Class - 3,000 Baht/Way',
      ],
      carArr: [],
      pick_upAddress: '',
      Message: '',
      pick_up_interest: '',
      pick_upFlight: '',
      pick_up_airport: '1',
      drop_offAddress: '',
      drop_off_interest: '',
      drop_off_airport: '1',
      drop_offFlight: '',
      maxPAX: '',
      note: '',
      remark: '',
      maxPerson: '',
      flagValidCar: true,
      data: this.props.data,
      coupon: this.props.coupon,
      code: '',
      selectedOption: null,
      pickupType: 0,
      dropoffType: 0,
      startDate: undefined,
      Datetime: '',
      privilege_id: '',
      coupon_id: '',
      disabled: false,
      valid: true,
      selectedCoupon: '',
      conponRemainedTime: 0,
      routeZone: '',
      routeSubZone: '',
      route: [],
      submit: false,
      selectedRoute: null,
      bookStep: 2,
      isChecked: false,
      selectedAmount: 1,
      discountCode: null,
      totalAmount: null,
      discountValueNet: null,
      defaultPrice: null,
      discountType: '',
      discountValue: 0,
      flagValidDiscountCode: false,
      flagValidCouponCode: false,
      errorCouponCode: false,
      newFetch: false,
      errorCoupon: '',
      clickValidateCoupon: false,
      errorRequireFirstName: false,
      errorRequireLastName: false,
      errorRequirePhone: false,
      errorRequirePhoneWrongPattern: false,
      errorRequireBookingDate: false,
      errorRequireBookingTime: '',
      errorRequirePerson: false,
      errorRequirePickUpType: false,
      errorRequirePickupAddress: false,
      errorRequirePickupFlight: false,
      errorRequireDropoffAddress: false,
      errorRequireDropoffFlight: false,
      displayText: false,
      calDiscount: false,
      maxDate: addDays(new Date(), 31),
      minDate: addDays(new Date(), 1),
      minTime: addDays(new Date(), 0),
      carType: null,
      airportAvailable: [],
      menu_id: this.props.match.params.menu_id,
      couponForever: false,
      deliveryFee: 350,
      coupon_cycle: [],
    }
    this.onchangeDate = this.onchangeDate.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  formStyle = {
    borderRadius: '20px !important!important',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }
  codeStyle = {
    borderRadius: '0px 20px 20px 0px',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }

  componentDidMount() {
    // this.props.popupError('ขออภัยคุณลูกค้าทุกท่าน ขณะนี้อยู่ในช่วงปรับปรุง กรุณาติดต่อไปยังเจ้าหน้าที่โดยตรง Phone: 02-016-9975  ขออภัยในความไม่สะดวกอย่างสูง')

    // console.log(this.props.menu)
  }
  componentDidUpdate() {
    //
    if (!this.props.coupon.firstLaunchCoupon) {
      //this.props.checkDiscountCodeFirst();
    }

    var allCoupon = this.props.coupon.validationCodeDisCountFromMonday

    if (this.state.valid && allCoupon.length > 0 && allCoupon !== undefined) {
      let selectedCoupon = allCoupon[0]
      try {
      } catch {}

      try {
        let index = selectedCoupon.dropdown6 !== null ? JSON.parse(selectedCoupon.dropdown6).ids[0] : 0
      } catch {}

      this.updateDiscountCode(this.props)
      this.setState({
        discountCode: selectedCoupon.text8.replace(/"/g, ''),
        flagValidCar: true,
        valid: false,
        selectedCoupon: allCoupon[0],
        conponRemainedTime: allCoupon.length,
        flagValidCouponCode: true,
        errorCouponCode: false,
      })
    }

    var coupon = this.props.coupon.validationCodeFromServer || this.props.coupon.validationCodeDisCountFromMonday[0]
    var coupon_cycle = null

    // console.log(this.state.discountCode)
    if (!this.state.discountCode) {
      let coupon_cycle_temp =
        coupon && coupon.hasOwnProperty('dup__of_cycle') && coupon.dup__of_cycle ? JSON.parse(coupon.dup__of_cycle) : ''
      coupon_cycle = coupon_cycle_temp ? coupon.additional_info.dup__of_cycle.label : ''
      //check menu in coupon setting

      // console.log('coupon_cycle', coupon_cycle.split(','))
    }

    //let refMenu =  coupon.hasOwnProperty("link_to_item") && coupon.link_to_item  && JSON.parse(coupon.link_to_item).linkedPulseIds ? JSON.parse(coupon.link_to_item).linkedPulseIds.map( item =>item.linkedPulseId) || [] : []
    let refMenu =
      coupon && coupon.hasOwnProperty('text5') && coupon.text5 && coupon.text5.replace(/"/g, '').split(',').length > 0
        ? coupon.text5
            .replace(/"/g, '')
            .split(',')
            .map(item => item) || []
        : coupon &&
          coupon.hasOwnProperty('text61') &&
          coupon.text61 &&
          coupon.text61.replace(/"/g, '').split(',').length > 0
        ? coupon.text61
            .replace(/"/g, '')
            .split(',')
            .map(item => item) || []
        : []
    let endDate = this.state.maxDate
    try {
      endDate =
        JSON.parse(coupon.date8) === null && JSON.parse(coupon.date8).date !== ''
          ? this.state.maxDate
          : new Date(JSON.parse(coupon.date8).date)

      // endDate = addDays( new Date(), 4)
    } catch (error) {
      endDate = this.state.maxDate
    }

    if (refMenu.length > 0) {
      // console.log(
      //   refMenu,
      //   refMenu.findIndex(data => this.props.menu_id === data)
      // )
      let result = refMenu.findIndex(data => this.props.menu_id === data) === -1 ? window.location.replace('/') : ''
    }

    // TODO : coupon already
    if (coupon && !this.state.couponValids && !this.state.discountCode) {
      
      this.setState({
        couponValids: coupon,
        coupon_cycle: coupon_cycle.split(','),
        maxDate: endDate,
      })
    }

    // TODO : coupon : recheck with integromat
  }

  normalizePhone = value => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^^[0][0-9-]+$]/g, '')
    return onlyNums
  }

  onchangeDate(value, bookingType) {
    let currentTime = new Date()
    let selectdDate = new Date(this.state.Datetime)
    let oldbookingTime = this.state.bookingTime
    if (bookingType === 'bookingTime') {
      oldbookingTime = value
      this.setState({ errorRequireBookingTime: '' })
      this.setState({ [bookingType]: value })

      // ถ้าสั่งรอบเที่ยง ต้องล่วงหน้าอย่างน้อย 1 วัน ภายใน 20.00 ของทุกวัน
      //ถ้าสั่งรอบเย็น จะได้ภายในวันนั้น 9 โมง
    } else if (bookingType === 'datepicker') {
      selectdDate = new Date(value)
      this.setState({
        Datetime: value,
        minTime: new Date(),
        errorRequireBookingDate: value !== '' ? false : true,
      })
    }
    // console.log(value, oldbookingTime)
    if (
      selectdDate.getMonth() === currentTime.getMonth() ||
      addDays(currentTime, 1).getDate() === selectdDate.getDate()
    ) {
      if (value === '102' || oldbookingTime === '102') {
        if (
          (currentTime.getDate() !== selectdDate.getDate() && currentTime.getHours() < 16) ||
          (currentTime.getDate() !== selectdDate.getDate() &&
            addDays(currentTime, 1).getDate() !== selectdDate.getDate())
        ) {
          this.setState({ errorRequireBookingTime: '' })
        } else {
          this.setState({ errorRequireBookingTime: 'สั่งก่อน 16.00 น. ก่อนวันล่วงหน้า' })
        }
      } else if (value === '151' || oldbookingTime === '151') {
        if (
          (currentTime.getDate() !== selectdDate.getDate() && currentTime.getHours() < 18) ||
          (currentTime.getDate() !== selectdDate.getDate() &&
            addDays(currentTime, 1).getDate() !== selectdDate.getDate())
        ) {
          // if(  (  (currentTime.getDate() !== selectdDate.getDate())  || ( (currentTime.getDate() === selectdDate.getDate()) && currentTime.getHours() < 9) )   ){
          this.setState({ errorRequireBookingTime: '' })
        } else {
          this.setState({ errorRequireBookingTime: 'สั่งก่อน 18.00 น. ก่อนวันล่วงหน้า' })
        }
      } else if (
        value === '157' ||
        oldbookingTime === '157' ||
        value === '158' ||
        oldbookingTime === '158'
      ) {
        if (
          (currentTime.getDate() !== selectdDate.getDate() && currentTime.getHours() < 18) ||
          (currentTime.getDate() !== selectdDate.getDate() &&
            addDays(currentTime, 1).getDate() !== selectdDate.getDate())
        ) {
          // if(  (  (currentTime.getDate() !== selectdDate.getDate())  || ( (currentTime.getDate() === selectdDate.getDate()) && currentTime.getHours() < 9) )   ){
          this.setState({ errorRequireBookingTime: '' })
        } else {
          this.setState({ errorRequireBookingTime: 'สั่งก่อน 18.00 น. ก่อนวันล่วงหน้า' })
        }
      }
    }
  }

  // Old Condition
  // handleInputChange(value, name) {
  //   // Booking Time Formatter
  //   console.log(value, name === 'bookingTime')
  //   try {
  //     let val = ''
  //     if (name === 'bookingTime') {
  //       let currentTime = new Date()
  //       let selectdDate = new Date(this.state.Datetime)
  //       console.log(this.state.Datetime, currentTime.getDate() !== selectdDate.getDate(), currentTime.getHours() < 20)
  //       if (value === '1') {
  //         if (currentTime.getDate() !== selectdDate.getDate() && currentTime.getHours() < 20) {
  //           this.setState({ errorRequireBookingTime: '' })
  //           this.setState({ [name]: val })
  //         } else {
  //           this.setState({ errorRequireBookingTime: 'ช่วงเที่ยงต้องสั่ง ภายใน 20.00 น. ล่วงหน้าอย่างน้อย 1 วัน' })
  //         }
  //       } else if (value === '4') {
  //         if (
  //           currentTime.getDate() !== selectdDate.getDate() ||
  //           (currentTime.getDate() === selectdDate.getDate() && selectdDate.getHours() < 20)
  //         ) {
  //           this.setState({ errorRequireBookingTime: '' })
  //           this.setState({ [name]: val })
  //         } else {
  //           this.setState({ errorRequireBookingTime: 'ถ้าสั่งรอบเย็น จะได้ภายในวันนั้น 11 โมง' })
  //           return
  //         }
  //       }

  //       // ถ้าสั่งรอบเที่ยง ต้องล่วงหน้าอย่างน้อย 1 วัน ภายใน 20.00 ของทุกวัน
  //       //ถ้าสั่งรอบเย็น จะได้ภายในวันนั้น 11 โมง
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  onChangeLimousineCode(value) {
    // Code valid
    //In next version : Have to remove hardcode privilege data
    try {
      let strValue = String(value)
      //let prefixCode = []
      //let postfixCode = []
      //let numberCode = []

      if (strValue === '') {
        this.setState({ code: '' })
      } else {
        this.setState({ code: strValue })
        //prefixCode = strValue.match('[A-Z]+')
        //postfixCode = strValue.replace(prefixCode[0],"").match('[A-Z]+')
        //numberCode = strValue.match('[0-9]+')
        this.setState({ car_pic: 5 })
      }

      //  if(prefixCode[0] === "CIGNA" && postfixCode[0] === "WRS" && numberCode[0].length > 0){
      //     this.setState({
      //       flagValidCar: false,
      //     })
      //     if(numberCode[0].length === 7) {
      //      this.props.GetCodeValid(strValue.toUpperCase())
      //       this.setState({
      //         flagValidCar: true
      //       })
      //     }
      //   }
      //   else

      if (strValue === '') {
        this.setState({
          flagValidCar: true,
        })
        this.setState({ car_pic: 5, carArr: this.state.carArray })

        this.setState({ code: '' })
      } else {
        this.setState({ car_pic: 5, carArr: [] })
        this.setState({
          flagValidCar: false,
        })
      }
    } catch (e) {
      // console.log(e)
    }
  }

  checkCodeManual = () => {
    this.props.GetCodeValid(this.state.code)
  }

  updateDiscountCode = newProps => {
    //if( this.state.defaultPrice != null &&  this.props.coupon.firstLaunchCoupon.length > 0){
    //   var newFee = this.state.deliveryFee - 100 - this.state.discountValueNet
    //
    //   this.setState({
    ///    totalAmount :  this.props.coupon.firstLaunchCoupon ? this.state.defaultPrice + boundedDiscount(newFee)  : this.state.defaultPrice +  boundedDiscount(this.state.deliveryFee - this.state.discountValueNet)
    //    })
    //   }

    if (
      (!this.isEmpty(newProps.coupon.validationCodeDisCountFromMonday) &&
        newProps.coupon.validationCodeDisCountFromMonday[0]) ||
      (!this.isEmpty(newProps.coupon.firstLaunchCoupon) && newProps.coupon.firstLaunchCoupon[0])
    ) {
      let couponCode = newProps.coupon.validationCodeDisCountFromMonday[0] || newProps.coupon.firstLaunchCoupon[0]
      let discount = JSON.parse(couponCode.status0) // {\"0\":\"VALUE\",\"1\":\"PERCENT\"}
      let couponStatus = JSON.parse(couponCode.status) // \"0\":\"DISABLE\",\"1\":\"AVAILABLE\",\"2\":\"USED\",\"3\":\"EXPIRED\",\"4\"
      let couponFor = JSON.parse(couponCode.status01) // {\"0\":\"Menu\",\"1\":\"Fee\",\"12\":\"Total\"}
      let couponType = JSON.parse(couponCode.status2) // {\"0\":\"DISCOUNT\",\"1\":\"COUPON\"}}
      let useForever = JSON.parse(couponCode.forever)
      //let linkRestaurant = JSON.parse(couponCode.link_to_item);
      let afterCheckCoupon = JSON.parse(couponCode.long_text9)
      let linkRestaurant = couponCode.text5
        ? couponCode.text5.replace(/"/g, '').split(',')
        : couponCode.text61
        ? couponCode.text61.replace(/"/g, '').split(',')
        : []

      let menu_id = newProps.match.params.menu_id

      // if(linkRestaurant && linkRestaurant.linkedPulseIds && menu_id){
      if (linkRestaurant && linkRestaurant.length > 0 && menu_id) {
        // let foundResult =   linkRestaurant.linkedPulseIds.find( (restaurant) => restaurant.linkedPulseId +"" === menu_id )
        let foundResult = linkRestaurant.find(restaurant => restaurant === menu_id)

        if (!foundResult) {
          this.setState({
            errorCoupon: 'ท่านไม่สามารถใช้รหัสส่วนลดร่วมกับเมนูดังกล่าวได้ กรุณาเลือกเมนูใหม่จากหน้าแรกอีกครั้ง',
            flagValidDiscountCode: false,
          })
          return
        }
      }

      if (afterCheckCoupon && afterCheckCoupon.text) {
        this.setState({
          messageAfter: afterCheckCoupon.text,
        })
      }

      // console.log(newProps.coupon.validationCodeDisCountFromMonday[0])
      if (newProps.coupon.firstLaunchCoupon.length > 0) console.log('FIRST INIT ')
      try {
        // resterrance check
        this.setState({
          couponForever: useForever.checked,
        })
        //_routeZone =  couponCode.route3!==null? couponCode.route3.replace(/"/g, ''):""
        // && (_carType===this.state.carType || _carType === "" || _carType === undefined) && ( _routeZone===this.state.selectedRoute || _routeZone === "" || _routeZone === undefined)
      } catch {}

      if (this.state.defaultPrice === null) {
        console.log(this.props.menu)
        return
      }

      if (
        !this.isEmpty(discount) &&
        couponStatus.index === 1 &&
        couponType.index === 0 &&
        this.state.calDiscount === false
      ) {
        this.setState({
          displayText: couponCode.name,
        })
        // couponFor = JSON.parse(couponCode.status01);  // {\"0\":\"Menu\",\"1\":\"Fee\",\"12\":\"Total\"}
        // console.log(couponCode)
        let discountValue =
          couponCode.condition && couponCode.condition.cond === 'cost:>=:5000' && this.state.defaultPrice >= 5000
            ? couponCode.condition.value
            : parseInt(couponCode.numbers.replace(/"/g, ''))
        let menuCost =
          couponFor.index === 0
            ? discountCal(discount.index, this.state.defaultPrice, discountValue)
            : this.state.defaultPrice
        let deliveryCost =
          couponFor.index === 1
            ? discountCal(discount.index, this.state.deliveryFee, discountValue)
            : this.state.deliveryFee
        let discountSet = this.state.defaultPrice - menuCost
        let discountDelivery = this.state.deliveryFee - deliveryCost

        let totalDefault = this.state.defaultPrice + this.state.deliveryFee

        let total = +menuCost + +deliveryCost
        let amount = couponFor.index === 12 ? discountCal(discount.index, totalDefault, discountValue) : totalDefault
        let totalAmount = couponFor.index === 12 ? (amount > 0 ? amount : 0) : total
        let discountValueNet =
          discountSet + discountDelivery > 0 ? discountSet + discountDelivery : totalDefault - totalAmount
        // console.log(newProps.coupon)

        if (
          newProps.coupon.firstLaunchCoupon.length > 0 &&
          this.isEmpty(newProps.coupon.validationCodeDisCountFromMonday)
        ) {
          // console.log(newProps.coupon.firstLaunchCoupon)
          this.setState({
            errorCoupon: '',
            discountSet: discountSet,
            discountDelivery: discountDelivery,
            discountType: discount.index === 0 ? 'Baht' : 'Percent',
            discountValue: discountValue,
            discountValueNet: discountValueNet,
            totalAmount: totalAmount,
            menuCost: menuCost,
            deliveryCost: deliveryCost,
          })
          console.log({
            errorCoupon: '',
            discountType: discount.index === 0 ? 'Baht' : 'Percent',
            discountValue: discountValue,
            discountSet: discountSet,
            discountDelivery: discountDelivery,
            discountValueNet: discountValueNet,
            totalAmount: totalAmount,
            menuCost: menuCost,
            deliveryCost: deliveryCost,
          })
          // console.log('')
          return false
        }
        let startDate = undefined
        let endDate = undefined
        let startRedeemDate = undefined
        let endRedeemDate = undefined
        let startRedeemTime = '00:00:00'
        let endRedeemTime = '23:59:59'
        try {
          startDate =
            JSON.parse(couponCode.date) === null && JSON.parse(couponCode.date).date !== ''
              ? undefined
              : JSON.parse(couponCode.date).date
          startDate = startDate - this.state.minDate >= 0 ? startDate : this.state.minDate
        } catch (error) {}
        try {
          endDate =
            JSON.parse(couponCode.date8) === null && JSON.parse(couponCode.date8).date !== ''
              ? ''
              : JSON.parse(couponCode.date8).date
          endDate = endDate - this.state.maxDate >= 0 ? endDate : this.state.maxDate

          // endDate = addDays( new Date(), 4)
        } catch (error) {}
        try {
          startRedeemDate =
            JSON.parse(couponCode.date) === null && JSON.parse(couponCode.date).date !== ''
              ? undefined
              : JSON.parse(couponCode.date).date
        } catch (error) {}
        try {
          endRedeemDate =
            JSON.parse(couponCode.date8) === null && JSON.parse(couponCode.date8).date !== ''
              ? undefined
              : JSON.parse(couponCode.date8).date
        } catch (error) {}
        try {
          startRedeemTime =
            JSON.parse(couponCode.date).time === null && JSON.parse(couponCode.date).date !== ''
              ? '00:00:00'
              : JSON.parse(couponCode.date).time
        } catch (error) {}
        try {
          endRedeemTime =
            JSON.parse(couponCode.date8).time === null && JSON.parse(couponCode.date8).date !== ''
              ? '23:59:59'
              : JSON.parse(couponCode.date8).time
        } catch (error) {}

        let textStartTime = '00:00:00'
        let textEndTime = '23:59:59'

        if (couponCode.date !== null && JSON.parse(couponCode.date).time !== null) {
          let tmpStartDate = new Date(startRedeemDate + ' ' + startRedeemTime)
          if (startRedeemTime) {
            textStartTime =
              tmpStartDate.getHours() + 7 + ':' + startRedeemTime.split(':')[1] + ':' + startRedeemTime.split(':')[2]
          }
        }
        if (couponCode.date8 !== null && JSON.parse(couponCode.date8).time !== null) {
          let tmpEndDate = new Date(endRedeemDate + ' ' + endRedeemTime)
          if (endRedeemTime) {
            textEndTime =
              tmpEndDate.getHours() + 7 + ':' + endRedeemTime.split(':')[1] + ':' + endRedeemTime.split(':')[2]
          }
        }
        if (startRedeemDate !== undefined && endRedeemDate !== undefined) {
          if (
            new Date(couponCode.currentTime).getTime() - new Date(startRedeemDate + ' ' + textStartTime).getTime() <
            0
          ) {
            this.setState({
              errorCoupon:
                'รหัสยังไม่เปิดใช้งาน รหัสเปิดใช้งานวันที่  ' +
                startRedeemDate +
                ' ' +
                (JSON.parse(couponCode.date).time !== null ? textStartTime : '') +
                ' ถึง  ' +
                endRedeemDate +
                ' ' +
                (JSON.parse(couponCode.date8).time !== null ? textEndTime : '') +
                '. ',
            })
          } else if (
            new Date(couponCode.currentTime).getTime() - new Date(endRedeemDate + ' ' + textEndTime).getTime() >
            0
          ) {
            this.setState({
              errorCoupon: 'รหัสของท่านหมดอายุใช้งานแล้ว  กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม ',
            })
          } else if (
            new Date(couponCode.currentTime).getTime() - new Date(endRedeemDate + ' ' + textEndTime).getTime() >
            0
          ) {
          } else {
            this.setState({
              valid: true,
              calDiscount: true,
              coupon: newProps.coupon,
              errorCoupon: '',
              maxDate: endDate,
              minDate: startDate,
              discountType: discount.index === 0 ? 'Baht' : 'Percent',
              discountValue: discountValue,
              flagValidDiscountCode: true,
              discountSet: discountSet,
              discountDelivery: discountDelivery,
              discountValueNet: discountValueNet,
              totalAmount: totalAmount,
              menuCost: menuCost,
              deliveryCost: deliveryCost,
            })
          }
        } else if (startRedeemDate !== undefined) {
          if (
            new Date(couponCode.currentTime).getTime() - new Date(startRedeemDate + ' ' + textStartTime).getTime() >
            0
          ) {
            this.setState({
              valid: true,
              calDiscount: true,
              coupon: newProps.coupon,
              errorCoupon: '',
              maxDate: endDate,
              minDate: startDate,
              discountType: discount.index === 0 ? 'Baht' : 'Percent',
              discountValue: discountValue,
              flagValidDiscountCode: true,
              discountSet: discountSet,
              discountDelivery: discountDelivery,
              discountValueNet: discountValueNet,
              totalAmount: totalAmount,
              menuCost: menuCost,
              deliveryCost: deliveryCost,
            })
          } else {
            this.setState({
              errorCoupon: 'รหัสยังไม่เปิดใช้งาน รหัสเปิดใช้งานวันที่  ' + startRedeemDate,
            })
          }
        } else if (endRedeemDate !== undefined) {
          if (new Date(couponCode.currentTime).getTime() - new Date(endRedeemDate + ' ' + textEndTime).getTime() < 0) {
            this.setState({
              valid: true,
              calDiscount: true,
              coupon: newProps.coupon,
              errorCoupon: '',
              maxDate: endDate,
              minDate: startDate,
              discountType: discount.index === 0 ? 'Baht' : 'Percent',
              discountValue: discountValue,
              flagValidDiscountCode: true,
              discountSet: discountSet,
              discountDelivery: discountDelivery,
              discountValueNet: discountValueNet,
              totalAmount: totalAmount,
              menuCost: menuCost,
              deliveryCost: deliveryCost,
            })
          } else {
            this.setState({
              errorCoupon: 'รหัสของท่านหมดอายุใช้งานแล้ว  กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม ',
            })
          }
        } else {
          this.setState({
            valid: true,
            calDiscount: true,
            coupon: newProps.coupon,
            errorCoupon: '',
            maxDate: endDate,
            minDate: startDate,
            discountType: discount.index === 0 ? 'Baht' : 'Percent',
            discountValue: discountValue,
            flagValidDiscountCode: true,
            discountSet: discountSet,
            discountDelivery: discountDelivery,
            discountValueNet: discountValueNet,
            totalAmount: totalAmount,
            menuCost: menuCost,
            deliveryCost: deliveryCost,
          })
        }
      } else {
        if (couponType.index === 1) {
          this.setState({
            errorCoupon: 'ไม่สามารถใช้งานได้ : ใช้งานผิดประเภท กรุณาเปิดหน้าแรกเพื่อใช้งานคูปองดังกล่าว', //Please enter discount coupon for package.
            flagValidDiscountCode: false,
          })
          return
        }

        if (this.state.calDiscount === false) {
          this.setState({
            errorCoupon: 'ไม่สามารถใช้งานได้ : กรุณาติดต่อเจ้าหน้าที่เพื่อสอบถามรายละเอียดที่ 02-016-9975 ', //Please enter discount coupon for package.
            flagValidDiscountCode: false,
          })
          return
        }

        // more error
      }
    } else {
      if (newProps.coupon.validationCodeDisCountFromMondayError === 'Error') {
        if (this.state.clickValidateCoupon === true) {
          this.setState({
            errorCoupon: 'ไม่สามารถใช้งานได้ : กรุณาติดต่อเจ้าหน้าที่เพื่อสอบถามรายละเอียดที่ 02-016-9975',
            clickValidateCoupon: false,
          })
        }
      }
    }
  }

  componentWillReceiveProps(newProps) {
    this.updateDiscountCode(newProps)
  }

  handleSubmit(e) {
    e.preventDefault()

    let returnSubmit = true

    if (this.state.firstname.trim() === '') {
      this.setState({
        errorRequireFirstName: true,
      })
      returnSubmit = false
    }
    if (this.state.lastname.trim() === '') {
      this.setState({
        errorRequireLastName: true,
      })
      returnSubmit = false
    }
    if (this.state.drop_offAddress.trim() === '') {
      this.setState({
        errorRequireDropoffAddress: true,
      })
      returnSubmit = false
    }
    if (this.state.phone.trim() === '') {
      this.setState({
        errorRequirePhone: true,
      })
      returnSubmit = false
    }
    if (this.state.Datetime === '') {
      this.setState({
        errorRequireBookingDate: true,
      })
      returnSubmit = false
    }
    if (this.state.errorRequireBookingTime !== '') {
      returnSubmit = false
    }
    if (returnSubmit === false) {
      return false
    }

    let status = ''
    let itemId = '-'
    let board_id = '-'
    if ((!this.state.flagValidCar && this.state.code !== '') || this.state.selectedCoupon === undefined) {
      this.props.popupError(
        'กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
      return
    }

    if (this.state.selectedCoupon !== '' && this.state.selectedCoupon !== undefined && this.state.code !== '') {
      status = JSON.parse(this.state.selectedCoupon.status) ? JSON.parse(this.state.selectedCoupon.status).index : ''
      itemId = this.state.selectedCoupon.id

      // board_id = this.state.selectedCoupon.board_id
    }
    if (this.state.selectedCoupon === '' && this.state.code !== '') {
      this.props.popupError(
        'กรุณากรอกรหัสสิทธิพิเศษให้ถูกต้อง กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
    } else if (this.state.selectedCoupon !== '' && (status.index === 2 || this.state.selectedCoupon.length === 0)) {
      // pin into note ว่าใช้แล้ว
      this.props.popupError(
        'รหัส ' + this.state.code + ' นี้ถูกใช้แล้ว กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
      //this.props.popupLoading()
      // this.props.submitBookingMonday(this.state.code, this.state)
    } else if (status.index === 0) {
      this.props.popupError(
        'ไม่สามารถใช้สิทธิพิเศษนี้ได้เนื่องจากรหัสถูกยกเลิกการใช้งาน กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'
      )
    } else {
      this.setState({ disabled: true, submit: true })
      // this.props.createCustomerTransactionAndBooking(this.state)

      this.props.submitBeforeBookingMonday(this.state.code, this.state, itemId)
    }
  }

  checkDiscountCodeManual() {
    if (this.state.discountCode) {
      this.setState({
        displayText: true,
        clickValidateCoupon: true,
        errorCoupon: '',
      })
      this.props.checkDiscountCode(this.state.discountCode)
    }
  }

  toggleChange = () => {
    this.setState({ isChecked: !this.state.isChecked })
  }

  fnc = () => {
    this.props.popupLoading()
    this.setState({ disabled: false, submit: false })

    if (this.props.coupon.dataTosend.data.isChecked === false) {
      debugger
      this.props.createCustomerTransactionAndBooking(this.props.coupon.dataTosend.data)
    } else {
      debugger
      this.props.submitBookingMonday(
        this.props.coupon.dataTosend.code,
        this.props.coupon.dataTosend.data,
        this.props.coupon.dataTosend.itemMondayId
      )
    }
  }
  returnConfirm = () => {
    this.setState({ disabled: false, submit: false })
  }

  isEmpty = obj => {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  checkFormByStep = step => {
    if (step === 1) {
      if (this.state.isChecked === false) {
        if (this.state.selectedRoute === null) {
          this.setState({
            errorSelectRoute: true,
          })
        }
        if (this.state.carType === null || this.state.carType === '') {
          this.setState({
            errorSelectCar: true,
          })
        }

        if (this.state.selectedRoute !== null && this.state.carType !== null && this.state.carType !== '') {
          this.setState({
            bookStep: 2,
          })
        }
      } else {
        if (this.state.flagValidCouponCode === true) {
          this.setState({
            bookStep: 2,
          })
        } else {
          this.setState({
            errorCouponCode: true,
          })
        }
      }
    }
  }

  getNewBanner = () => {
    if (!this.state.newFetch) {
      this.props.getBanner()
      this.setState({ newFetch: true })
    }
  }

  formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  render() {
    if (this.props.coupon.ticketId) {
      return <ThankForBooking />
    }
    // console.log(this.props)
    const { banner, menu, menu_id } = this.props
    let lang = window.localStorage.getItem('i18nextLng') ? window.localStorage.getItem('i18nextLng') : 'th'
    let coupon_cycle = this.state.coupon_cycle
    // console.log(this.state)
    if (menu && menu.list && menu.list.length === 0) {
      return <div>Loading...</div>
    }
    let menuList = []
    let selectedMenu = {}
    if (menu && menu.list && menu.list.length === 0) {
      return <div>Loading...</div>
    }
    if (
      menu.list.filter(item => item.long_text).length !== 0 &&
      menu.list.filter(item => item.id === menu_id).length !== 0
    ) {
      selectedMenu = menu.list.find(item => item.id === menu_id)
    }
    if (menu.list.filter(item => item.id === menu_id).length === 0) {
      return <Redirect to={{ pathname: '/' }} />
    }
    if (selectedMenu && selectedMenu.text4.text && this.state.defaultPrice === null) {
      this.setState({
        defaultPrice: parseInt(selectedMenu.text4.text.replace('"', '')),
      })
      if (this.state.totalAmount === null) {
        this.setState({
          totalAmount: parseInt(selectedMenu.text4.text.replace('"', '')) + this.state.deliveryFee,
          selectedMenu: selectedMenu,
        })
      }
    }
    if (selectedMenu.long_text.text || selectedMenu.long_text.text5.text) {
      coupon_cycle =
        coupon_cycle.length == 0
          ? selectedMenu && selectedMenu.additional_info && selectedMenu.additional_info.status_17
            ? selectedMenu.additional_info.status_17.label.split(',')
            : []
          : coupon_cycle
      const menulistJSON = lang === 'th' ? selectedMenu.long_text : selectedMenu.long_text.text5
      if (menulistJSON && menulistJSON.text) {
        menuList = menulistJSON.text.split('\n')
      } else if (!menulistJSON) {
        const menulistJSON2 = lang === 'th' ? selectedMenu.long_text.text5 : selectedMenu.long_text
        if (menulistJSON2 && menulistJSON2.text) {
          menuList = menulistJSON2.text.split('\n')
        }
      }
    }

    // feature : CARD_ONLY
    let CARD_ONLY_CHECKED = false

    if (this.state.selectedCoupon) {
      if (this.state.selectedCoupon.check9) {
        let checkCol = JSON.parse(this.state.selectedCoupon.check9)
        if (checkCol && checkCol.checked) {
          CARD_ONLY_CHECKED = true
        }
      }
    }

    // console.log(selectedMenu)
    return (
      <div className="container booking-page">
        <div className="page-circle"></div>
        <div className="title-page">Booking</div>
        <form onSubmit={this.handleSubmit}>
          {this.state.bookStep === 2 ? (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="row ">
                    <div className="booking-form col-md mx-3">
                      <div className="row ">
                        <div className="col-12  p-form">
                          <div id="step-1" className="step">
                            <h5 className="mb-3">
                              <Translation>{(t, { i18n }) => t('Personalinformation')}</Translation>
                            </h5>
                            <div className="row">
                              <div className="form-group col name">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <input
                                      type="text"
                                      id="firstname"
                                      name="firstname"
                                      className="form-control"
                                      style={this.formStyle}
                                      placeholder={t('firstname')}
                                      onChange={e => {
                                        this.setState({
                                          firstname: e.target.value,
                                          errorRequireFirstName: e.target.value.trim() !== '' ? false : true,
                                        })
                                      }}
                                      value={this.state.firstname}
                                    />
                                  )}
                                </Translation>
                                {this.state.errorRequireFirstName === true ? (
                                  <p style={{ color: 'red' }}>*Please enter first name</p>
                                ) : (
                                  ''
                                )}
                              </div>
                              <div className="form-group col name">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <input
                                      type="text"
                                      id="lastname"
                                      name="lastname"
                                      className="form-control"
                                      style={this.formStyle}
                                      placeholder={t('lastname')}
                                      onChange={e => {
                                        this.setState({
                                          lastname: e.target.value,
                                          errorRequireLastName: e.target.value.trim() !== '' ? false : true,
                                        })
                                      }}
                                      value={this.state.lastname}
                                    />
                                  )}
                                </Translation>
                                {this.state.errorRequireLastName === true ? (
                                  <p style={{ color: 'red' }}>*Please enter last name</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <input
                                      type="text"
                                      id="mobile"
                                      name="mobile"
                                      className="form-control"
                                      style={this.formStyle}
                                      minLength="10"
                                      maxLength="10"
                                      placeholder={t('phone')}
                                      onChange={e => {
                                        var regPhone = new RegExp('^[0][0-9-]+$')
                                        this.setState({
                                          errorRequirePhoneWrongPattern: !regPhone.test(e.target.value),
                                        })
                                        let val = this.normalizePhone(e.target.value)
                                        this.setState({
                                          phone: val,
                                          errorRequirePhone: val.trim() !== '' ? false : true,
                                        })
                                      }}
                                      value={this.state.phone}
                                    />
                                  )}
                                </Translation>
                                {this.state.errorRequirePhoneWrongPattern === true ? (
                                  <p style={{ color: 'red' }}>*Please enter mobile number as 0123456789</p>
                                ) : (
                                  ''
                                )}
                                {this.state.errorRequirePhone === true ? (
                                  <p style={{ color: 'red' }}>*Please enter mobile number</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <input
                                      type="text"
                                      id="email"
                                      name="email"
                                      className="form-control"
                                      style={this.formStyle}
                                      placeholder={t('email')}
                                      onChange={e => {
                                        // this.handleInputChange(e.target.value, 'email')
                                        this.setState({
                                          email: e.target.value,
                                        })
                                      }}
                                      value={this.state.email}
                                    />
                                  )}
                                </Translation>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <DatePicker
                                      id="datepicker"
                                      className="form-control"
                                      placeholderText={t('BookingDate')}
                                      dateFormat="dd/MM/yyyy"
                                      style={{ width: '100% !important' }}
                                      autoComplete={'off'}
                                      selected={this.state.Datetime}
                                      minDate={
                                        this.state.minDate !== undefined
                                          ? new Date(this.state.minDate)
                                          : addDays(new Date(), 1)
                                      }
                                      maxDate={this.state.maxDate === '' ? '' : new Date(this.state.maxDate)}
                                      onChange={value => this.onchangeDate(value, 'datepicker')}
                                    />
                                  )}
                                </Translation>
                                {this.state.errorRequireBookingDate === true ? (
                                  <p style={{ color: 'red' }}>*Please choice booking date</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col">
                                <Translation>
                                  {t => (
                                    <select
                                      name="BookingTime"
                                      id="BookingTime"
                                      className="form-control"
                                      style={this.formStyle}
                                      value={null}
                                      disabled={this.state.Datetime ? false : true}
                                      placeholder={t('BookingTime')}
                                      onChange={({ target: { value } }) => this.onchangeDate(value, 'bookingTime')}
                                      required
                                    >
                                      <option value=""> {t('BookingTime')} </option>
                                      {coupon_cycle.find(t => t === '11:30 - 13:30') ? (
                                        <option value="102">11:30 - 13:30 </option>
                                      ) : (
                                        ''
                                      )}
                                      {/* {coupon_cycle.find(t => t === '12:00 - 13:30') ? (
                                        <option value="102">12:00 - 13:30 </option>
                                      ) : (
                                        ''
                                      )} */}
                                      {coupon_cycle.find(t => t === '17:00 - 19:30') ? (
                                        <option value="151">17:00 - 19:30 </option>
                                      ) : (
                                        ''
                                      )}
                                      {coupon_cycle.find(t => t === '14:00 - 17:00') ? (
                                        <option value="157">14:00 - 17:00 </option>
                                      ) : (
                                        ''
                                      )}
                                      {/* {coupon_cycle.find(t => t === '16:00 - 19:30') ? (
                                        <option value="158">16:00 - 19:30 </option>
                                      ) : (
                                        ''
                                      )} */}
                                      {coupon_cycle.find(t => t === '16:30 - 19:30') ? (
                                        <option value="151">16:30 - 19:30 </option>
                                      ) : (
                                        ''
                                      )}
                                    </select>
                                  )}
                                </Translation>
                                {this.state.errorRequireBookingTime !== '' ? (
                                  <p style={{ color: 'red' }}>*{this.state.errorRequireBookingTime}</p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </div>
                          <div id="step-3" className="step pt-4">
                            <h5 className="mb-3">
                              <Translation>{(t, { i18n }) => t('Dropoffinformation')}</Translation>
                            </h5>
                            <p style={{ color: '#850612', fontSize: '13px', paddingLeft: '10px' }}>
                              {' '}
                              <Translation>{(t, { i18n }) => t('description.third')}</Translation>
                            </p>
                            <div id="drop_off-location" className="drop_off_group">
                              {/* <div className="row">
                            <div className="form-group col">

                              <Translation>
                                {(t, { i18n }) => (

                                      <Geolocated/>

                                )}
                              </Translation>
                            </div>
                          </div>
                      <h6> OR </h6> */}
                              <div className="row">
                                <div className="form-group col">
                                  <Translation>
                                    {(t, { i18n }) => (
                                      <textarea
                                        name="drop_off_address"
                                        id="drop_off_address"
                                        className="form-control"
                                        style={this.formStyle}
                                        placeholder={t('address')}
                                        onChange={e => {
                                          // this.handleInputChange(e.target.value, 'drop_offAddress');
                                          this.setState({
                                            drop_offAddress: e.target.value,
                                            errorRequireDropoffAddress: e.target.value.trim() !== '' ? false : true,
                                          })
                                        }}
                                        value={this.state.drop_offAddress}
                                      ></textarea>
                                    )}
                                  </Translation>
                                  {this.state.errorRequireDropoffAddress === true ? (
                                    <p style={{ color: 'red' }}>*Please enter drop off address</p>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              <div className="row">
                                <div className="form-group col">
                                  <Translation>
                                    {(t, { i18n }) => (
                                      <input
                                        type="text"
                                        id="drop_off_postcode"
                                        name="drop_off_postcode"
                                        className="form-control"
                                        style={this.formStyle}
                                        placeholder={t('point')}
                                        onChange={e => {
                                          // this.handleInputChange(e.target.value, 'drop_off_interest')
                                          this.setState({
                                            drop_off_interest: e.target.value,
                                          })
                                        }}
                                        value={this.state.drop_off_interest}
                                      />
                                    )}
                                  </Translation>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="step-4" className="step  pt-3">
                            <h5 className="mb-3">
                              <Translation>{(t, { i18n }) => t('SpecialNote')}</Translation>
                            </h5>
                            <div className="row">
                              <div className="form-group col">
                                <Translation>
                                  {(t, { i18n }) => (
                                    <textarea
                                      name="special_Note"
                                      id="special_Note"
                                      className="form-control"
                                      style={this.formStyle}
                                      placeholder={t('SpecialNote')}
                                      onChange={e =>
                                        // {this.handleInputChange(e.target.value, 'note'
                                        this.setState({
                                          note: e.target.value,
                                        })
                                      }
                                      value={this.state.note}
                                    ></textarea>
                                  )}
                                </Translation>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="booking-form col-md mx-3 noPadding">
                      <div id="step-1" className="step menu">
                        <h5 className="mb-3" style={{ paddingBottom: '30px', fontWeight: 'bold' }}>
                          <Translation>{(t, { i18n }) => t('HeaderSelectedMenu')}</Translation>
                        </h5>
                        <center>
                          <h5
                            className="mb-12"
                            style={{ paddingBottom: '30px', fontWeight: 'bold', paddingLeft: '0px' }}
                          >
                            {selectedMenu.name.replace(/"/g, '')}
                          </h5>
                        </center>
                        <div className="row">
                          <div className="form-group col menu-billing">
                            <section>
                              <center>
                                {menuList.map(data => {
                                  return <p>{data}</p>
                                })}
                              </center>
                            </section>
                          </div>
                        </div>
                      </div>
                      <div className="billingTab">
                        {this.state.couponValids ? (
                          <table>
                            {' '}
                            <tfoot>
                              <tr>
                                <th> {this.state.couponValids.name} </th>
                                <th>
                                  {' '}
                                  มูลค่า :{' '}
                                  {this.state.couponValids.numbers
                                    ? this.state.couponValids.numbers.replace(/"/g, '')
                                    : '-'}{' '}
                                  บาท
                                </th>
                              </tr>
                              <tr>
                                <th className="total"> หมายเลขคูปอง </th>
                                <th className="summary">
                                  {' '}
                                  {this.state.couponValids.text8
                                    .replace(/"/g, '')
                                    .replace(/"/g, '')
                                    .slice(0, -3) + 'XXX'}{' '}
                                </th>
                              </tr>
                            </tfoot>
                          </table>
                        ) : (
                          <div>
                            <h5 className="mb-3">
                              <Translation>{(t, { i18n }) => t('Billing')}</Translation>
                            </h5>
                            <table>
                              <tbody>
                                <tr>
                                  <th>
                                    {' '}
                                    <Translation>{(t, { i18n }) => t('MenuPrice')}</Translation>
                                  </th>
                                  <td>
                                    {this.formatNumber(parseInt(selectedMenu.text4.text.replace(/"/g, '')).toFixed(2))}
                                  </td>
                                </tr>
                                <tr>
                                  <th>
                                    {' '}
                                    <Translation>{(t, { i18n }) => t('DeliveryFee')}</Translation>
                                  </th>
                                  <td>{this.formatNumber(this.state.deliveryFee.toFixed(2))}</td>
                                </tr>
                                <tr>
                                  <th style={{ paddingTop: '18px' }}>
                                    {this.props.coupon.firstLaunchCoupon.length > 0 &&
                                    this.state.displayText &&
                                    this.state.displayText === 'FREEFIRST350AXGE' ? (
                                      this.state.discountValueNet != null ? (
                                        <div>
                                          {' '}
                                          <Translation>{(t, { i18n }) => t('ส่วนลด')}</Translation>{' '}
                                          <span style={{ color: 'gray' }}>({this.state.displayText})</span>
                                        </div>
                                      ) : this.state.errorCoupon === '' ? (
                                        <p style={{ color: 'red' }}>
                                          {'กำลังตรวจสอบ... : การตรวจสอบดังกล่าวเป็นเพียงการตรวจสอบเบื้องต้นเท่านั้น'}
                                        </p>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </th>
                                  <td style={{ paddingTop: '18px' }}>
                                    {' '}
                                    {this.props.coupon.firstLaunchCoupon.length > 0 &&
                                    this.state.displayText === 'FREEFIRST350AXGE' &&
                                    this.state.displayText &&
                                    this.state.discountValueNet != null ? (
                                      <p style={{ color: 'red' }}> {'-' + this.state.discountValueNet} </p>
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ paddingTop: '18px' }}>
                                    {' '}
                                    <Translation>
                                      {(t, { i18n }) => (
                                        <input
                                          type="text"
                                          id="code-box"
                                          style={{
                                            borderRadius: '0px 20px 20px 0px',
                                            display:
                                              this.state.displayText !== 'FREEFIRST350AXGE' &&
                                              this.state.displayText &&
                                              this.state.discountValueNet != null
                                                ? 'none'
                                                : 'initial',
                                          }}
                                          name="discountCode"
                                          className="form-control "
                                          placeholder={t('discountCode')}
                                          onChange={({ target: { value } }) =>
                                            this.setState({
                                              discountCode: value,
                                            })
                                          }
                                          value={this.state.discountCode}
                                          readOnly={
                                            this.state.flagValidDiscountCode && !this.props.coupon.firstLaunchCoupon
                                          }
                                        />
                                      )}
                                    </Translation>
                                    {this.state.errorCoupon !== '' ? (
                                      <p style={{ color: 'red' }}>{this.state.errorCoupon}</p>
                                    ) : (
                                      ''
                                    )}
                                    {this.state.displayText && this.state.displayText !== 'FREEFIRST350AXGE' ? (
                                      this.state.discountValueNet != null && this.state.flagValidDiscountCode ? (
                                        <div>
                                          {' '}
                                          <Translation>{(t, { i18n }) => t('ส่วนลด')}</Translation>{' '}
                                          <span style={{ color: 'gray' }}>({this.state.displayText})</span>
                                        </div>
                                      ) : this.state.errorCoupon === '' ? (
                                        <p style={{ color: 'red' }}>
                                          {'กำลังตรวจสอบ... : การตรวจสอบดังกล่าวเป็นเพียงการตรวจสอบเบื้องต้นเท่านั้น'}
                                        </p>
                                      ) : (
                                        ''
                                      )
                                    ) : (
                                      ''
                                    )}
                                  </th>
                                  <td style={{ paddingTop: '18px' }}>
                                    {' '}
                                    {this.state.displayText !== 'FREEFIRST350AXGE' &&
                                    this.state.displayText &&
                                    this.state.discountValueNet != null &&
                                    this.state.flagValidDiscountCode ? (
                                      <p style={{ color: 'red' }}> {'-' + this.state.discountValueNet} </p>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-check"
                                        onClick={this.checkDiscountCodeManual.bind(this)}
                                      >
                                        CHECK
                                      </button>
                                    )}
                                  </td>
                                </tr>
                                {CARD_ONLY_CHECKED || this.state.messageAfter ? (
                                  <tr style={{ paddingTop: '36px', paddingBottom: '36px', width: '100%' }}>
                                    <td
                                      style={{
                                        position: 'absolute',
                                        width: '100%',
                                        color: '#850612 ',
                                        textAlign: 'center',
                                        padding: '10px',
                                      }}
                                    >
                                      {this.state.messageAfter ? (
                                        this.state.messageAfter
                                      ) : (
                                        <Translation>{(t, { i18n }) => t('CheckCardOnly')}</Translation>
                                      )}
                                    </td>
                                    <td></td>
                                  </tr>
                                ) : (
                                  ''
                                )}
                                {CARD_ONLY_CHECKED || this.state.messageAfter ? (
                                  <tr>
                                    <td className="space" colSpan="4"></td>
                                  </tr>
                                ) : (
                                  ''
                                )}

                                {CARD_ONLY_CHECKED || this.state.messageAfter ? (
                                  <tr>
                                    <td className="space" colSpan="4"></td>
                                  </tr>
                                ) : (
                                  ''
                                )}

                                <tr>
                                  <td className="space" colSpan="4"></td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <th className="total">
                                    {' '}
                                    <Translation>{(t, { i18n }) => t('Total')}</Translation>
                                  </th>
                                  <th className="summary">{this.formatNumber(parseInt(this.state.totalAmount))}</th>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          {this.state.bookStep === 1 ? (
            <div className="text-right cc  pt-3 pb-5">
              <Link to="/">
                <button type="button" id="btn-cancel" className="btn-booking-clear mr-4" disabled={this.state.disabled}>
                  <Translation>{(t, { i18n }) => t('Back')}</Translation>
                </button>
              </Link>
              <button
                type="button"
                className="btn-booking"
                disabled={this.state.disabled}
                onClick={e => this.checkFormByStep(1)}
              >
                <Translation>{(t, { i18n }) => t('Next')}</Translation>
              </button>
              <TermModal type={this.state.submit} returnData={this.fnc} />
            </div>
          ) : (
            ''
          )}

          {this.state.bookStep === 2 ? (
            <div className="buttonBookAndBack pt-3 pb-5">
              <Link to="/">
                <button type="button" id="btn-cancel" className="btn-booking-clear mr-4">
                  <Translation>{(t, { i18n }) => t('Back')}</Translation>
                </button>
              </Link>
              <button
                type="submit"
                id="btn-submit"
                className="btn-booking"
                disabled={this.state.disabled || this.state.errorRequirePhoneWrongPattern === true}
              >
                <Translation>{(t, { i18n }) => t('Booking')}</Translation>
              </button>
              <TermModal type={this.state.submit} returnConfirm={this.returnConfirm} returnData={this.fnc} />
            </div>
          ) : (
            ''
          )}
        </form>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup, banner, menu }, ownProps) => {
  const menu_id = ownProps.match.params.menu_id
  return { coupon, popup, banner, menu, menu_id }
}

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
  submitBookingMonday,
  submitToCore,
  GetCodeValid,
  submitBeforeBookingMonday,
  popupSuccess,
  resetDiscountCode,
  checkDiscountCodeFirst,
  checkDiscountCode,
  createCustomerTransaction,
  getBanner,
  createCustomerTransactionAndBooking,
  menuListFetch,
})(MainForm)
