import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

class TermModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalTermStatus: false,
      data: this.props.data,
      submitFn: '',
    }
  }
  onClickTerm = event => {
    event.preventDefault()
    this.setState({
      modalTermStatus: true,
    })
    document.body.classList.add('modal-open')
  }
  onCloseTerm = event => {
    event.preventDefault()
    this.setState({
      modalTermStatus: false,
    })
    document.body.classList.remove('modal-open')
  }

  onSend = event => {
    event.preventDefault()
    this.setState({
      modalTermStatus: false,
    })
    document.body.classList.remove('modal-open')
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.type) {
      this.setState({
        modalTermStatus: true,
      })
      document.body.classList.add('modal-open')
    } else {
      this.setState({
        modalTermStatus: false,
      })
      document.body.classList.remove('modal-open')
    }
  }

  render() {
    if (this.state.modalTermStatus === true) {
      document.body.classList.add('modal-open-manual')
    } else {
      document.body.classList.remove('modal-open-manual')
    }
    return (
      <div>
        <a style={{ display: !this.props ? 'block' : 'none' }} href="#consent_form">
          Terms & Conditions
        </a>
        <div
          className={`form modal ${this.state.modalTermStatus === true && 'd-block'} `}
          id="consent_form"
          tabIndex="999999"
          role="dialog"
          aria-labelledby="consent_form_title"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="detail-1">
                <div className="modal-header">
                  <div
                    className="modal-close"
                    onClick={e => {
                      this.onCloseTerm(e)
                      this.props.returnConfirm()
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </div>

                  <span className="modal-title" id="consent_form_title"></span>
                </div>

                <div className="modal-body">
                  <center>
                    <h5>
                      <b>
                        <Translation>{(t, { i18n }) => t('ConfirmQ')}</Translation>{' '}
                      </b>
                    </h5>
                    <br />
                    <br />

                    <button
                      style={{ display: this.props.type ? 'initial' : 'none' }}
                      className="btn-booking w-80 mb-3"
                      onClick={e => {
                        this.onCloseTerm(e)
                        this.props.returnData()
                      }}
                    >
                      <Translation>{(t, { i18n }) => t('Confirm')}</Translation>
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TermModal
