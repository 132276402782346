import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { BANNER_GET_BANNER, BANNER_GET_PACKAGE } from '../actions/types'
import { setBanner, setPackage } from '../actions'
import axios from '../util/APIUtils'

// Request
const mondayNEXTPriceSettingQueryRequest = async payload => {
  const mondayNEXTPriceSettingQuery = `query ($board: Int!) { boards (ids:[$board]){ items { id name column_values { id title value}}} } `
  const body = {
    query: mondayNEXTPriceSettingQuery,
    variables: {
      board: 400104835,
    },
  }

  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const mondayNEXTRouteSettingQueryRequest = async payload => {
  const mondayNEXTRouteSettingQuery = `query ($board: Int!) { boards (ids:[$board]){ items { id name column_values { id title value}}} } `
  const body = {
    query: mondayNEXTRouteSettingQuery,
    variables: {
      board: 397708412,
    },
  }

  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const destucture = async payload => {
  let newPayload = payload.data.boards[0].items.map(priceSettingItem => {
    let newItem = {}
    newItem['id'] = priceSettingItem.id
    newItem['name'] = priceSettingItem.name
    Object.keys(priceSettingItem.column_values).forEach(function(key) {
      newItem[priceSettingItem.column_values[key].id] = priceSettingItem.column_values[key].value
    })

    return newItem
  })

  return newPayload
}

const extractRoute1Way = async payload => {
  let extracted = payload
    .filter(route => {
      //filter  1 way price to main route
      if (route.numbers9 && route.numbers9.replace(/"/g, '') === '1') {
        return true
      }

      return false
    })
    .map(route => {
      return {
        routeWay: route.name,
        price: {
          ECO: route.numbers ? parseInt(route.numbers.replace(/"/g, '')) : 0,
          SUV: route.eco_price8 ? parseInt(route.eco_price8.replace(/"/g, '')) : 0,
          VAN: route.eco_price ? parseInt(route.eco_price.replace(/"/g, '')) : 0,
        },
        airport: [],
        homeOffice: [],
        prices: [],
      }
    })

  return extracted
}

const addSettingToMainRoute = async payload => {
  let mapRoute = new Map()
  let clonePayload = payload
  payload.extractRouteWay.map((routeMain, index) => {
    mapRoute.set(routeMain.routeWay, index)
    return routeMain
  })

  payload.routeDestucture.map(setting => {
    if (setting.text && setting.text.replace(/"/g, '')) {
      let index = mapRoute.get(setting.text.replace(/"/g, ''))
      try {
        clonePayload.extractRouteWay[index].homeOffice = Array.from(
          new Set([...clonePayload.extractRouteWay[index].homeOffice, setting.text8.replace(/"/g, '')])
        )
        clonePayload.extractRouteWay[index].airport = Array.from(
          new Set([...clonePayload.extractRouteWay[index].airport, setting.text1.replace(/"/g, '')])
        )
      } catch {}
    }
    return setting
  })

  return { route: clonePayload.extractRouteWay }
}
const addPackageToMainRoute = async payload => {
  let mapRoute = new Map()
  let clonePayload = payload

  payload.settingData.route.map((routeMain, index) => {
    mapRoute.set(routeMain.routeWay, index)
    return routeMain
  })

  payload.priceDestucture.map(packageItem => {
    if (packageItem.text7 && packageItem.text7.replace(/"/g, '')) {
      let index = mapRoute.get(packageItem.text7.replace(/"/g, ''))
      clonePayload.settingData.route[index].prices.push({
        ECO: {
          amount: parseInt(packageItem.numbers9.replace(/"/g, '')),
          price: packageItem.numbers ? parseInt(packageItem.numbers.replace(/"/g, '')) : 0,
        },
        SUV: {
          amount: parseInt(packageItem.numbers9.replace(/"/g, '')),
          price: packageItem.eco_price8 ? parseInt(packageItem.eco_price8.replace(/"/g, '')) : 0,
        },
        VAN: {
          amount: parseInt(packageItem.numbers9.replace(/"/g, '')),
          price: packageItem.eco_price ? parseInt(packageItem.eco_price.replace(/"/g, '')) : 0,
        },
      })
    }
    return packageItem
  })

  return { packagePrice: clonePayload.settingData }
}

//sagas

function* mondayNEXTSettingQuery({ payload }) {
  try {
    const dataPrice = yield call(mondayNEXTPriceSettingQueryRequest, payload)
    const priceDestucture = yield call(destucture, dataPrice)
    const extractRouteWay = yield call(extractRoute1Way, priceDestucture)
    const dataRoute = yield call(mondayNEXTRouteSettingQueryRequest, payload)
    const routeDestucture = yield call(destucture, dataRoute)
    const settingData = yield call(addSettingToMainRoute, { extractRouteWay, routeDestucture })
    const data = yield call(addPackageToMainRoute, { settingData, priceDestucture })
    yield put(setBanner(settingData))
    yield put(setPackage(data))
  } catch (error) {
    console.log(error)
  }
}

//Wacher

function* requestBannerWatcher() {
  yield takeEvery(BANNER_GET_BANNER, mondayNEXTSettingQuery)
}

function* requestPackageWatcher() {
  yield takeEvery(BANNER_GET_PACKAGE, mondayNEXTSettingQuery)
}

export default function* rootSaga() {
  yield all([fork(requestBannerWatcher), fork(requestPackageWatcher)])
}
