import React, { Component } from 'react'
import PopupLayout from './layoutModal'
import '../../assets/css/app.css'

class ConsentModal extends Component {
  constructor(props) {
    super(props)
    this.state = { selected: 1 }
  }

  render() {
    return (
      <PopupLayout display={this.props.display}>
        <div className="detail-1" style={{ display: this.state.selected === 1 ? 'initial' : 'none' }}>
          <div className="modal-header">
            <h5 className="modal-title" id="consent_form_title">
              ข้อกำหนดและเงื่อนไขการใช้บริการสิทธิพิเศษ
            </h5>
          </div>

          <div className="modal-body">
            ในการใช้สิทธิพิเศษนี้ ข้าพเจ้าตกลงและให้ความยินยอมดังต่อไปนี้ <br />
            <br />
            <ol>
              <li>เงื่อนไขที่ 1</li>
              <br />
              <li>เงื่อนไขที่ 2</li>
              <br />
              <li>เงื่อนไขที่ 3</li>
              <br />
              <li>เงื่อนไขที่ 4</li>
              <br />
              <li>เงื่อนไขที่ 5</li>
            </ol>
          </div>
          <div className="modal-footer text-center d-block ">
            <button
              type="button"
              className="btn btn-primary btn-md button_agree_term"
              onClick={() => {
                this.props.closePopup()
              }}
            >
              ยอมรับข้อกำหนดและเงื่อนไข
            </button>
          </div>
          <div className="modal-footer text-center d-block pt-0">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={() => {
                this.setState({ selected: 2 })
              }}
            >
              ยกเลิก
            </button>
          </div>
        </div>
        <div className="detail-2" style={{ display: this.state.selected === 2 ? 'initial' : 'none' }}>
          <div className="modal-header">
            <h5 className="modal-title" id="consent_form_title">
              ติดต่อสอบถาม
            </h5>
          </div>
          <div className="modal-body text-center">
            <p>
              <span>
                <i className="icon-envelope"></i> booking@worldrewardsolutions.com
              </span>
              <br />
              <i className="icon-phone"></i> Phone: 02-016-9950
              <br />
              <i className="icon-clock"></i> Everyday: 24 Hours
            </p>
          </div>
          <div className="modal-footer text-center d-block pt-0">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={() => {
                this.setState({ selected: 1 })
              }}
            >
              กลับสู่หน้าข้อกำหนดและเงื่อนไข
            </button>
          </div>
        </div>
      </PopupLayout>
    )
  }
}

export default ConsentModal
