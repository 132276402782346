import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { MENU_LIST_FETCH } from '../actions/types'
import { menuListFetchSuccess, restaurantFetchSuccess, bannerFetchSuccess } from '../actions'
import axios from '../util/APIUtils'

const menuListFetchRequest = async () => {
  return await axios
    .get('https://automation.rewrds.me/webhook/3bd1f8f9-cd5c-485e-8978-d8887d20744c/query/506829974', {
      headers: '',
    })
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

const getSettingRequest = async payload => {
  // const SettingQuery = `query {
  //       boards (ids: 554011129) {
  //         groups (ids: group_title) {
  //               title
  //               color
  //               position
  //           items { id name column_values { id title value}}
  //         }
  //       }
  //     }`
  // const body = {
  //   query: SettingQuery,
  // }

  return await axios
    .get('https://automation.rewrds.me/webhook/3bd1f8f9-cd5c-485e-8978-d8887d20744c/query/554011129', {})
    .catch(err => {
      console.error(err)
    })
    .then(res => {
      return res
    })
}

//sagas
function* menuListFetch() {
  try {
    const menuList = yield call(menuListFetchRequest)

    // debugger

    let newPayload = menuList.data.items_by_column_values[0].map(menu => {
      // if(menu.id === "3276853131"){
      //   debugger
      // }

      // debugger

      let newItem = { ...menu.json.destructor }
      newItem['id'] = menu.json.id
      newItem['name'] = menu.json.name
      newItem['additional_info'] = {}
      // Object.keys(menu.column_values).forEach(function(key) {
      //   newItem[menu.column_values[key].id] = menu.column_values[key].value
      //   if (menu.column_values[key].additional_info) {
      //     newItem['additional_info'][menu.column_values[key].id] = JSON.parse(menu.column_values[key].additional_info)
      //   }
      // })

      return newItem
    })

    const SettingRequest = yield call(getSettingRequest)

    let restaurant = [], banner = []

    SettingRequest.data.data.items_by_column_values[0].map(item => {
      if (  item.json.destructor.status8 
            && item.json.destructor.status8.value
            && ( item.json.destructor.status8.value.index === 0 || item.json.destructor.status8.value.index === 4)) {

        restaurant.push({
          name: item.json.destructor.text7.text.replace(/\"/g, ''),
          img: item.json.destructor.long_text.text.replace(/\"/g, ''),
          slug: item.json.destructor.text.text.replace(/\"/g, ''),
        })
      } else if (item.json.destructor.status8.value.index && item.json.destructor.status8.value && item.json.destructor.status8.value.index === 1) {
        banner.push({
          src: item.json.destructor.long_text.text.replace(/\"/g, ''),
          altText: item.json.destructor.text7.text.replace(/\"/g, ''),
          header: item.json.destructor.text7.text.replace(/\"/g, ''),
          caption_th:
          item.json.destructor.long_text4 && item.json.destructor.long_text4.text
              ? item.json.destructor.long_text4.text
              : item.json.destructor.long_text0.text,
          caption_en:
          item.json.destructor.long_text0.text && item.json.destructor.long_text0
              ? item.json.destructor.long_text0.text
              : item.json.destructor.long_text4.text,
        })
      }
    })

    yield put(restaurantFetchSuccess(restaurant.filter(r => { return newPayload.find(m => m.text.text.search(r.slug)!==-1)})))
    yield put(bannerFetchSuccess(banner))
    yield put(menuListFetchSuccess(newPayload))
  } catch (error) {
    console.log(error)
  }
}

//Wacher

export function* menuListFetchWatcher() {
  yield takeEvery(MENU_LIST_FETCH, menuListFetch)
}

export default function* rootSaga() {
  yield all([
    //fork(requestBannerWatcher),
    fork(menuListFetchWatcher),
  ])
}
