import React, { Component } from 'react'
import { connect } from 'react-redux'

import '../../assets/css/app.css'
import '../../assets/css/banner-modal.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import imgCar from '../../assets/imgs/car-1.png'
import nextWord from '../../assets/imgs/next-word.png'
import imgDriver from '../../assets/imgs/driver.png'
import imgCarInsurance from '../../assets/imgs/car-insurance.png'
import imgSecurity from '../../assets/imgs/security.png'
import imgCar2 from '../../assets/imgs/car.png'
import imgForm from '../../assets/imgs/form.png'
import imgCarO from '../../assets/imgs/car-o.png'
import imgTicket from '../../assets/imgs/ticket.png'
import imgMap from '../../assets/imgs/map.png'
import imgPlane from '../../assets/imgs/plane.png'
import imgLeft from '../../assets/imgs/left.png'
import imgRight from '../../assets/imgs/right.png'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { popupHidden, getBanner, closeBannerPopup } from '../../actions'

class BannerModal extends Component {
  onCloseBanner = event => {
    this.props.closeBannerPopup()
  }
  render() {
    const banner = this.props.banner
    if (banner.displayPopup === true) {
      document.body.classList.add('modal-open')
    }
    return (
      <div>
        <div
          className={`modal w-100 ${banner.displayPopup === true && 'd-block'} `}
          id="banner_modal"
          tabIndex="999999"
          role="dialog"
          aria-labelledby="banner_modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <Carousel
                showArrows={false}
                infiniteLoop={true}
                showThumbs={false}
                swipeable={true}
                showStatus={false}
                autoPlay={false}
              >
                <div className="modal-body">
                  <div className="modal-close" onClick={this.onCloseBanner}>
                    <span aria-hidden="true">&times;</span>
                  </div>
                  <span className="bg-circle-white"></span>
                  <div className="row banner-content align-center">
                    <div className="col-12 col-md-6">
                      <div className="div-img-car-o">
                        <img alt="" src={imgCar} className="banner-img-car" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 next-content">
                      <div className="title-top" style={{ paddingBottom: '10px' }}>
                        เดินทางสะดวกกับ Eco Sedan
                      </div>
                      <div className="next-detail">
                        <div className="bar"></div>
                        <div className="">
                          <div className="next-list">
                            <div className="head">
                              <img alt="" src={nextWord} className="mr-3 next-word" />
                              rate
                            </div>
                            <div className="sub-head">Flat rate anywhere anytime</div>
                          </div>
                          <div className="next-list">
                            <div className="head">
                              <img alt="" src={nextWord} className="mr-3 next-word" />
                              amenity
                            </div>
                            <div className="sub-head">Net amenity</div>
                          </div>
                          <div className="next-list">
                            <div className="head">
                              <img alt="" src={nextWord} className="mr-3 next-word" />
                              guarantee service{' '}
                            </div>
                            <div className="sub-head">You could surely get booking anywhere anytime</div>
                          </div>
                        </div>
                      </div>
                      <div className="title-bottom">
                        “Your
                        <img alt="" src={nextWord} className="next-word" />
                        ride anywhere”
                      </div>
                    </div>
                  </div>
                </div>

                {banner.data.route &&
                  banner.data.route.length > 0 &&
                  banner.data.route.map((dataItem, i) => {
                    return (
                      <div className="modal-body" key={i}>
                        <div className="modal-close" onClick={this.onCloseBanner}>
                          <span aria-hidden="true">&times;</span>
                        </div>
                        <span className="bg-circle-white"></span>
                        <div className="row banner-content">
                          <div className="col-12 col-md-6">
                            <div className="div-img-car-o img-fix">
                              <img alt="" src={imgCar} className="banner-img-car" />
                            </div>
                          </div>
                          <div className="col-12 col-md-6 next-content text-center">
                            <div className="title-top">
                              <p>เดินทางสะดวกกับ Eco Sedan</p>
                              <br />
                              Our Service
                            </div>
                            <div className="next-detail-2">
                              <div className="row justify-content-center mx-auto next-check-list">
                                <div className="col-12">
                                  <span className="price">{dataItem.price['ECO']}</span>
                                  <br />
                                  <span className="unit">Baht/Way</span>
                                </div>
                              </div>
                              <div className="row justify-content-center mx-auto next-check-list position-relative">
                                <img alt="" src={imgRight} className="arrow right" />
                                <img alt="" src={imgLeft} className="arrow left" />
                                <div className="col-6 col-next-service">
                                  <div>
                                    <img alt="" src={imgMap} className="img-next-service" />
                                  </div>
                                  <span className="detail-next-service">
                                    {dataItem.homeOffice.length
                                      ? dataItem.homeOffice.map(item => (
                                          <>
                                            {item}
                                            <br />
                                          </>
                                        ))
                                      : ''}
                                  </span>
                                </div>
                                <div className="col-6 col-next-service">
                                  <div>
                                    <img alt="" src={imgPlane} className="img-next-service" />
                                  </div>
                                  <span className="detail-next-service">
                                    {dataItem.airport.length
                                      ? dataItem.airport.map(item => (
                                          <>
                                            {item}
                                            <br />
                                          </>
                                        ))
                                      : ''}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}

                <div className="modal-body">
                  <div className="modal-close" onClick={this.onCloseBanner}>
                    <span aria-hidden="true">&times;</span>
                  </div>
                  <span className="bg-circle-white bg-circle-fix"></span>
                  <div className="row banner-content">
                    <div className="col-12 col-md-6">
                      <div className="div-img-car-o img-fix">
                        <img alt="" src={imgCar} className="banner-img-car" />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 next-content">
                      <div className="title-top" style={{ paddingBottom: '10px' }}>
                        เดินทางสะดวกกับ Eco Sedan
                      </div>
                      <div className="title-top">
                        The <img alt="" src={nextWord} className="next-word" /> Check
                      </div>
                      <div className="next-detail-2">
                        <div className="row justify-content-center mx-auto next-check-list">
                          <div className="col-6 col-next-check">
                            <div>
                              <img alt="" src={imgDriver} className="img-next-check" />
                            </div>
                            <span className="detail-next-check">
                              Criminal <br />
                              history check
                            </span>
                          </div>
                          <div className="col-6 col-next-check">
                            <div>
                              <img alt="" src={imgCar2} className="img-next-check" />
                            </div>
                            <span className="detail-next-check">
                              Car condition not <br />
                              over 10 years.
                            </span>
                          </div>
                          <div className="col-6 col-next-check">
                            <div>
                              <img alt="" src={imgCarInsurance} className="img-next-check" />
                            </div>
                            <span className="detail-next-check">Travel Insurance</span>
                          </div>
                          <div className="col-6 col-next-check">
                            <div>
                              <img alt="" src={imgSecurity} className="img-next-check" />
                            </div>
                            <span className="detail-next-check">
                              Guarantee booking <br />
                              before 6 hr.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-body">
                  <div className="modal-close" onClick={this.onCloseBanner}>
                    <span aria-hidden="true">&times;</span>
                  </div>
                  <div className="banner-content">
                    <div className="col-12 col-md-12 next-content">
                      <div className="title-top" style={{ paddingBottom: '10px' }}>
                        เดินทางสะดวกกับ Eco Sedan
                      </div>
                      <div className="title-top">How to booking</div>
                      <div className="title-sub">with NEXT TRANSFER</div>
                    </div>
                    <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 next-content">
                      <div className="row next-how-to">
                        <div className="col-2 text-center p-0 number">1</div>
                        <div className="col-8 text-left">Purchase member </div>
                        <div className="col-2 text-right pr-0">
                          {' '}
                          <img alt="" src={imgTicket} className="img-next-how-to" />{' '}
                        </div>
                      </div>
                      <div className="row next-how-to">
                        <div className="col-2 text-center p-0 number">2</div>
                        <div className="col-8 text-left">Booking </div>
                        <div className="col-2 text-right pr-0">
                          {' '}
                          <img alt="" src={imgForm} className="img-next-how-to" />{' '}
                        </div>
                      </div>
                      <div className="row next-how-to">
                        <div className="col-2 text-center p-0 number">3</div>
                        <div className="col-8 text-left text-middle">
                          Wait your <img alt="" src={nextWord} className="next-word" /> ride!{' '}
                        </div>
                        <div className="col-2 text-right pr-0">
                          {' '}
                          <img alt="" src={imgCarO} className="img-next-how-to" />{' '}
                        </div>
                      </div>
                      <div className="row next-how-to">
                        <div className="col-2 p-0"></div>
                        <div className="col-8 text-left text-middle p-0">
                          <button class="btn btn-next-clear w-100" onClick={this.onCloseBanner}>
                            START WITH NEXT
                          </button>
                        </div>
                        <div className="col-2 text-right pr-0"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ coupon, popup, banner }) => {
  return { coupon, popup, banner }
}

export default connect(mapStateToProps, {
  popupHidden,
  getBanner,
  closeBannerPopup,
})(BannerModal)
