import { UPLOAD_IMAGE, UPLOAD_IMAGE_CLEAR, UPLOAD_IMAGE_SUCCESS, UPLOAD_IMAGE_MONDAY } from './types'

export const uploadImage = (payload, id) => {
  return {
    type: UPLOAD_IMAGE,
    payload: { payload, id },
  }
}

export const uploadImageClear = () => {
  return {
    type: UPLOAD_IMAGE_CLEAR,
  }
}

export const uploadImageSuccess = payload => {
  return {
    type: UPLOAD_IMAGE_SUCCESS,
    payload: payload,
  }
}

export const uploadImageMonday = payload => {
  return {
    type: UPLOAD_IMAGE_MONDAY,
    payload: payload,
  }
}
