import { APP_STAGE_LOADING, APP_STAGE_DISPLAY_COUPON, APP_STAGE_NOT_FOUND } from '../actions/types'

const INIT_STATE = {
  display_stage: 'loading',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APP_STAGE_LOADING: {
      return {
        ...state,
        display_stage: 'loading',
      }
    }
    case APP_STAGE_DISPLAY_COUPON: {
      return {
        ...state,
        display_stage: 'display_coupon',
      }
    }
    case APP_STAGE_NOT_FOUND: {
      return {
        ...state,
        display_stage: 'not_found',
      }
    }
    default:
      return state
  }
}
