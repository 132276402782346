import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import '../../assets/css/app.css'
import '../../assets/css/booking-page.css'
import '../../assets/css/buy-page.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Translation } from 'react-i18next'
import {
  submitBookingMonday,
  uploadImage,
  uploadImageClear,
  uploadImageMonday,
  getTransactionDetail,
} from '../../actions'

class VerifyPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketNo: '',
      verify: false,
    }
  }

  render() {
    const { member } = this.props
    if (member && member.transaction && member.transaction.id) {
      return <Redirect to={{ pathname: 'payment/' + member.transaction.id }}></Redirect>
    }
    return (
      <div>
        <div className="bg-payment"></div>
        <div className="container buy-page">
          <div className="bg-circle"></div>
          <div className="title-page position-relative">
            <Translation>{(t, { i18n }) => t('VerifyPayment')}</Translation>
            <div className="row mx-auto buy-content mh-100">
              <div className="col-12 col-md-5 p-0 pt-4">
                <div className="reference-bar" style={{ borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }}>
                  <Translation>{(t, { i18n }) => t('ReferenceCode')}</Translation>
                </div>
                <div className="reference-bar d-md-none" style={{ borderRadius: '0px', height: '10px !important' }}>
                  &nbsp;
                </div>
                <div className="reference-bar" style={{ borderRadius: '0px' }}>
                  <div className="row w-100">
                    <div className="col-12 col-md-7">
                      <input
                        type="text"
                        ref="refcode"
                        name="refcode"
                        style={{ marginTop: '20px' }}
                        className="form-control"
                        onChange={e => {
                          this.setState({ ticketNo: e.target.value })
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-5">
                      <button
                        className="btn-next w-100"
                        style={{ height: '33.5px', marginTop: '20px' }}
                        onClick={() => {
                          this.props.getTransactionDetail(this.state.ticketNo)
                        }}
                      >
                        Check
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="reference-bar"
                  style={{
                    borderRadius: '0px',
                    height: '50px !important;',
                    borderBottomLeftRadius: '30px',
                    borderBottomRightRadius: '30px',
                    paddingBottom: '10px',
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup, banner, imageUpload, member }) => {
  return { coupon, popup, banner, imageUpload, member }
}

export default connect(mapStateToProps, {
  submitBookingMonday,
  uploadImage,
  uploadImageClear,
  uploadImageMonday,
  getTransactionDetail,
})(VerifyPayment)
