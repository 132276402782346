import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'reactstrap'
import '../../assets/css/app.css'
import '../../assets/css/booking-page.css'
import '../../assets/css/buy-page.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  submitBookingMonday,
  uploadImage,
  uploadImageClear,
  uploadImageMonday,
  getTransactionDetail,
} from '../../actions'
import { Translation } from 'react-i18next'
import blank_slip from '../../assets/imgs/blank_slip.png'
import 'react-datepicker/dist/react-datepicker.css'
import axios from 'axios'

import ReactHtmlParser from 'react-html-parser'

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      gbpReferenceNo: props.match.params.gbpReferenceNo,
      html: '',
    }
  }

  componentDidMount() {
    const search = this.props.location.search // could be '?foo=bar'
    const params = new URLSearchParams(search)
    const gbpReferenceNo = params.get('gbpReferenceNo') // bar
    // console.log(this.props, this.state)

    var publicKey = 'eHAMBEn6Rpl1kEb0G5SfkpZxJja6EyWs'
    // var publicKey = "xBEroUA1xal0Ab1RbYgWQ8P9NAgaaeUJ"  //UAT

    var link_api = 'https://api.gbprimepay.com/v1/'
    // var link_api = "https://api.globalprimepay.com/v1/" //UAT

    let d_secured = axios
      .post(link_api + 'tokens/3d_secured?publicKey=' + publicKey + '&gbpReferenceNo=' + gbpReferenceNo, {})
      .then(res => {
        let responseData = res.data
        // console.log(responseData)
        var sc = document.createElement('script')
        sc.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js')
        sc.setAttribute('type', 'text/javascript')
        document.head.appendChild(sc)

        document.writeln(responseData)
        document.getElementsByTagName('form')[0].submit()
      })
  }

  formStyle = {
    borderRadius: '20px !important!important',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }

  render() {
    return (
      <div>
        <div className="bg-payment"></div>
        <div className="container buy-page">
          <div className="bg-circle"></div>
          <div className="title-page position-relative">
            <div className="row mx-auto buy-content mh-100">
              <div className="col-12 col-md-5 p-0 position-relative justify-content" style={{ color: 'black' }}>
                <div className="buy-succes text-center">
                  <div className="thankyou-text">รายการถูกยกเลิก</div>
                  <div className="thankyou-text">
                    ขออภัยในความไม่สะดวก รหัสอ้างอิงดังกล่าว ถูกยกเลิกเนื่องจากพบปัญหาบางประการ
                  </div>
                </div>
                <div className="buy-form text-center">{ReactHtmlParser(this.state.html)}</div>
              </div>

              {/* <div className="col-12 col-md-6 div-image-car d-none d-md-block ">
              <img alt="" src={imgCar} className="w-100"/>
          </div> */}
            </div>
          </div>
          <div className="home-clear-space"></div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup, banner, imageUpload, member }) => {
  return { coupon, popup, banner, imageUpload, member }
}

export default connect(mapStateToProps, {
  submitBookingMonday,
  uploadImage,
  uploadImageClear,
  uploadImageMonday,
  getTransactionDetail,
})(Payment)
