import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  CODE_GET_DATA,
  CODE_POST_MONDAY,
  CODE_GET_VALID,
  CODE_REQUEST_DISCOUNT_CODE,
  CODE_REQUEST_DISCOUNT_CODE_FIRST,
} from '../actions/types'
import {
  setTicketData,
  SetCodeValid,
  popupError,
  popupHidden,
  returnDiscountCode,
  returnDiscountCodeError,
  returnDiscountCodeFirst,
} from '../actions'
import axios from '../util/APIUtils'
import { destructure, parseJSON, destructureArr } from '../util/mondayColumnsUtils'
import moment from 'moment'

var mondaySchema = {
  date4: {
    date: '',
  },
  phone4: '',
  email4: '',
  ______________: '',
  text27: '',
  status29: {
    index: 2,
  },
  status: {
    index: 5,
  },
  long_text5: { text: '' },
  long_text0: { text: '' },
  long_text54: { text: '' },
}

const specialCondition = payload => {
  let temp = payload
  switch (payload[0].text8.replace(/"/g, '')) {
    case 'KSWG':
      temp[0].condition = { cond: 'cost:>=:5000', value: 100 }
      break
    default:
      break
  }
  return temp
}

const postToMondayRequest = async payload => {
  const statusJob = () => {
    if (payload.data.pickupType === '1' && payload.data.dropoffType === '1') return 15
    else if (payload.data.dropoffType === '2' && payload.data.drop_off_airport === '2') return 1
    else if (payload.data.dropoffType === '2' && payload.data.drop_off_airport === '1') return 2
    else if (payload.data.pickupType === '2' && payload.data.pick_up_airport === '1') return 13
    else if (payload.data.pickupType === '2' && payload.data.pick_up_airport === '2') return 1
    return 5
  }

  try {
    //  
    mondaySchema.date4.date = moment(payload.data.Datetime).format('YYYY-MM-DD')
    mondaySchema.phone4 = payload.data.phone ? payload.data.phone : ''
    mondaySchema.pickup_time = payload.data.bookingTime ? payload.data.bookingTime : ''
    mondaySchema.email4 = payload.data.email ? payload.data.email : ''

    mondaySchema.______________ = payload.data.person + '/' + payload.data.luggage
    mondaySchema.text27 = payload.data.pick_upFlight || payload.data.drop_offFlight
    mondaySchema.status29.index = statusJob()

    mondaySchema.long_text54.text = payload.data.note ? payload.data.note : ''
    mondaySchema.text0 = ''

    // if (payload.data.coupon.validationCodeFromServer.coupon_status === "active"){
    //   mondaySchema.long_text54.text += "รหัสนี้ได้ใช้งานไปแล้วในรายการการจองที่ " + payload.data.coupon.validationCodeFromServer.booking_id
    //   mondaySchema.text0 +=  "(รหัสนี้ได้ใช้งานไปแล้ว ตรวจสอบและติดต่อกลับหาลูกค้า) "
    // }

    mondaySchema.text0 += payload.code

    mondaySchema.long_text0.text =
      payload.data.dropoffType === '1'
        ? payload.data.drop_offAddress + payload.data.drop_off_interest
        : payload.data.drop_off_airport === '1'
        ? 'SUVANABHUMI Airport'
        : 'DON MUANG Airport'

    mondaySchema.long_text5.text =
      payload.data.pickupType === '1'
        ? payload.data.pick_upAddress + payload.data.pick_up_interest
        : payload.data.pick_up_airport === '1'
        ? 'SUVANABHUMI Airport'
        : 'DON MUANG Airport'

    //routeSubZone -- pickup -- dropoff
    mondaySchema.text2 = payload.data.selectedRoute

    let carType = () => {
      let car = payload.data.carType
      if (car === 'ECO') {
        return 1
      } else if (car === 'SUV') {
        return 2
      } else if (car === 'VAN') {
        return 3
      }
    }
    mondaySchema.dropdown9 = { ids: [] }
    mondaySchema.dropdown9.ids.push(carType()) ////car
  } catch (error) {
    console.log(error)
  }
  var ColumnValue = JSON.stringify(mondaySchema)

  const body = {
    query: `mutation ($board: Int!, $name: String!,  $group: String!, $column: JSON!) { 
		create_item( board_id: $board, item_name: $name,group_id :$group , column_values:$column ,create_labels_if_missing: true) { 
		  id 
		  column_values { 
			id 
			value 
		  } 
		} 
	  } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_BOOKING),
      name: payload.data.firstname + ' ' + payload.data.lastname,
      group: 'new_group42939',
      column: ColumnValue,
    },
  }

  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const getCodeValidRequest = async payload => {
  const body = {
    query: `query($board: Int!, $column_id: String!,  $column_value: String!) {
      items_by_column_values (board_id:  $board, column_id:  $column_id, column_value:  $column_value) {
        board { id }
        id
        name
        column_values { 
          id title value additional_info
        }
      }
      } `,
    variables: {
      board: parseInt(payload.board_id ? payload.board_id : process.env.REACT_APP_MONDAY_FD_Codes),
      column_id: 'text8',
      column_value: payload.Code,
    },
  }

  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const postUpdatevalue = async payload => {
  //change to monday
  // console.log('payload', payload)

  if (payload.data.couponForever) {
    return true
  } else {
    const body = {
      query: `mutation($board: Int!, $column_id: String!, $item_id: Int!,  $value: JSON!) {
        change_column_value (board_id:  $board, column_id:  $column_id,item_id:  $item_id, value:  $value) {
        id
        }
        } `,
      variables: {
        board: parseInt(process.env.REACT_APP_MONDAY_FD_Codes),
        item_id: Number(payload.itemMondayId),
        column_id: 'status',
        value: '{"index": 2}',
      },
    }

    try {
      await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
    } catch (error) {}
    return await axios
      .post('', body)
      .catch(err => {
        console.error(err.data)
      })
      .then(res => {
        return res.data
      })
  }
}

const mondayNextCodeRequest = async ({ payload, board_id }) => {
  // 
  const mondayNEXTDiscountCodeSettingQuery = `{
    items_by_column_values (board_id:  ${
      board_id ? board_id : process.env.REACT_APP_MONDAY_FD_Codes
    }, column_id: "text8", column_value: "${payload.trim()}") {
        board { id }
        id
        name
        column_values { 
          id title value
        }
      }
    }`
  const body = {
    query: mondayNEXTDiscountCodeSettingQuery,
  }

  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const currentTimeRequest = async ({ payload }) => {
  return await axios
    .get('https://public-api.lifestyle-privilege.com/ping')
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const destucureAndCheck = async payload => {
  let data = []
  try {
    for (var i = 0; i < payload.length; i++) {
      let _item = destructure(payload[i])

      if (JSON.parse(_item.status).index === 1) {
        data = [_item]
        break
      }
    }
  } catch (error) {}
  return data
}

function* postToMonday({ payload }) {
  try {
    const valid = yield call(getCodeValidRequest, payload)
    debugger
    if (valid) {
      const data = yield call(postToMondayRequest, payload)
      debugger
      if (data) {
        let updateData = false
        if (payload.itemMondayId === '-') {
          debugger
          updateData = true
        } else {
          debugger
          updateData = yield call(postUpdatevalue, payload)
        }

        if (updateData) {
          debugger 
          yield put(popupHidden())
          yield put(setTicketData(data.data.create_item.id))
        } else {
          debugger
          throw new Error(data.err_code)
        }
      } else {
        throw new Error(data.err_code)
      }
    } else {
      throw new Error(valid.err_code)
    }
  } catch (error) {
    yield put(popupError('การส่งข้อมูลไม่สำเร็จ'))
  }
}

function* codeValidation({ payload }) {
  try {
    let data = yield call(getCodeValidRequest, payload)
    const time = yield call(currentTimeRequest, payload)

    // if(data.data.items_by_column_values.length === 0){
    //   let newpayload =  payload
    //   newpayload.board_id = "1573268217"
    //   // if(payload.Code.startsWith("A")){
    //   //   newpayload.board_id = "1573268217"
    //   // }
    //   // else if (payload.Code.startsWith("B")){
    //   //   newpayload.board_id = "1573268217"
    //   // }
    //   // else{
    //   //   newpayload.board_id = "1573268217"
    //   // }

    //   data = yield call(getCodeValidRequest, newpayload)

    // }

    // 2.loop check

    if (payload && payload.Code && payload.Code.startsWith('AIAW')) {
      let newpayload = payload
      newpayload.board_id = '2045963046'
      data = yield call(getCodeValidRequest, newpayload)
    } else if (data.data.items_by_column_values.length === 0) {
      let board_ids = ['1573268217', '1585948540']
      let newpayload = payload
      for (let index = 0; index < board_ids.length; index++) {
        if (data.data.items_by_column_values.length === 0) {
          newpayload.board_id = board_ids[index]
          data = yield call(getCodeValidRequest, newpayload)
        }
      }
    }
    if (data) {
      const newData = yield call(destructureArr, data.data.items_by_column_values)
      // console.log(newData)
      var flagAllbeUsed = false
      var refcodeItem = []
      let flagCouponDiscount = false

      let popupErrorMsg = ''
      newData.map(codeItem => {
        codeItem.currentTime = time.date
        // check  ครับ
        let status = parseJSON(codeItem.status).index
        //let DiscountType = parseJSON(codeItem.status0).index
        //let DiscountFor = parseJSON(codeItem.status01).index
        let Type = parseJSON(codeItem.status2).index

        let startRedeemDate = undefined
        let endRedeemDate = undefined
        let startRedeemTime = '00:00:00'
        let endRedeemTime = '23:59:59'

        try {
          startRedeemDate = JSON.parse(codeItem.date) === null ? undefined : JSON.parse(codeItem.date).date
        } catch (error) {}
        try {
          endRedeemDate = JSON.parse(codeItem.date8) === null ? undefined : JSON.parse(codeItem.date8).date
        } catch (error) {}
        try {
          startRedeemTime = JSON.parse(codeItem.date).time === null ? '00:00:00' : JSON.parse(codeItem.date).time
        } catch (error) {}
        try {
          endRedeemTime = JSON.parse(codeItem.date8).time === null ? '23:59:59' : JSON.parse(codeItem.date8).time
        } catch (error) {}

        let textStartTime = '00:00:00'
        let textEndTime = '23:59:59'

        // get date time of startRedeemDate  ,  endRedeemDate
        if (codeItem.date !== null && JSON.parse(codeItem.date).time !== null) {
          let tmpStartDate = new Date(startRedeemDate + ' ' + startRedeemTime)
          if (startRedeemTime) {
            textStartTime =
              tmpStartDate.getHours() + 7 + ':' + startRedeemTime.split(':')[1] + ':' + startRedeemTime.split(':')[2]
          }
        }
        if (codeItem.date8 !== null && JSON.parse(codeItem.date8).time !== null) {
          let tmpEndDate = new Date(endRedeemDate + ' ' + endRedeemTime)
          if (endRedeemTime) {
            textEndTime =
              tmpEndDate.getHours() + 7 + ':' + endRedeemTime.split(':')[1] + ':' + endRedeemTime.split(':')[2]
          }
        }

        //START: condition to check time between start and stop
        if (startRedeemDate !== undefined && endRedeemDate !== undefined) {
          if (
            new Date(codeItem.currentTime).getTime() - new Date(startRedeemDate + ' ' + textStartTime).getTime() <
            0
          ) {
            popupErrorMsg =
              'รหัสยังไม่เปิดใช้งาน รหัสเปิดใช้งานวันที่  ' +
              startRedeemDate +
              ' ' +
              (JSON.parse(codeItem.date).time !== null ? textStartTime : '') +
              ' ถึง ' +
              endRedeemDate +
              ' ' +
              (JSON.parse(codeItem.date8).time !== null ? textEndTime : '') +
              '. '
          } else if (
            new Date(codeItem.currentTime).getTime() - new Date(endRedeemDate + ' ' + textEndTime).getTime() >
            0
          ) {
            popupErrorMsg = 'รหัสของท่านหมดอายุใช้งานแล้ว  กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม '
          } else if (
            new Date(codeItem.currentTime).getTime() - new Date(endRedeemDate + ' ' + textEndTime).getTime() >
            0
          ) {
          } else {
            // NEXT
          }
        } else if (startRedeemDate !== undefined) {
          if (
            new Date(codeItem.currentTime).getTime() - new Date(startRedeemDate + ' ' + textStartTime).getTime() >
            0
          ) {
            // NEXT
          } else {
            popupError = 'รหัสยังไม่เปิดใช้งาน รหัสเปิดใช้งานวันที่  ' + startRedeemDate
          }
        } else if (endRedeemDate !== undefined) {
          if (new Date(codeItem.currentTime).getTime() - new Date(endRedeemDate + ' ' + textEndTime).getTime() < 0) {
            // NEXT
          } else {
            popupErrorMsg = 'รหัสของท่านหมดอายุใช้งานแล้ว  กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม '
          }
        } else {
          // NEXT
        }

        //END: condition to check time between start and stop

        if (status === 1) {
          flagAllbeUsed = true
          refcodeItem.push(codeItem)
        }

        if (status === 1 && Type === 1) {
          //  flagAllbeUsed = true;
          ///  refcodeItem.push(codeItem)
        }

        if (Type === 0) {
          flagCouponDiscount = true
        }

        return codeItem
        
      })
      
      if (!flagAllbeUsed || popupErrorMsg) {
        if (popupErrorMsg) {
          
          yield put(popupError(popupErrorMsg))
        } else if (flagCouponDiscount) {
          
          yield put(
            popupError('รหัสดังกล่าวเป็น "รหัสส่วนลด" กรุณากรอกในขั้นตอนก่อนชำระเงิน (หลังจากเลือกเมนูรายการอาหาร)')
          )
        } else {
          
          yield put(popupError('รหัสมีปัญหา กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'))
        }
      } else {
        if (flagCouponDiscount) {
          let _newData = refcodeItem
          
          if (refcodeItem[0].check3 && JSON.parse(refcodeItem[0].check3) && JSON.parse(refcodeItem[0].check3).checked) {
            _newData = specialCondition(refcodeItem)
          }

          yield put(returnDiscountCode(_newData.length > 0 ? [_newData[0]] : {}))
        } else {
          
          yield put(SetCodeValid(refcodeItem.length > 0 ? refcodeItem[0] : {}))
        }
      }
    } else {
      throw new Error(data)
    }
  } catch (error) {
    yield put(popupError('ไม่พบรหัสดังกล่าว กรุณาติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม'))
    console.log(error)
    //SET ERROR
  }
}

function* codeDiscountValidation(payload) {
  try {
    let data = yield call(mondayNextCodeRequest, payload)
    const time = yield call(currentTimeRequest, payload)

    // if(data.data.items_by_column_values.length === 0){
    //   let newpayload =  payload
    //   newpayload.board_id = "1573268217"

    //   data = yield call(mondayNextCodeRequest, newpayload)

    // }

    // 2.loop check
    if (payload && payload.Code && payload.Code.startsWith('AIAW')) {
      let newpayload = payload
      newpayload.board_id = '2045963046'
      data = yield call(getCodeValidRequest, newpayload)
    } else if (data.data.items_by_column_values.length === 0) {
      let board_ids = ['1573268217', '1585948540']
      let newpayload = payload
      for (let index = 0; index < board_ids.length; index++) {
        if (data.data.items_by_column_values.length === 0) {
          newpayload.board_id = board_ids[index]
          data = yield call(mondayNextCodeRequest, newpayload)
        }
      }
    }

    let newData = yield call(destucureAndCheck, data.data.items_by_column_values)
    newData[0].currentTime = time.date

    if (newData.length > 0 && data.data.items_by_column_values.length > 0) {
      let _newData = newData
      if (newData[0].check3 && JSON.parse(newData[0].check3) && JSON.parse(newData[0].check3).checked) {
        _newData = specialCondition(newData)
      }

      yield put(returnDiscountCode(_newData))
    } else if (newData.length === 0 && data.data.items_by_column_values.length > 0) {
      yield put(returnDiscountCodeError('Sorry now this code are fully booked!,'))
    } else {
      // ไม่มี
      yield put(returnDiscountCode([]))
      yield put(returnDiscountCodeError('Error'))
    }
  } catch (error) {
    yield put(returnDiscountCodeError('Error'))
    console.log(error)
  }
}

function* codeDiscountFirstValidation(payload) {
  try {
    const data = yield call(mondayNextCodeRequest, { payload: 'FREEFIRST350AXGE' })
    // console.log(data)
    let newData = yield call(destucureAndCheck, data.data.items_by_column_values)
    // console.log(newData, data.data.items_by_column_values)

    if (newData.length > 0 && data.data.items_by_column_values.length > 0) {
      yield put(returnDiscountCodeFirst(newData))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getCodeCouponData(payload) {
  let code = payload.payload.code
  if (!code) return 0
  try {
    let data = yield call(mondayNextCodeRequest, { payload: code })
    if (payload && payload.Code && payload.Code.startsWith('AIAW')) {
      let newpayload = payload
      newpayload.board_id = '2045963046'
      data = yield call(getCodeValidRequest, newpayload)
    } else if (data.data.items_by_column_values.length === 0) {
      let board_ids = ['1573268217', '1585948540']
      let newpayload = {}
      newpayload.payload = code
      //  
      for (let index = 0; index < board_ids.length; index++) {
        if (data.data.items_by_column_values.length === 0) {
          newpayload.board_id = board_ids[index]
          data = yield call(mondayNextCodeRequest, newpayload)
        }
      }
    }

    let newData = yield call(destructureArr, data.data.items_by_column_values)
    // console.log(newData, data.data.items_by_column_values)

    if (newData.length > 0 && data.data.items_by_column_values.length > 0) {
      yield put(returnDiscountCode(newData))
    }
  } catch (error) {
    console.log(error)
  }
}

function* requestCodeWatcher() {
  yield takeEvery(CODE_POST_MONDAY, postToMonday)
}

function* validCodeWatcher() {
  yield takeEvery(CODE_GET_VALID, codeValidation)
}

function* validDiscountCodeWatcher() {
  yield takeEvery(CODE_REQUEST_DISCOUNT_CODE, codeDiscountValidation)
}

function* validDiscountCodeFirstWatcher() {
  yield takeEvery(CODE_REQUEST_DISCOUNT_CODE_FIRST, codeDiscountFirstValidation)
}
function* getCodeWatcher() {
  yield takeEvery(CODE_GET_DATA, getCodeCouponData)
}

export default function* rootSaga() {
  yield all([
    fork(requestCodeWatcher),
    fork(validCodeWatcher),
    fork(validDiscountCodeWatcher),
    fork(getCodeWatcher),
    fork(validDiscountCodeFirstWatcher),
  ])
}
