import React, { Component } from 'react'
import { connect } from 'react-redux'
import { payOmise, updateOmise } from '../../actions'

class PaymentButton extends Component {
  componentDidMount() {
    const { OmiseCard } = window
    OmiseCard.configure({
      publicKey: 'pkey_5ia1mnpfiwtt8rzdb60',
      image: 'https://cdn.omise.co/assets/dashboard/images/omise-logo.png',
      amount: parseInt(this.props.price * 100),
    })
  }

  onClickCheckout = event => {
    const { OmiseCard } = window
    event.preventDefault()
    this.props.updateOmise(this.props.ticketNo)
    let amount = parseInt(this.props.price.replace(/\,/g, '').replace(/\"/g, '')) * 100
    // console.log(this.props.price)
    OmiseCard.open({
      amount: amount,
      currency: 'THB',
      defaultPaymentMethod: 'credit_card',
      frameLabel: 'Silver Voyage Club',
      onCreateTokenSuccess: nonce => {
        if (nonce.startsWith('tokn_')) {
          this.setState({
            token: nonce,
            amount: amount,
            currency: 'THB',
            ticketNo: this.props.ticketNo,
            bookingNo: this.props.bookingNo.replace(/"/g, ''),
          })
          this.props.toggle()
          this.props.payOmise(this.state)
        } else {
          // console.log('form.omiseSource.value', nonce)
          document.querySelector("#checkoutForm input[name='omiseToken']").value = nonce
        }
      },
    })
  }

  render() {
    return (
      <div>
        <form id="checkoutForm" name="checkoutForm" method="POST" action="/charge">
          <input type="hidden" name="omiseToken" />
          <input type="hidden" name="omiseSource" />
          <button type="submit" id="checkout-button" className="btn btn-next w-100" onClick={this.onClickCheckout}>
            PAY VIA OMISE
          </button>
        </form>
      </div>
    )
  }
}

const mapStateToProps = ({ coupon, popup, banner, imageUpload }) => {
  return { coupon, popup, banner, imageUpload }
}

export default connect(mapStateToProps, {
  payOmise,
  updateOmise,
})(PaymentButton)
