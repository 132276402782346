import { UPLOAD_IMAGE_CLEAR, UPLOAD_IMAGE_SUCCESS } from '../actions/types'

const INIT_STATE = {
  uploadStatus: false,
  uploadPath: '',
}

export default (state = INIT_STATE, action = '') => {
  switch (action.type) {
    case UPLOAD_IMAGE_CLEAR: {
      return {
        uploadStatus: false,
        uploadPath: '',
      }
    }
    case UPLOAD_IMAGE_SUCCESS: {
      return {
        uploadStatus: true,
        uploadPath: action.payload,
      }
    }
    default:
      return state
  }
}
