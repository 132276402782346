import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import TermModalFooter from '../../components/Modal/termModalFooter'

class MobileMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  handleMenuClick() {
    this.setState({ menuOpen: !this.state.menuOpen })
  }
  render() {
    return (
      <div>
        <div id="mobile-menu-open" onClick={() => this.handleMenuClick()} class="d-inline-block text-white">
          <FontAwesomeIcon icon={faBars} />
        </div>
        <div id="mobile-menu-content" style={{ display: this.state.menuOpen ? 'initial' : 'none' }}>
          <ul className="nav-main text-left">
            <li>
              <div className="px-4 pb-4 icon-close" id="mobile-menu-close" onClick={() => this.handleMenuClick()}>
                <FontAwesomeIcon icon={faTimes} className="icon-menu" />{' '}
              </div>
            </li>
            <li>
              <Link to="/">
                <span className="px-4 pt-4" onClick={() => this.handleMenuClick()} style={{ cursor: 'pointer' }}>
                  Home
                </span>
              </Link>
            </li>
            <li>
              <Link to="/verify-payment" className="px-4 pt-4" onClick={() => this.handleMenuClick()}>
                <span>Verlify Payment</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="px-4 pt-4">
                <TermModalFooter />
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className="px-4 pt-4" onClick={() => this.handleMenuClick()}>
                <span>Privacy Policy</span>
              </Link>
            </li>
            <li className="px-4 pt-4">
              <span className="pull-right">
                <Translation>
                  {(t, { i18n }) => (
                    <span
                      className={`lang ${i18n['language'] === 'en' ? 'active' : ''}`}
                      onClick={() => i18n.changeLanguage('en')}
                    >
                      EN
                    </span>
                  )}
                </Translation>
                /
                <Translation>
                  {(t, { i18n }) => (
                    <span
                      className={`lang ${i18n['language'] === 'th' ? 'active' : ''}`}
                      onClick={() => i18n.changeLanguage('th')}
                    >
                      TH
                    </span>
                  )}
                </Translation>
              </span>
            </li>
          </ul>
          <br />
          <hr />
          <br />
          <div className="mx-4 text-main">
            Email : services@worldrewardsolutions.com
            <br />
            Line official account : @silvervoyage <br />
            Phone : 02-016-9975 <br />
            Time : 9.00-20.00 hrs <br />
          </div>
        </div>
      </div>
    )
  }
}

export default MobileMenu
