import React, { Component } from 'react'

import { Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import packageJson from '../../../package.json'
class HelpModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalTermStatus: false,
    }
  }
  onClickHelp = event => {
    event.preventDefault()
    this.setState({
      modalHelpStatus: true,
    })
    document.body.classList.add('modal-open')
  }
  onCloseHelp = event => {
    event.preventDefault()
    this.setState({
      modalHelpStatus: false,
    })
    document.body.classList.remove('modal-open')
  }
  render() {
    return (
      <div>
        <a href="#consent_form">
          <span onClick={this.onClickHelp}>
            {' '}
            <Translation>{(t, { i18n }) => t('Help')}</Translation>
          </span>
        </a>
        <div
          className={`modal ${this.state.modalHelpStatus === true && 'd-block'} `}
          id="consent_form"
          tabIndex="999999"
          role="dialog"
          aria-labelledby="consent_form_title"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="detail-1">
                <div className="modal-header">
                  <div className="modal-close" onClick={this.onCloseHelp}>
                    <span aria-hidden="true">&times;</span>
                  </div>

                  <span className="modal-title" id="">
                    <Translation>{(t, { i18n }) => t('Help')}</Translation>
                  </span>
                </div>

                <div className="modal-body">
                  <p>
                    Email : services@worldrewardsolutions.com
                    <br />
                    Line official account : @silvervoyage
                    <br />
                    Phone : 02-016-9975
                    <br />
                    Time : 9.00-20.00 hrs
                    <br />
                    <br />
                    <br />
                    <div
                      onClick={() => {
                        let now = new Date()
                        localStorage.clear()
                        //window.Cache.delete()
                        window.location.href = '/?time=' + (now + 0)
                      }}
                    >
                      version : {packageJson.version}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HelpModal
