import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import {
  MEMBER_POST_DETAIL,
  MEMBER_POST_DETAIL_BOOKING,
  TRANSACTION_DETAIL_FETCH,
  OMISE_PAY_UPDATE_STATUS,
} from '../actions/types'
import {
  setCustomerTransaction,
  setTransactionDetail,
  popupError,
  setCustomerTransactionAndBooking,
  popupHidden,
} from '../actions'
import axios from '../util/APIUtils'
import moment from 'moment'

import { destructure } from '../util/mondayColumnsUtils'

// text = เบอร์โทรศัพท์
// email9 = Email
// dropdown0 = label-> restaurant name
// status = Dining Credit
// date4 = date = วันที่จัดส่ง
// status53 = status = เวลาจัดส่งให้ลูกค้า  = 11-13 : 1 / 17-18 : 4
// long_text = สถานที่จัดส่ง
// numbers9 = ค่าส่ง
// long_text3 = รายการอาหาร
// ________ = ค่าอาหาร
// formula = รวมยอด
// status0 = ชำระเงิน = index 12 = รอชำระเงิน
// status40 = Order ให้โรงแรม = index 5 = ว่างเปล่า
// status7 = Status = index 4 = รอตรวจสอบ
// people =  personsAndTeams = [{id:7184378}]
//  status_1  0\":\"เว็บไซต์
// status_14  :{\"0\":\"บัตรเครดิต\",\"1\":\"โอนเข้าบัญชี\
// _____________  \"4\":\"ส่งสินค้า/ส่งอาหาร\",
// input_team  : \"10\":\"ROBOT\"
// input_date = date6

function boundedDiscount(val) {
  if (val < 0) return 0
  else if (val > 350) return 350
  else return val
}

var memberMondatSchema = {
  status9: {
    // Color
    index: 0,
  },
}

var mondaySchema = {
  text: 'เบอร์โทร',
  email9: 'itsmyemail@mailserver.com',
  dropdown0: { ids: [] },
  status: { index: 5 },
  date4: { date: moment().format('YYYY-MM-DD') },
  status53: { index: 5 },
  long_text: { text: '' },
  numbers9: 0,
  long_text3: { text: '' },
  ________: 0,
  status0: { index: 12 },
  status40: { index: 5 },
  status7: { index: 4 },
  //people : {"personsAndTeams":[{"id":7184378,"kind":"person"}]} ,
  status_1: { index: 0 },
  status_14: { index: 0 },
  _____________: { index: 4 },
  input_team: { index: 10 },
  input_date: {
    date: moment().format('YYYY-MM-DD'),
  },
}

//
const DiningCredit = bath => {
  if (bath === '"1000"') {
    return 1
  }
  if (bath === '"2000"') {
    return 2
  }
  if (bath === '"5000"') {
    return 0
  }
}

const dropdownRestaurant = menu_id => {
  let id = parseInt(menu_id)
  //  62 = jW = 516081255 , 516081936
  //  63 = Supanniga Eating Room = 516089067 , 516089343 , 516089381
  //  64  = Wine Connection = 516093406 , 516093455 , 516093470 , 516094342
  //  58 = Somboon  = 516095663 , 516095739 , 516095773
  //  60 = Coffee Beans by Dao = 516097532 , 516097563 , 516097581
  //  65 = Baan Dusit Thani  = 517476742 , 517479633 , 517481571 , 517482891
  //  66 = WATER LIBRARY  = 517730957 , 517732355 , 517733212
  //  67 = PLU  = 517739059 , 517739557 , 517739941
  //  68 = AT-TA-ROTE  = 523023170 , 523024026 , 523024140
  //  70 = HONG BAO  = 517734601 , 517734956 , 517737247
  //  71 = Nara Thai Cuisine  = 525324225 , 525324226 , 525324227
  //   55 = SIAM KEMPINSKI  = 527412676 , 527415084
  //   72 = Banyan Tree  = 527415225 , 527415797
  //   73 = Jumbo Seafood  = 527417237 , 527416388 , 527416389 , 527416390
  //  80 = Sushi Mori  = 543065281 , 543065282 , 543065283
  //  81 = Kumpoon  = 549371572 , 549371574 , 549371576
  //  84 = Chatrium  = 554151744, 554152320, 554152708

  if (id === 516081255 || id === 516081936) {
    return 62
  } else if (id === 516089067 || id === 516089343 || id === 516089381) {
    return 63
  } else if (id === 516093406 || id === 516093455 || id === 516093470 || id === 516094342) {
    return 64
  } else if (id === 516095663 || id === 516095739 || id === 516095773) {
    return 58
  } else if (id === 516097532 || id === 516097563 || id === 516097581) {
    return 60
  } else if (id === 517476742 || id === 517479633 || id === 517481571 || id === 517482891) {
    return 65
  } else if (id === 517730957 || id === 517732355 || id === 517733212) {
    return 66
  } else if (id === 517739059 || id === 517739557 || id === 517739941) {
    return 67
  } else if (id === 523023170 || id === 523024026 || id === 523024140) {
    return 68
  } else if (id === 517734601 || id === 517734956 || id === 517737247) {
    return 70
  } else if (id === 525324225 || id === 525324226 || id === 525324227) {
    return 71
  } else if (id === 527412676 || id === 527415084) {
    return 55
  } else if (id === 527415225 || id === 527415797) {
    return 72
  } else if (id === 527417237 || id === 527416388 || id === 527416389 || id === 527416390) {
    return 73
  } else if (id === 543065281 || id === 543065282 || id === 543065283) {
    return 80
  } else if (id === 549371572 || id === 549371574 || id === 549371576) {
    return 81
  } else if (id === 554151744 || id === 554152320 || id === 554152708) {
    return 84
  } else {
    return
  }
}

const RestaurantDetail = menu_id => {
  let id = parseInt(menu_id)
  //  62 = jW = 516081255 , 516081936
  //  63 = Supanniga Eating Room = 516089067 , 516089343 , 516089381
  //  64  = Wine Connection = 516093406 , 516093455 , 516093470 , 516094342
  //  58 = Somboon  = 516095663 , 516095739 , 516095773
  //  60 = Coffee Beans by Dao = 516097532 , 516097563 , 516097581
  //  65 = Baan Dusit Thani  = 517476742 , 517479633 , 517481571 , 517482891
  //  66 = WATER LIBRARY  = 517730957 , 517732355 , 517733212
  //  67 = PLU  = 517739059 , 517739557 , 517739941
  //  68 = AT-TA-ROTE  = 523023170 , 523024026 , 523024140
  //  70 = HONG BAO  = 517734601 , 517734956 , 517737247
  //  71 = Nara Thai Cuisine  = 525324225 , 525324226 , 525324227
  //   55 = SIAM KEMPINSKI  = 527412676 , 527415084
  //   72 = Banyan Tree  = 527415225 , 527415797
  //   73 = Jumbo Seafood  = 527417237 , 527416388 , 527416389 , 527416390
  //  80 = Sushi Mori  = 543065281 , 543065282 , 543065283
  //  81 = Kumpoon  = 549371572 , 549371574 , 549371576
  //  84 = Chatrium  = 554151744, 554152320, 554152708

  if (id === 516081255 || id === 516081936) {
    return `JW Marriott 
    K. มาลัย (BBCO) 02-6567700 
    จอดรถหน้าอาคารใกล้กับ spirit house`
  } else if (id === 516089067 || id === 516089343 || id === 516089381) {
    return `Supanniga Eating Room สาขาทองหล่อ 
          คุณเจษฎา 026147508`
  } else if (id === 516093406 || id === 516093455 || id === 516093470 || id === 516094342) {
    return `WINE CONNECTIONS สาขา Rain Hill 
  K.หนิง 022617217`
  } else if (id === 516095663 || id === 516095739 || id === 516095773) {
    return `ร้านอาหารสมบูรณ์ โภชนา สาขาบรรทัดทอง 
  คุณหญิง 081-8218441(เบอร์ร้านสาขา 022164203-5, 0970464203)`
  } else if (id === 516097532 || id === 516097563 || id === 516097581) {
    return `COFFEE BEANS by Dao สาขา Ruamrudee   
  ให้คนขับแจ้งว่ามารับอาหารของ World Reward (เวิลด์ รีวอด)`
  } else if (id === 517476742 || id === 517479633 || id === 517481571 || id === 517482891) {
    return ' Baan Dusit Thani '
  } else if (id === 517730957 || id === 517732355 || id === 517733212) {
    return 'Water Library(ชั้น 5 Central Embassy) 094 703 7777'
  } else if (id === 517739059 || id === 517739557 || id === 517739941) {
    return 'Plu(พลู) เลขที่ 3 ซอยพระพินิจ เบอร์: 062 642 2222 , 084 638 8888'
  } else if (id === 523023170 || id === 523024026 || id === 523024140) {
    return 'อรรถรส 59/3 สุขุมวิท 39 เบอร์: 0642494244 02-262-0178'
  } else if (id === 517734601 || id === 517734956 || id === 517737247) {
    return 'Hong Bao ชั้น G หน้าร้าน gucci 0926672992 , 021605679'
  } else if (id === 525324225 || id === 525324226 || id === 525324227) {
    return 'นารา เอราวัณ ชั้น LG รร Erawan Grand Hyatt เบอร์: 092-248-1482 / 02-250-7707'
  } else if (id === 527417237 || id === 527416388 || id === 527416389 || id === 527416390) {
    return 'Jumbo Seafood: Icon siam ชั้น G จะมีจุดเดียว บริเวณใกล้ศาลพระภูมิ 0890069175'
  } else if (id === 543065281 || id === 543065282 || id === 543065283) {
    return 'Sushi Mori: 98 ถนนสาทรเหนือ ตึกสาทรสแควร์ สีลม บางรัก จอดรถได้หน้าตึก 15 นาที เข้าไปในตึกจะเห็นร้านเลย สาขาสาทร 092-424-4040'
  } else if (id === 549371572 || id === 549371574 || id === 549371576) {
    return 'Kumpoon: คำพูน สาขา Central บางนา ชั้น 1 0988299477'
  } else if (id === 554151744 || id === 554152320 || id === 554152708) {
    return 'Chatrium Hotel:  28 ซอยเจริญกรุง 70 แขวงบางคอแหลม  Riverbarge Restaurant  02-307-8888 088-0223658'
  } else {
    return
  }
}
const postToMondayRequest = async payload => {
  // console.log(payload.data.selectedMenu, payload.data.restaurant)

  mondaySchema.text = payload.data.phone
  mondaySchema.email9 = payload.data.email

  mondaySchema.dropdown0 = {
    labels: [payload.data.selectedMenu.text ? payload.data.selectedMenu.text.replace(/"/g, '') : ''],
  }
  mondaySchema.status = { index: payload.data.selectedMenu.text4 ? DiningCredit(payload.data.selectedMenu.text4) : 5 }
  mondaySchema.date4 = { date: moment(payload.data.Datetime).format('YYYY-MM-DD') }
  mondaySchema.status53 = { index: parseInt(payload.data.bookingTime) }
  mondaySchema.long_text = { text: payload.data.drop_offAddress + payload.data.drop_off_interest }
  // location    mondaySchema.location = {lat : "0" , lng: "0"}
  mondaySchema.numbers9 = payload.data.deliveryFee > 0 ? payload.data.deliveryFee : 0

  mondaySchema.______ =
    payload.data.couponValids && payload.data.totalAmount > 0
      ? payload.data.totalAmount
      : payload.data.discountDelivery > 0
      ? payload.data.discountDelivery
      : 0 // ส่วนลดค่าส่ง
  mondaySchema.numbers =
    payload.data.couponValids && payload.data.totalAmount > 0
      ? payload.data.totalAmount
      : payload.data.discountSet > 0
      ? payload.data.discountSet
      : payload.data.discountValueNet && payload.data.discountDelivery === 0
      ? payload.data.discountValueNet
      : 0 // ส่วนลดอาหาร

  mondaySchema._____________2 = {
    text:
      payload.data.selectedMenu.contact && JSON.parse(payload.data.selectedMenu.contact).text
        ? JSON.parse(payload.data.selectedMenu.contact).text
        : '',
  }
  mondaySchema.long_text3 = {
    text: payload.data.selectedMenu.long_text
      ? payload.data.selectedMenu.name + '\n' + JSON.parse(payload.data.selectedMenu.long_text).text
      : '',
  } // รายการอาหาร
  mondaySchema.________ = payload.data.defaultPrice > 0 ? payload.data.defaultPrice : 0 // ค่าอาหาร
  mondaySchema.status0 = payload.data.totalAmount > 0 && !payload.data.couponValids ? { index: 12 } : { index: 101 }
  mondaySchema.status40 = { index: 5 }
  mondaySchema.status7 = { index: 4 }
  mondaySchema.people = { personsAndTeams: [{ id: 7184378, kind: 'person' }] }
  mondaySchema.________3 = { text: payload.data.note }
  mondaySchema.text3 = payload.data.flagValidDiscountCode
    ? payload.data.discountCode
      ? payload.data.discountCode.toUpperCase()
      : ''
    : payload.data.couponValids && payload.data.couponValids.text8
    ? payload.data.couponValids.text8.replace(/"/g, '')
    : ''

  var ColumnValue = JSON.stringify(mondaySchema)

  const body = {
    query: `mutation ($board: Int!, $name: String!,  $group: String!, $column: JSON!) { 
		create_item( board_id: $board, item_name: $name,group_id :$group , column_values:$column ,create_labels_if_missing: true) { 
		  id 
		  column_values { 
			id 
			value 
		  } 
		} 
	  } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_BOOKING),
      name: payload.data.firstname + ' ' + payload.data.lastname,
      group: 'new_group42939',
      column: ColumnValue,
    },
  }
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const mondayNEXTCreateMemberTransactionRequest = async payload => {
  try {
    const data = payload.payload
    memberMondatSchema.name = data.firstname + ' ' + data.lastname
    memberMondatSchema.text = data.phone
    memberMondatSchema.text0 = data.email
    memberMondatSchema.text3 = payload.bookingID // ราคา
    memberMondatSchema.text75 = data.discountCode // ราคา
    memberMondatSchema.numbers = data.totalAmount // ราคา
    // check CARD ONLY
    if (data.selectedCoupon && data.selectedCoupon.check9) {
      let flag_check_cardOnly = JSON.parse(data.selectedCoupon.check9)
      if (flag_check_cardOnly && flag_check_cardOnly.checked === 'true') {
        memberMondatSchema.status9 = { index: 153 }
      }
    }

    if (data.couponValids) {
      memberMondatSchema.status9 = { index: 1 }
      memberMondatSchema.link7 = 'PAID'
      memberMondatSchema.text6 = data.couponValids.text8.replace(/"/g, '')
    }

    if (+data.totalAmount === 0) {
      memberMondatSchema.status9 = { index: 1 }
    }
  } catch (error) {
    console.log(error)
  }
  var ColumnValue = JSON.stringify(memberMondatSchema)

  const body = {
    query: `mutation ($board: Int!, $name: String!,  $group: String!, $column: JSON!) { 
      create_item( board_id: $board, item_name: $name,group_id :$group , column_values:$column ,create_labels_if_missing: true) { 
        id 
        column_values { 
        id 
        value 
        } 
      } 
      } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_CustomerTransaction),
      name: payload.payload.firstname + ' ' + payload.payload.lastname,
      group: 'topics',
      column: ColumnValue,
    },
  }
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const getTransactionSettingRequest = async payload => {
  const mondayNEXTPriceSettingQuery = `query { boards (ids: ${process.env.REACT_APP_MONDAY_FD_CustomerTransaction}) {columns {  title id settings_str  type  }}}`
  const body = {
    query: mondayNEXTPriceSettingQuery,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_CustomerTransaction),
    },
  }
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const postUpdatevalue = async payload => {
  // console.log(payload)

  if (payload.couponForever === 'true') return true

  const body = {
    query: `mutation($board: Int!, $column_id: String!, $item_id: Int!,  $value: JSON!) {
          change_column_value (board_id:  $board , column_id:  $column_id,item_id:  $item_id, value:  $value) {
          id
          }
          } `,
    variables: {
      board: parseInt(payload[0].board_id),
      item_id: Number(payload[0].id),
      column_id: 'status',
      value: '{"index": 2}',
    },
  }
   // console.log(body , parseInt(process.env.REACT_APP_MONDAY_FD_Codes) , Number(payload.data.items_by_column_values[0].id || payload[0].id)  )
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const postUpdateTransactionvStatus = async payload => {
  const body = {
    query: `mutation($board: Int!, $column_id: String!, $item_id: Int!,  $value: JSON!) {
          change_column_value (board_id:  $board, column_id:  $column_id,item_id:  $item_id, value:  $value) {
          id
          }
          } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_CustomerTransaction),
      item_id: Number(payload),
      column_id: 'status9',
      value: JSON.stringify({ index: 3 }),
    },
  }
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const mondayNextCodeRequest = async payload => {
  const mondayNEXTDiscountCodeSettingQuery = `{
        items_by_column_values (board_id:  ${
          payload.couponValids ? payload.couponValids.board_id : payload.selectedCoupon.board_id
        }, column_id: "text8", column_value: "${(
    payload.discountCode || payload.couponValids.text8.replace(/"/g, '')
  ).trim()}") {
            board { id }
          id
            name
            column_values { 
              id title value
            }
          }
        }`
  const body = {
    query: mondayNEXTDiscountCodeSettingQuery,
  }

  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const postUpdateTransactionvalue = async payload => {
  const body = {
    query: `mutation($board: Int!, $column_id: String!, $item_id: Int!,  $value: JSON!) {
          change_column_value (board_id:  $board, column_id:  $column_id,item_id:  $item_id, value:  $value) {
          id
          }
          } `,
    variables: {
      board: parseInt(process.env.REACT_APP_MONDAY_FD_CustomerTransaction),
      item_id: Number(payload.data.create_item.id),
      column_id: 'text00',
      value: `"https://whiteglove.worldrewardsolutions.com/payment/${payload.data.create_item.id}"`,
    },
  }
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}
const getTransactionDetailRequest = async payload => {
  const mondayNEXTPriceSettingQuery = `{ items (ids: [${parseInt(
    payload
  )}]) { id name column_values { id title value} } }`
  const body = {
    query: mondayNEXTPriceSettingQuery,
  }
  try {
    await axios.post('https://automation.rewrds.me/webhook/wgd-food-log-action', body, {
      headers: {
        'Content-Type': 'text/plain',
      },
    })
  } catch (error) {}
  return await axios
    .post('', body)
    .catch(err => {
      console.error(err.data)
    })
    .then(res => {
      return res.data
    })
}

const reCheck = async payload => {
  let data = []

  try {
    for (var i = 0; i < payload.length; i++) {
      let _item = destructure(payload[i])

      if (JSON.parse(_item.status).index === 1) {
        data = [_item]
        break
      }
    }
  } catch (error) {}
  return data
}

//sagas

function* mondayNEXTCreateMemberTransaction({ payload }) {
  try {
    const transactionSetting = yield call(getTransactionSettingRequest)
    if (payload.discountCode !== null) {
      const mondayCode = yield call(mondayNextCodeRequest, payload)

      const newData = yield call(reCheck, mondayCode.data.items_by_column_values)

      if (newData.length > 0 && mondayCode.data.items_by_column_values.length > 0) {
        yield call(postUpdatevalue, newData)
      } else {
        yield put(popupError('Sorry now this code are fully booked!'))
        throw new Error('Error code are fully ')
      }
    }
    const data = yield call(mondayNEXTCreateMemberTransactionRequest, { payload, transactionSetting })

    yield call(postUpdateTransactionvalue, data)
    let transcation = {
      id: data.data.create_item.id,
    }
    yield put(setCustomerTransaction(transcation))
    window.location = '/payment/' + data.data.create_item.id
  } catch (error) {
    console.log(error)
  }
}

function* mondayNEXTCreateMemberTransactionBooking({ payload }) {
  try {
    //
    if (payload.discountCode !== null || payload.couponValids !== undefined) {
      const mondayCode = yield call(mondayNextCodeRequest, payload)
      const newData = yield call(reCheck, mondayCode.data.items_by_column_values)

      if (newData.length > 0 && mondayCode.data.items_by_column_values.length > 0) {
        mondayCode.couponForever = payload.couponForever
        newData.couponForever = payload.couponForever

        yield call(postUpdatevalue, newData)
      } else {
        yield put(popupError('Sorry now this code are fully booked!'))
      }
    }

    const dataBooking = yield call(postToMondayRequest, { data: payload })

    // dataBooking

    if (dataBooking) {
      const data = yield call(mondayNEXTCreateMemberTransactionRequest, {
        payload,
        bookingID: dataBooking.data.create_item.id,
      })
      yield call(postUpdateTransactionvalue, data)
      let transcation = {
        id: data.data.create_item.id,
      }
      yield put(setCustomerTransactionAndBooking(transcation))

      yield put(popupHidden())
      window.location = '/payment/' + data.data.create_item.id

      // yield put(setTicketData(dataBooking.data.create_item.id))
    } else {
      yield put(popupError(dataBooking.err_code || 'ทำรายการไม่สำเร็จ'))
      // throw new Error(dataBooking.err_code)
    }

    //
  } catch (error) {
    yield put(popupError(error || 'ทำรายการไม่สำเร็จ'))
  }
}

function* getTransactionDetail({ payload }) {
  try {
    const mondayStructure = yield call(getTransactionDetailRequest, payload)
    const data = yield call(destucture1Select, mondayStructure)
    yield put(setTransactionDetail(data[0]))
  } catch (error) {}
}

const destucture1Select = async payload => {
  let newPayload = payload.data.items.map(priceSettingItem => {
    let newItem = {}
    newItem['id'] = priceSettingItem.id
    newItem['name'] = priceSettingItem.name
    Object.keys(priceSettingItem.column_values).forEach(function(key) {
      newItem[priceSettingItem.column_values[key].id] = priceSettingItem.column_values[key].value
    })
    return newItem
  })
  return newPayload
}

function* omiseUpdateStatus({ payload }) {
  try {
    yield call(postUpdateTransactionvStatus, payload)
  } catch (error) {}
}
//Wacher

/*
function* requestBannerWatcher() {
  yield takeEvery(BANNER_GET_BANNER, mondayNEXTSettingQuery)
}
*/

function* mondayNEXTCreateMemberTransactionWatcher() {
  yield takeEvery(MEMBER_POST_DETAIL, mondayNEXTCreateMemberTransaction)
}

function* mondayNEXTCreateMemberTransactionBookingWatcher() {
  yield takeEvery(MEMBER_POST_DETAIL_BOOKING, mondayNEXTCreateMemberTransactionBooking)
}

function* getTransactionDetailWatcher() {
  yield takeEvery(TRANSACTION_DETAIL_FETCH, getTransactionDetail)
}

export function* omiseUpdateServerWatcher() {
  yield takeEvery(OMISE_PAY_UPDATE_STATUS, omiseUpdateStatus)
}

export default function* rootSaga() {
  yield all([
    //fork(requestBannerWatcher),
    fork(mondayNEXTCreateMemberTransactionWatcher),
    fork(mondayNEXTCreateMemberTransactionBookingWatcher),
    fork(getTransactionDetailWatcher),
    fork(omiseUpdateServerWatcher),
  ])
}
