import { MENU_LIST_FETCH, MENU_LIST_FETCH_SUCCESS, RES_FETCH_SUCCESS, BANNER_FETCH_SUCCESS } from './types'

export const menuListFetch = () => {
  return {
    type: MENU_LIST_FETCH,
  }
}

export const menuListFetchSuccess = payload => {
  return {
    type: MENU_LIST_FETCH_SUCCESS,
    payload: payload,
  }
}

export const restaurantFetchSuccess = payload => {
  return {
    type: RES_FETCH_SUCCESS,
    payload: payload,
  }
}

export const bannerFetchSuccess = payload => {
  return {
    type: BANNER_FETCH_SUCCESS,
    payload: payload,
  }
}
