import React, { Component, Suspense } from 'react'
import { Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactHtmlParser from 'react-html-parser'
class TermModalFooter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalTermStatus: false,
      data: this.props.data,
      submitFn: '',
    }
  }
  onClickTerm = event => {
    event.preventDefault()
    this.setState({
      modalTermStatus: true,
    })
    document.body.classList.add('modal-open')
  }
  onCloseTerm = event => {
    event.preventDefault()
    this.setState({
      modalTermStatus: false,
    })
    document.body.classList.remove('modal-open')
  }

  onSend = event => {
    event.preventDefault()
    this.setState({
      modalTermStatus: false,
    })
    document.body.classList.remove('modal-open')
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.type) {
      this.setState({
        modalTermStatus: true,
      })
      document.body.classList.add('modal-open')
    } else {
      this.setState({
        modalTermStatus: false,
      })
      document.body.classList.remove('modal-open')
    }
  }

  render() {
    return (
      <div>
        <a href="#consent_form">
          <span onClick={this.onClickTerm}>Terms & Conditions</span>
        </a>

        <Suspense
          fallback={
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          }
        >
          <div
            className={`form modal ${this.state.modalTermStatus === true && 'd-block'} `}
            id="consent_form"
            tabIndex="999999"
            role="dialog"
            aria-labelledby="consent_form_title"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="detail-1">
                  <div className="modal-header">
                    <div className="modal-close" onClick={this.onCloseTerm}>
                      <span aria-hidden="true">&times;</span>
                    </div>

                    <span className="modal-title" id="consent_form_title">
                      Terms & Conditions
                    </span>
                  </div>

                  <div className="modal-body">
                    <b>Food delivery booking - Terms and conditions</b>
                    <br />
                    <br />
                    <ol>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.1')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => ReactHtmlParser(t('consent.2'))}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.3')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.4')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.5')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.6')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.7')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.8')}</Translation>
                      </li>
                      <li>
                        <Translation>{(t, { i18n }) => t('consent.9')}</Translation>
                      </li>
                    </ol>
                    <br /> <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      </div>
    )
  }
}

export default TermModalFooter
