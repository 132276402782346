import {
  CODE_GET_DATA,
  CODE_SET_DATA,
  CODE_POST_MONDAY,
  CODE_SET_PIC,
  CODE_REQUEST_CODE,
  CODE_POST_BOOKING_CORE,
  CODE_SET_VALID,
  CODE_GET_VALID,
  CODE_POST_REDEEM_CORE,
  CODE_REQUEST_DISCOUNT_CODE,
  CODE_REQUEST_DISCOUNT_CODE_SUCCESS,
  CODE_REQUEST_DISCOUNT_CODE_RESET,
  CODE_REQUEST_DISCOUNT_CODE_ERRORS,
  CODE_REQUEST_DISCOUNT_CODE_FIRST,
  CODE_REQUEST_DISCOUNT_CODE_SUCCESS_FIRST,
  CODE_POST_BEFORE_MONDAY,
} from './types'

export const getCouponData = ({ id, code }) => {
  return {
    type: CODE_GET_DATA,
    payload: { id, code },
  }
}

export const setTicketData = id => {
  return {
    type: CODE_SET_DATA,
    payload: { id },
  }
}

export const setPicData = id => {
  return {
    type: CODE_SET_PIC,
    payload: { id },
  }
}

export const submitBooking = (id, code, shopCode) => {
  return {
    type: CODE_REQUEST_CODE,
    payload: { id, code, shopCode },
  }
}

export const submitBookingMonday = (code, data, itemMondayId) => {
  return {
    type: CODE_POST_MONDAY,
    payload: { code, data, itemMondayId },
  }
}

export const submitBeforeBookingMonday = (code, data, itemMondayId) => {
  return {
    type: CODE_POST_BEFORE_MONDAY,
    payload: { code, data, itemMondayId },
  }
}

export const SetCodeValid = data => {
  return {
    type: CODE_SET_VALID,
    payload: { data },
  }
}

export const GetCodeValid = Code => {
  return {
    type: CODE_GET_VALID,
    payload: { Code },
  }
}

export const submitToCore = (code, data, privilege_id, coupon_id) => {
  return {
    type: CODE_POST_REDEEM_CORE,
    payload: { code, data, privilege_id, coupon_id },
  }
}

export const updateBookingCore = (...booking) => {
  return {
    type: CODE_POST_BOOKING_CORE,
    payload: { booking },
  }
}

export const checkDiscountCode = payload => {
  return {
    type: CODE_REQUEST_DISCOUNT_CODE,
    payload: payload,
  }
}

export const checkDiscountCodeFirst = payload => {
  return {
    type: CODE_REQUEST_DISCOUNT_CODE_FIRST,
    payload: payload,
  }
}

export const returnDiscountCode = payload => {
  return {
    type: CODE_REQUEST_DISCOUNT_CODE_SUCCESS,
    payload: payload,
  }
}

export const returnDiscountCodeFirst = payload => {
  return {
    type: CODE_REQUEST_DISCOUNT_CODE_SUCCESS_FIRST,
    payload: payload,
  }
}

export const resetDiscountCode = payload => {
  return {
    type: CODE_REQUEST_DISCOUNT_CODE_RESET,
    payload: payload,
  }
}

export const returnDiscountCodeError = payload => {
  return {
    type: CODE_REQUEST_DISCOUNT_CODE_ERRORS,
    payload: payload,
  }
}
