import { combineReducers } from 'redux'
import { reducer as reduxForm } from 'redux-form'
import AppStageReducer from './AppStageReducer'
import CouponReducer from './CouponReducer'
import PopupReducer from './PopupReducer'
import BannerReducer from './BannerReducer'
import UploadImageReducer from './UploadImageReducer'
import memberReducers from './memberReducers'
import menuReducers from './menuReducers'

const reducers = combineReducers({
  form: reduxForm,
  appStage: AppStageReducer,
  coupon: CouponReducer,
  popup: PopupReducer,
  banner: BannerReducer,
  imageUpload: UploadImageReducer,
  member: memberReducers,
  menu: menuReducers,
})

export default reducers
