import React from 'react'

function PopupBackdrop(props) {
  return (
    <div
      className={'modal-backdrop fade ' + (props.display ? 'show' : '')}
      style={props.display ? {} : { display: 'none' }}
    ></div>
  )
}
export default PopupBackdrop
