import { MENU_LIST_FETCH_SUCCESS, RES_FETCH_SUCCESS, BANNER_FETCH_SUCCESS } from '../actions/types'

const INIT_STATE = {
  list: [],
  restaurant: [],
  banner: [],
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MENU_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        list: action.payload,
      }
    }

    case RES_FETCH_SUCCESS: {
      return {
        ...state,
        restaurant: action.payload,
      }
    }

    case BANNER_FETCH_SUCCESS: {
      return {
        ...state,
        banner: action.payload,
      }
    }

    default:
      return state
  }
}
