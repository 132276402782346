import {
  CODE_SET_DATA,
  CODE_SET_VALID,
  CODE_REQUEST_DISCOUNT_CODE_SUCCESS,
  CODE_REQUEST_DISCOUNT_CODE_ERRORS,
  CODE_POST_BEFORE_MONDAY,
  CODE_REQUEST_DISCOUNT_CODE_RESET,
  CODE_REQUEST_DISCOUNT_CODE_SUCCESS_FIRST,
} from '../actions/types'

const INIT_STATE = {
  ticketId: 0,
  car_pic: 0,
  validationCodeFromServer: '',
  validationCodeDisCountFromMonday: {},
  firstLaunchCoupon: '',
  validationCodeDisCountFromMondayError: '',
  booking: {},
  car_type: {
    SVCAMRYTEST: { privilege_id: 2, coupon_id: 13, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    SVHYUNDAITEST: { privilege_id: 3, coupon_id: 14, value: 'H1', car: ['H1'], picIndex: 0 },
    SVHYUNDAI: { privilege_id: 3, coupon_id: 14, value: 'H1', car: ['H1'], picIndex: 0 },
    SVCAMRY: { privilege_id: 2, coupon_id: 13, value: 'CAMRY', car: ['CAMRY'], picIndex: 1 },
    SVBENBMWTEST: {
      privilege_id: 5,
      coupon_id: 15,
      value: 'BENBMW',
      car: ['BENZ E CLASS', 'BMW Series 5'],
      picIndex: 2,
    },
    SVBENBMW: { privilege_id: 5, coupon_id: 15, value: 'BENBMW', car: ['BENZ E CLASS', 'BMW Series 5'], picIndex: 2 },
    SVALPHARD: { privilege_id: 4, coupon_id: 9, value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4 },
    SVALPHARDTEST: { privilege_id: 4, coupon_id: 9, value: 'ALPHARD', car: ['ALPHARD'], picIndex: 4 },
  },
  mondayModel: {
    name: '',
    text: '',
  },
  carMaxDetail: {
    H1: {
      pax: '6 (28")',
      person: 6,
      Msg: 'Hyundai H1',
    },
    CAMRY: {
      pax: '3 (26") / 2 (28")',
      person: 3,
      Msg: 'Toyota Camry',
    },
    BENBMW: {
      pax: '3 (26") / 2 (28")',
      person: 3,
      Msg: '',
    },
    ALPHARD: {
      pax: '4 (28")',
      person: 4,
      Msg: 'Toyota Alphard',
    },
  },
  coupon: {},
  coperate: {},
  branch: {},
  coupon_id: 0,
  coupon_code: '',
  dataTosend: {},
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CODE_POST_BEFORE_MONDAY: {
      return {
        ...state,
        dataTosend: action.payload,
      }
    }
    case CODE_SET_DATA: {
      return {
        ...state,
        ticketId: action.payload.id,
      }
    }
    case CODE_SET_VALID: {
      return {
        ...state,
        validationCodeFromServer: action.payload.data,
      }
    }

    case CODE_REQUEST_DISCOUNT_CODE_SUCCESS: {
      return {
        ...state,
        validationCodeDisCountFromMonday: action.payload,
      }
    }

    case CODE_REQUEST_DISCOUNT_CODE_SUCCESS_FIRST: {
      // console.log('action.payload', action.payload)
      return {
        ...state,
        firstLaunchCoupon: action.payload,
      }
    }

    case CODE_REQUEST_DISCOUNT_CODE_RESET: {
      return {
        ...state,
        validationCodeDisCountFromMonday: {},
      }
    }

    case CODE_REQUEST_DISCOUNT_CODE_ERRORS: {
      return {
        ...state,
        validationCodeDisCountFromMondayError: action.payload, //"This code are fully booked! (this is precheck step, we will recheck code again when you book the trip!)",
      }
    }

    default:
      return state
  }
}
