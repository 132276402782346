import React, { Component } from 'react'
import { Translation } from 'react-i18next'
import '../../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'

// import logo_WG from '../../assets/imgs/WG.png'
import logo_WG from '../../assets/imgs/WGDLOGO.svg'

import logo from '../../assets/imgs/WRSLOGO.svg'
// import logo from '../../assets/imgs/logo-silver-white.png'
import HelpModal from '../../components/Modal/helpModal'
import MobileMenu from '../Menu/mobilemenu'

import { Link } from 'react-router-dom'

class Header extends Component {
  home = () => {
    window.location.href = '/'
  }
  render() {
    return (
      <div>
        <header className="header-desktop d-none d-md-flex">
          <Link to="/">
            <span className="logo-text">WHITE GLOVE DELIVERY by </span>&nbsp;&nbsp;
            <a href="/">
              <img alt="" src={logo} className="logo mr-4" />
            </a>
          </Link>
          {/* <a href="/"><img alt="" src={logo_WG} className="logo_WG"/></a> */}

          <div className="mr-auto"></div>
          <Link to="/" className="mobileLogo">
            <span className="pull-right mr-5" style={{ cursor: 'pointer' }} onClick={() => this.props.clickMenu(true)}>
              <Translation>{(t, { i18n }) => t('Menu')}</Translation>
            </span>
          </Link>
          <Link to="/verify-payment" className="mobileLogo">
            <span className="pull-right mr-5">
              <Translation>{(t, { i18n }) => t('VerifyPayment')}</Translation>
            </span>
          </Link>
          <span className="pull-right mr-5">
            <HelpModal />
          </span>
          <span className="pull-right">
            <Translation>
              {(t, { i18n }) => (
                <span
                  className={`lang ${i18n['language'] === 'en' ? 'active' : ''}`}
                  onClick={() => i18n.changeLanguage('en')}
                >
                  EN
                </span>
              )}
            </Translation>
            /
            <Translation>
              {(t, { i18n }) => (
                <span
                  className={`lang ${i18n['language'] === 'th' ? 'active' : ''}`}
                  onClick={() => i18n.changeLanguage('th')}
                >
                  TH
                </span>
              )}
            </Translation>
          </span>
        </header>
        <header className="header-mobile d-flex d-md-none">
          <MobileMenu clickMenu={this.props.clickMenu} />
          <Link to="/" className="mobileLogo">
            <div className="backgrond-logo">
              <img alt="" src={logo} className="logo" />
            </div>
          </Link>
        </header>
      </div>
    )
  }
}

export default Header
