import { BANNER_GET_PACKAGE, BANNER_GET_PACKAG_SUCCESS, BANNER_CLOSE_POPUP } from './types'

/*
export const getBanner = () => {
  return {
    type: BANNER_GET_BANNER,
  }
}
*/
export const getPackage = () => {
  return {
    type: BANNER_GET_PACKAGE,
  }
}

export const setPackage = payload => {
  return {
    type: BANNER_GET_PACKAG_SUCCESS,
    payload: payload,
  }
}

/*
export const setBanner = (payload) => {
  return {
    type: BANNER_GET_BANNER_SUCCESS,
    payload: payload,
  }
}*/
export const closeBannerPopup = () => {
  return {
    type: BANNER_CLOSE_POPUP,
  }
}
