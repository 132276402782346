import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {
  popupLoading,
  submitBookingMonday,
  submitToCore,
  resetDiscountCode,
  menuListFetch,
  getBanner,
  popupError,
  createCustomerTransactionAndBooking,
  GetCodeValid,
  submitBeforeBookingMonday,
  popupSuccess,
  checkDiscountCode,
  createCustomerTransaction,
} from '../../actions'

function keyGen(keyLength) {
  var i,
    key = '',
    characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  var charactersLength = characters.length
  for (i = 0; i < keyLength; i++) {
    key += characters.substr(Math.floor(Math.random() * charactersLength + 1), 1)
  }
  return key
}

async function completeCallback(response) {
  // Save the resultIndicator

  // post to adpter to update

  let storage = JSON.parse(localStorage.getItem('ticketPayment'))

  var body = {
    ticketNo: storage.ticketNo,
    orderId: storage.orderId,
    resultCode: '00',
  }
  await axios
    .post('https://md-sl.lifestyle-privilege.com/fd_amex_update', body, {})
    .then(res => {
      let responseData = res.data

      if (responseData && responseData.status === true) {
        localStorage.setItem('ticketPayment', '')
        window.location.reload()
      } else {
        console.log('ทำรายการไม่สำเร็จ กรุณาเลือกช่องทางอื่น หรือ ติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม')
        return false
      }
    })
    .catch(err => {
      console.log(err)
      console.log('ทำรายการไม่สำเร็จ กรุณาเลือกช่องทางอื่น หรือ ติดต่อ 02-016-9975 หากต้องการความช่วยเหลือเพิ่มเติม')
      window.location.reload()
    })
}

function errorCallback(error) {
  var message = JSON.stringify(error)

  console.log(message)
}

function beforeRedirect() {
  return {
    successIndicator: 'successIndicator',
    orderId: window.orderId,
  }
}

function afterRedirect(data) {}

window.errorCallback = errorCallback
window.completeCallback = completeCallback
window.beforeRedirect = beforeRedirect
window.afterRedirect = afterRedirect

class PaymentAmex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketNo: props.ticketNo,
      bookingNo: props.bookingNo,
      price: props.price,
      name: props.transaction.name,
      email: props.transaction.text0,
      payment_url: props.transaction.text00,
      credit_no: '',
      flag_payment: false,
      expirationMonth: '',
      expirationYear: '',
      securityCode: '',
      price_menu: '',
      price_menu_error: '',
    }
  }

  componentDidMount() {
    const { PaymentSession, localStorage } = window

    localStorage.setItem('amex', JSON.stringify({}))
    let item_id = parseInt(this.state.bookingNo.replace(/"/g, ''))
    // console.log(item_id, this.state.bookingNo)
    const itemPriceQuery = `  query {     items (ids: [ ${item_id} ]) { id name column_values (ids : ["________" , "numbers"]) { id title value}}}`
    // console.log(itemPriceQuery)

    if (item_id) {
      let date = axios
        .post('', { query: itemPriceQuery })
        .catch(err => {
          this.setState({
            price_menu_error: 'ประมวลผลไม่สำเร็จ  กรุณาติดต่อเจ้าหน้าที่เพื่อขอรับความช่วยเหลือ',
          })
        })
        .then(res => {
          // console.log(res.data)
          let realPrice = parseInt(
            res.data.data.mapping.items[0].________ ? res.data.data.mapping.items[0].________.replace(/"/g, '') : ''
          )
          let disCountPrice = parseInt(
            res.data.data.mapping.items[0].numbers ? res.data.data.mapping.items[0].numbers.replace(/"/g, '') : ''
          )

          this.setState({
            price_menu: realPrice - disCountPrice,
            price_menu_error: '',
          })

          let price = parseInt(this.state.price_menu)
          let detail = 'เลขอ้างอิงจากระบบ คือ ' + this.state.ticketNo
          let ticket_no = this.state.ticketNo

          PaymentSession.configure({
            fields: {
              // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
              card: {
                number: '#card-number',
                securityCode: '#security-code',
                expiryMonth: '#expiry-month',
                expiryYear: '#expiry-year',
              },
            },
            //SPECIFY YOUR MITIGATION OPTION HERE
            frameEmbeddingMitigation: ['javascript'],
            callbacks: {
              initialized: function(response) {
                // HANDLE INITIALIZATION RESPONSE
                // console.log(response)
              },
              formSessionUpdate: async function(response) {
                // HANDLE RESPONSE FOR UPDATE SESSION
                // console.log(response)
                if (response.status) {
                  if ('ok' == response.status) {
                    //check if the security code was provided by the user
                    if (response.sourceOfFunds.provided.card.securityCode) {
                      console.log('Security code was provided.')
                    }
                    // Submit fields
                    // console.log(response)
                    let transactionId = 'tran-' + keyGen(10)
                    let orderId = 'order-' + keyGen(10)
                    let secureId = 'sc-' + keyGen(10)

                    var data = {
                      apiOperation: 'CHECK_3DS_ENROLLMENT',
                      sessionId: response.session.id,
                      transactionId: transactionId,
                      orderId: orderId,
                      secureId: secureId,
                      orderAmount: parseInt(price),
                      orderCurrency: 'THB',
                      orderDescription: detail,
                      secureIdResponseUrl: `https://md-sl.lifestyle-privilege.com/fd_amex_update/${ticket_no}/${secureId}/${orderId}/${transactionId}/${parseInt(
                        price
                      )}/${response.session.id}/`,
                    }

                    await axios
                      .post('https://md-sl.lifestyle-privilege.com/fd_amex_session_update', data, {})
                      .then(res => {
                        // console.log('Data: ', res.data)
                        document.documentElement.innerHTML = res.data
                        document.forms['echoForm'].submit()
                      })

                    // console.log('Data: ' + JSON.stringify(data))
                  } else if ('fields_in_error' == response.status) {
                    console.log('Session update failed with field errors.')
                    if (response.errors.cardNumber) {
                      console.log('Card number invalid or missing.')
                    }
                    if (response.errors.expiryYear) {
                      console.log('Expiry year invalid or missing.')
                    }
                    if (response.errors.expiryMonth) {
                      console.log('Expiry month invalid or missing.')
                    }
                    if (response.errors.securityCode) {
                      console.log('Security code invalid.')
                    }
                  } else if ('request_timeout' == response.status) {
                    console.log('Session update failed with request timeout: ' + response.errors.message)
                  } else if ('system_error' == response.status) {
                    console.log('Session update failed with system error: ' + response.errors.message)
                  }
                } else {
                  console.log('Session update failed: ' + response)
                }
              },
            },
          })

          return res.data
        })
    }
    {
      this.setState({
        price_menu_error: 'กำลังประมวลผล',
      })
    }

    // console.log(document.getElementById('card-number'))
  }

  formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  onChangeCreditCard = event => {
    var visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
    var amex = new RegExp('^3[47][0-9]{13}$')
    var mastercard = new RegExp('^5[1-5][0-9]{14}$')
    var onlyNum = new RegExp('^[0-9]+$')
    // console.log(event.target.value)
    if (visa.test(event.target.value) || amex.test(event.target.value) || mastercard.test(event.target.value)) {
      this.setState({
        flag_payment: true,
      })
    }
    if (event.target.value === '' || onlyNum.test(event.target.value)) {
      this.setState({
        credit_no: event.target.value,
      })
    }
  }

  onClickCheckout = event => {
    event.preventDefault()

    const { localStorage, PaymentSession } = window
    let orderId = 'order-' + keyGen(10)
    let storage = {}
    storage.orderId = orderId
    storage.ticketNo = this.state.ticketNo

    localStorage.setItem('ticketPayment', JSON.stringify(storage))

    var sessionIdBody = {
      apiOperation: 'CHECK_3DS_ENROLLMENT',
      interaction: { operation: 'AUTHORIZE' },
      order: {
        id: orderId,
        currency: 'THB',
      },
    }

    PaymentSession.updateSessionFromForm('card')

    //Checkout.showPaymentPage();
    return
  }

  render() {
    return (
      <div className="row" style={{ color: '#850612' }}>
        <div className="contents col-12">
          <div className="col-md-12">
            {this.state.price_menu ? (
              <div>
                <h4>
                  <b>Payment details</b>
                </h4>
                <fieldset>
                  <div class="form-group">
                    <label class="col-md-12 control-label" htmlFor="card-number">
                      <b>Card number:</b>
                    </label>
                    <div class="col-md-12">
                      <input type="text" id="card-number" class="form-control input-md" value="" readOnly />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-md-12 control-label" htmlFor="expiry-month">
                      <b>Expiry month:</b> e.g., 01 09 12{' '}
                    </label>
                    <div class="col-md-12">
                      <input type="text" id="expiry-month" class="form-control input-md" value="" readOnly />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-md-12 control-label" htmlFor="expiry-year">
                      <b>Expiry year:</b> e.g., 20 21 22
                    </label>
                    <div class="col-md-12">
                      <input type="text" id="expiry-year" class="form-control input-md" value="" readOnly />
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="col-md-12 control-label" htmlFor="security-code">
                      <b>Security code:</b> 4 digit{' '}
                    </label>
                    <div class="col-md-12">
                      <input type="text" id="security-code" class="form-control input-md" value="" readOnly />
                    </div>
                  </div>
                  <button class="btn btn-primary" id="payButton" onClick={this.onClickCheckout}>
                    Pay Now{' '}
                    {this.formatNumber(
                      parseInt(this.state.price_menu)
                        ? parseInt(this.state.price_menu)
                        : this.props.price.replace(/"/g, '')
                    )}{' '}
                    THB
                  </button>
                </fieldset>
              </div>
            ) : this.state.price_menu_error ? (
              <div style={{ textAlign: 'center' }}> {this.state.price_menu_error} </div>
            ) : (
              <div style={{ textAlign: 'center' }}>กำลังประมวลผลราคาใหม่... </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ coupon, popup, banner, imageUpload }) => {
  return { coupon, popup, banner, imageUpload }
}

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
})(PaymentAmex)
