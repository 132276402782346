import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { popupLoading, submitBookingMonday, submitToCore, popupError, GetCodeValid } from '../actions'
import { geolocated } from 'react-geolocated'

import axios from 'axios'

class Geolocated extends Component {
  onClick = get => {
    // console.log(this.props)
  }

  render() {
    return !this.props.isGeolocationAvailable ? (
      <div></div>
    ) : !this.props.isGeolocationEnabled ? (
      <div></div>
    ) : this.props.coords ? (
      <button
        type="button"
        onClick={val => {
          this.onClick(val)
        }}
      >
        กดเพื่อรับตำแหน่งปัจจุบันของคุณ
      </button>
    ) : (
      <div> มีการขออนุญาติรับตำแหน่งของท่านเพื่อความสะดวกในการจัดส่ง &hellip; </div>
    )
  }
}
const mapStateToProps = all => {
  // console.log(all)
  return { all }
}
const a = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Geolocated)

export default connect(mapStateToProps, {
  popupLoading,
  popupError,
  submitBookingMonday,
  submitToCore,
  GetCodeValid,
})(a)
