import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'reactstrap'
import '../../assets/css/app.css'
import '../../assets/css/booking-page.css'
import '../../assets/css/buy-page.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  submitBookingMonday,
  uploadImage,
  uploadImageClear,
  uploadImageMonday,
  getTransactionDetail,
} from '../../actions'
import { Translation } from 'react-i18next'
import blank_slip from '../../assets/imgs/blank_slip.png'
import 'react-datepicker/dist/react-datepicker.css'

import bank2 from '../../assets/imgs/bank/bank-2.png'
import omiseLogo from '../../assets/imgs/omise.png'
import blankSlip from '../../assets/imgs/blank_slip.png'
import loading from '../../assets/imgs/loading.gif'
import PaymentButton from './PaymentButton'
import PaymentGB from './PaymentGBprimePay'

class Payment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ticketNo: props.match.params.ticketNo,
      buyStep: 3,
      selectedRoute: null,
      textAddSlip: 'แนบหลักฐาน',
      selectedPayment: '1',
      selectedBank: 2,
      receiveSlip: false,
      slipImage: blankSlip,
      waitModal: false,
      uploadStatus: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.getTransactionDetail(this.state.ticketNo)
  }

  formStyle = {
    borderRadius: '20px !important!important',
    paddingVertical: '5px',
    paddingHorizontal: '20px',
    border: '1px solid #da1a43',
    color: '000000',
  }

  componentDidUpdate() {
    if (this.props.imageUpload.uploadStatus) {
      this.setUploadImage()
    }
  }

  onChangeImage = e => {
    const files = Array.from(e.target.files)
    const formData = new FormData()
    files.forEach((file, i) => {
      formData.append(i, file)
    })
    this.props.uploadImage(formData, this.state.ticketNo)
  }

  toggleModal = () => {
    this.setState({
      waitModal: !this.state.waitModal,
    })
  }

  setUploadImage = () => {
    const { imageUpload } = this.props
    const image_url = 'https://s3-ap-southeast-1.amazonaws.com/static.nextride.club/' + imageUpload.uploadPath.key
    this.setState({
      uploadStatus: true,
      slipImage: image_url,
      textAddSlip: 'ADD NEW SLIP',
    })
    this.props.uploadImageClear()
  }

  normalizePhone = value => {
    if (!value) {
      return value
    }
    const onlyNums = value.replace(/[^^[0][0-9-]+$]/g, '')
    return onlyNums
  }

  handleInputChange(value, name) {
    // Booking Time Formatter
    try {
      this.setState({ [name]: value })
      let val = ''
      if (name === 'bookingTime') {
        val = value + ''
        let numberCode = val.match('[0-9]+')
        if (val.length === 2 && numberCode[0].length === 2) {
          val += ':'
        }

        this.setState({ [name]: val.substring(0, 5) })
      }
    } catch (e) {
      console.log(e)
    }
  }

  checkFormByStep = step => {
    if (step === 3) {
      this.setState({
        buyStep: 4,
      })
    } else {
      this.setState({
        buyStep: 1,
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    this.props.popupLoading()
    this.setState({ disabled: true })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.member.payment.couponCode !== '') {
      this.setState({
        waitModal: false,
        buyStep: 6,
      })
    }

    if (nextProps.member.payment.status === 'successful') {
      this.setState({
        waitModal: false,
      })
    }
  }
  copyToClipboard = e => {
    this.textArea.select()
    document.execCommand('copy')

    e.target.focus()
    this.setState({ copySuccess: 'Copied!' })
  }
  copyText = () => {
    this.refs.refcode.select()
    // console.log('z', this.refs)
    document.execCommand('copy')
    alert('copy done')
  }

  render() {
    const { member } = this.props
    let pay = false

    if (member.payment.status) {
      pay = true
    } else if (member.transaction.numbers && member.transaction.numbers.replace(/"/g, '') === '0') {
      pay = true
    } else if (member.transaction.status9) {
      pay = JSON.parse(member.transaction.status9).index === 1
      if (JSON.parse(member.transaction.status9).index === 2 || JSON.parse(member.transaction.status9).index === 4) {
        pay = 'cancel'
      }
    }

    return (
      <div>
        <div className="bg-payment"></div>
        <div className="container buy-page">
          <div className="bg-circle"></div>
          <div className="title-page position-relative">
            {member.transaction.text6 !== '"PAID"' ? 'การชำระเงิน' : ''}
            <div className="row mx-auto buy-content mh-100">
              {this.state.buyStep === 3 && pay === false ? (
                <div className="col-12 col-md-5 p-0 pt-4">
                  <div className="reference-bar">
                    <input type="hidden" ref="refcode" name="refcode" defaultValue={member.transaction.id} />
                    <Translation>{(t, { i18n }) => t('ReferenceCode')}</Translation> : {member.transaction.id}{' '}
                    {/*<div class="mr-auto"></div><div className="pull-right"><i class="icon-ico-copy" onClick={this.copyText }></i></div> */}
                  </div>
                  <div className="buy-form top-bar">
                    <div className="row">
                      <div className="form-group col mb-0">
                        <select
                          name="selectedPayment"
                          id="selectedPayment"
                          className="form-control"
                          style={this.formStyle}
                          value={this.state.selectedPayment}
                          onChange={({ target: { value } }) => this.setState({ selectedPayment: value })}
                          required
                        >
                          <Translation>
                            {(t, { i18n }) => <option value="1">{t('optionPayment.bank')}</option>}
                          </Translation>
                          <Translation>
                            {(t, { i18n }) => <option value="2">{t('optionPayment.card')}</option>}
                          </Translation>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="buy-form">
                    <div className="row">
                      <div className="form-group col">
                        <h5>
                          {this.state.selectedPayment === '1' ? (
                            <div style={{ color: 'black' }}>
                              <Translation>{(t, { i18n }) => t('optionPayment.bank_text')}</Translation>
                              <div className="pt-4 px-4">
                                <label class="container">
                                  <img alt="" src={bank2} className="mr-3" /> Kasikorn Bank
                                  <br />
                                  046-1-56971-4 บจก. เวิลด์ รีวอร์ด โซลูชั่น
                                </label>
                                <label class="container">
                                  จำนวน{' '}
                                  {member.transaction.numbers
                                    ? member.transaction.numbers.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''}{' '}
                                  บาท
                                </label>
                              </div>
                              <button className="btn btn-next w-100" onClick={e => this.checkFormByStep(3)}>
                                ADD SLIP
                              </button>
                            </div>
                          ) : (
                            ''
                          )}

                          {this.state.selectedPayment === '2' ? (
                            <div>
                              Secured by
                              <img alt="" src={omiseLogo} className="p-5" style={{ width: '100%' }} />
                              <PaymentButton
                                ticketNo={this.state.ticketNo}
                                price={member.transaction.numbers}
                                bookingNo={member.transaction.text3 || ''}
                                transaction={member.transaction}
                                toggle={this.toggleModal}
                              />
                            </div>
                          ) : (
                            ''
                          )}

                          {this.state.selectedPayment === '3' ? (
                            <div>
                              Secured by gbPrimePay ({' '}
                              <div>
                                <PaymentGB
                                  ticketNo={this.state.ticketNo}
                                  price={member.transaction.numbers}
                                  bookingNo={member.transaction.text3 || ''}
                                  transaction={member.transaction}
                                  toggle={this.toggleModal}
                                />
                              </div>{' '}
                              )
                            </div>
                          ) : (
                            ''
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ' '
              )}

              {this.state.buyStep === 4 && pay === false ? (
                <div className="col-12 col-md-5 p-0 position-relative">
                  <div className="buy-form">
                    <>
                      <div className="m-5 text-center">
                        <img alt="" src={this.state.slipImage} style={{ width: '80%' }} />
                      </div>
                      <input
                        type="file"
                        id="upload_image_slip"
                        name="upload_image_slip"
                        onChange={this.onChangeImage}
                        ref={input => (this.inputElement = input)}
                        style={{ display: 'none' }}
                      />
                      <button
                        className="btn btn-next w-100"
                        onClick={e => {
                          this.inputElement.click()
                          // this.setState({receiveSlip:true,buyStep:5})
                        }}
                      >
                        {this.state.textAddSlip}
                      </button>
                      <button
                        className="btn btn-next w-100"
                        onClick={e => {
                          if (this.state.uploadStatus === true) {
                            this.props.uploadImageMonday({
                              id: this.state.ticketNo,
                              url: this.state.slipImage,
                            })
                          }
                          this.setState({ buyStep: 5 })
                        }}
                      >
                        ส่งหลักฐาน
                      </button>
                      <button className="btn btn-next-clear w-100" onClick={e => this.setState({ buyStep: 3 })}>
                        {/* CHANGE PAYMENT METHOD */}
                        ย้อนกลับ
                      </button>
                    </>
                  </div>
                </div>
              ) : (
                ' '
              )}

              {this.state.buyStep === 5 && pay === false ? (
                <div className="col-12 col-md-5 p-0 position-relative justify-content" style={{ color: 'black' }}>
                  <div className="buy-succes text-center">
                    <div className="thankyou-title">ขอขอบพระคุณ</div>
                    <div className="thankyou-text">
                      ที่ท่านได้ไว้วางใจให้ white Glove Delivery ดูแลมื้ออาหารสุดพิเศษของคุณ
                    </div>
                  </div>
                  <div className="buy-form">
                    ทางเจ้าหน้าที่จะทำการตรวจสอบข้อมูลรายการและทำการส่งข้อความยืนยันทาง sms ภายใน 6 ชั่วโมง
                  </div>
                </div>
              ) : (
                ' '
              )}
              {this.state.buyStep === 6 || pay === true ? (
                <div className="col-12 col-md-5 p-0 position-relative justify-content" style={{ color: 'black' }}>
                  <div className="buy-succes text-center">
                    <div className="thankyou-title">ขอขอบพระคุณ</div>
                    <div className="thankyou-text">
                      ที่ท่านได้ไว้วางใจให้ white Glove Delivery ดูแลมื้ออาหารสุดพิเศษของคุณ
                    </div>
                  </div>
                  <div className="buy-form text-center">
                    {member.payment.couponCode
                      ? member.payment.couponCode === 'PAID'
                        ? 'ทำรายการสำเร็จ!'
                        : 'Your member code: ' + member.payment.couponCode
                      : 'ทำรายการสำเร็จ!'}
                    <br />
                    <p style={{ fontSize: '21px' }}>
                      หากมีปัญหาหรือต้องการช่วยเหลือสามารถติดต่อได้ที่ Line official Id: @silvervoyage{' '}
                    </p>
                  </div>
                </div>
              ) : (
                ' '
              )}

              {pay === 'cancel' ? (
                <div className="col-12 col-md-5 p-0 position-relative justify-content" style={{ color: 'black' }}>
                  <div className="buy-succes text-center">
                    <div className="thankyou-text">รายการถูกยกเลิก</div>
                    <div className="thankyou-text">
                      ขออภัยในความไม่สะดวก รหัสอ้างอิงดังกล่าว ถูกยกเลิกเนื่องจากพบปัญหาบางประการ
                    </div>
                  </div>
                  <div className="buy-form text-center">
                    <p style={{ fontSize: '21px' }}>
                      กรุณาติดต่อที่ หมายเลขโทรศัพท์ 02-016-9975 กด 3 หรือ Line official Id: @silvervoyage{' '}
                    </p>
                  </div>
                </div>
              ) : (
                ' '
              )}

              {/* <div className="col-12 col-md-6 div-image-car d-none d-md-block ">
              <img alt="" src={imgCar} className="w-100"/>
          </div> */}
            </div>
          </div>

          <form onSubmit={this.handleSubmit}></form>
          <Modal isOpen={this.state.waitModal} className="paymentModal">
            <div className="modal-content" style={{ height: '600px' }}>
              <div className="modal-header">
                <h1 className="modal-title ">Please wait...</h1>
              </div>
              <div className="modal-body text-body text-center">
                Your payment is currently being processed. <br />
                Do not refresh or close your browser.
                <img alt="" src={loading} className="w-100" />
              </div>
            </div>
          </Modal>
          <div className="home-clear-space"></div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = ({ coupon, popup, banner, imageUpload, member }) => {
  return { coupon, popup, banner, imageUpload, member }
}

export default connect(mapStateToProps, {
  submitBookingMonday,
  uploadImage,
  uploadImageClear,
  uploadImageMonday,
  getTransactionDetail,
})(Payment)
