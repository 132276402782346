export const parseText = payload => {
  let tempText = ''
  try {
  } catch {}

  return tempText
}

export const parseJSON = payload => {
  return JSON.parse(payload) !== null ? JSON.parse(payload) : '{index:null}'
}

export const parseDropdowm = payload => {
  let tempText = ''
  try {
  } catch {}

  return tempText
}

export const destructure = payload => {
  let newItem = {}
  newItem['id'] = payload.id
  newItem['board_id'] = payload.board.id
  newItem['name'] = payload.name
  Object.keys(payload.column_values).forEach(function(key) {
    newItem[payload.column_values[key].id] = payload.column_values[key].value
  })

  return newItem
}

export const destructureArr = payload => {
  let newPayload = payload.map(Item => {
    let newItem = {}
    newItem['id'] = Item.id
    newItem['board_id'] = Item.board.id
    newItem['name'] = Item.name
    newItem['additional_info'] = {}
    Object.keys(Item.column_values).forEach(function(key) {
      newItem[Item.column_values[key].id] = Item.column_values[key].value
      if (Item.column_values[key].additional_info) {
        newItem['additional_info'][Item.column_values[key].id] = JSON.parse(Item.column_values[key].additional_info)
      }
    })

    return newItem
  })

  return newPayload
}
