export const APP_STAGE_LOADING = 'APP_STAGE_LOADING'
export const APP_STAGE_DISPLAY_COUPON = 'APP_STAGE_DISPLAY_COUPON'
export const APP_STAGE_NOT_FOUND = 'APP_STAGE_NOT_FOUND'

export const COUPON_INIT_DATA = 'COUPON_INIT_DATA'
export const COUPON_GET_DATA = 'COUPON_GET_DATA'
export const COUPON_SET_DATA = 'COUPON_SET_DATA'
export const COUPON_REQUEST_SHOP_CODE = 'COUPON_REQUEST_SHOP_CODE'

export const CODE_REQUEST_CODE = 'CODE_REQUEST_CODE'
export const CODE_GET_DATA = 'CODE_GET_DATA'
export const CODE_SET_DATA = 'CODE_SET_DATA'
export const CODE_POST_MONDAY = 'CODE_POST_MONDAY'
export const CODE_SET_VALID = 'CODE_SET_VALID'
export const CODE_GET_VALID = 'CODE_GET_VALID'
export const CODE_SET_PIC = 'CODE_SET_PIC'

export const CODE_POST_BEFORE_MONDAY = 'CODE_POST_BEFORE_MONDAY'

export const CODE_POST_REDEEM_CORE = 'CODE_POST_REDEEM_CORE'
export const CODE_POST_BOOKING_CORE = 'CODE_POST_BOOKING_CORE'

export const POPUP_STAGE_HIDDEN = 'POPUP_STAGE_HIDDEN'
export const POPUP_STAGE_LOADING = 'POPUP_STAGE_LOADING'
export const POPUP_STAGE_CONSENT = 'POPUP_STAGE_CONSENT'
export const POPUP_STAGE_ERROR = 'POPUP_STAGE_ERROR'
export const POPUP_STAGE_NOTCONSENT = 'POPUP_STAGE_NOTCONSENT'
export const POPUP_STAGE_SUCCESS = 'POPUP_STAGE_SUCCESS'

//NEW

export const BANNER_GET_DETAIL_PRICE = 'BANNER_GET_DETAIL_PRICE'
export const BANNER_GET_DETAIL_ROUTE = 'BANNER_GET_DETAIL_ROUTE'
export const BANNER_GET_BANNER = 'BANNER_GET_BANNER'
export const BANNER_GET_BANNER_SUCCESS = 'BANNER_GET_BANNER_SUCCESS'
export const BANNER_GET_PACKAGE = 'BANNER_GET_PACKAGE'
export const BANNER_GET_PACKAG_SUCCESS = 'BANNER_GET_PACKAG_SUCCESS'
export const BANNER_CLOSE_POPUP = 'BANNER_CLOSE_POPUP'

export const MEMBER_POST_DETAIL = 'MEMBER_POST_DETAIL' //p1.2
export const MEMBER_POST_DETAIL_SUCCESS = 'MEMBER_POST_DETAIL_SUCCESS' //p1.2
//confirm omise / upload slip
export const MEMBER_POST_DETAIL_BOOKING = 'MEMBER_POST_DETAIL_BOOKING' //p1.2
export const MEMBER_POST_DETAIL_BOOKING_SUCCESS = 'MEMBER_POST_DETAIL_BOOKING_SUCCESS' //p1.2

export const MEMBER_FETCH_DETAIL_BY_CODE = 'MEMBER_FETCH_DETAIL_BY_CODE' //p2
export const MEMBER_FETCH_DETAIL_SUCCESS = 'MEMBER_FETCH_DETAIL_SUCCESS' //p2

export const CODE_REQUEST_DISCOUNT_CODE = 'CODE_REQUEST_DISCOUNT_CODE' //p1.1
export const CODE_REQUEST_DISCOUNT_CODE_SUCCESS = 'CODE_REQUEST_DISCOUNT_CODE_SUCCESS' //p1.1
export const CODE_REQUEST_DISCOUNT_CODE_ERRORS = 'CODE_REQUEST_DISCOUNT_CODE_ERRORS' //p1.1
export const CODE_REQUEST_DISCOUNT_CODE_RESET = 'CODE_REQUEST_DISCOUNT_CODE_RESET'
export const CODE_REQUEST_DISCOUNT_CODE_FIRST = 'CODE_REQUEST_DISCOUNT_CODE_FIRST'
export const CODE_REQUEST_DISCOUNT_CODE_SUCCESS_FIRST = 'CODE_REQUEST_DISCOUNT_CODE_SUCCESS_FIRST'

// Upload Image
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE'
export const UPLOAD_IMAGE_CLEAR = 'UPLOAD_IMAGE_CLEAR'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_MONDAY = 'UPLOAD_IMAGE_MONDAY'

// PAY OMISE
export const OMISE_PAY_SERVER = 'OMISE_PAY_SERVER'
export const OMISE_PAY_SERVER_SUCCESS = 'OMISE_PAY_SERVER_SUCCESS'
export const OMISE_PAY_UPDATE_STATUS = 'OMISE_PAY_UPDATE_STATUS'

// MONDAY CUSTOMER & TRANSACTION
export const TRANSACTION_DETAIL_FETCH = 'TRANSACTION_DETAIL_FETCH'
export const TRANSACTION_DETAIL_FETCH_SUCCESS = 'TRANSACTION_DETAIL_FETCH_SUCCESS'

// MENU LIST
export const MENU_LIST_FETCH = 'MENU_LIST_FETCH'
export const MENU_LIST_FETCH_SUCCESS = 'MENU_LIST_FETCH_SUCCESS'
export const RES_FETCH_SUCCESS = 'RES_FETCH_SUCCESS'
export const BANNER_FETCH_SUCCESS = 'BANNER_FETCH_SUCCESS'
