import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Translation } from 'react-i18next'
import '../assets/css/app.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/privacy-policy.css'
class PrivacyPolicy extends Component {
  render() {
    return (
      <>
        <div className="privacy-content text-left">
          <div class="border1"></div>
          <div class="border2"></div>
          <div className="privacy-detail">
            <Translation>
              {(t, { i18n }) =>
                i18n['language'] === 'en' ? (
                  <>
                    <div class="mb-4 text-center text-bold title">
                      Privacy Policy
                      <br />
                      World Reward Solutions Co., Ltd.
                    </div>
                    <br />
                    World Reward Solutions Co., Ltd. (the <b>“Company”</b>, <b>“we”</b>, <b>“us”</b>, or <b>“our”</b>)
                    recognizes the importance of the protection of personal data for our potential customers, customers,
                    or retail merchants (<b>“you”</b>, <b>“your”</b>, or <b>“yours”</b>) of our products and services.
                    We follow strict security procedures when collecting, using, disclosing and/ or internationally
                    transferring your Personal Data (as defined in Section 1 below) outside of Thailand. The information
                    you share with us allows us, affiliates and subsidiaries and our business partners, to provide the
                    products and services you may need and want, while giving you the very best personalized experience
                    and customer services.
                    <br />
                    <br />
                    This privacy policy (<b>“Privacy Policy”</b>) applies to our business, websites, mobile
                    applications, call center, hotline, social networking sites, online communication channels and other
                    locations where we collect your Personal Data. <br />
                    <br />
                    From time to time, we may change and/or update this Privacy Policy. We will provide additional
                    notice of significant updates. We will post the date our Privacy Policy was last updated at the top
                    of the Privacy Policy. We encourage you to read this Privacy Policy carefully and to regularly check
                    Privacy Policy to review any changes we might take in accordance with the terms of this Privacy
                    Policy.
                    <br />
                    <br />
                    <b> 1. WHAT PERSONAL DATA WE COLLECT </b>
                    <br />
                    For the purposes of this Privacy Policy, “Personal Data” means any identified or identifiable
                    information about you as listed below. We may collect your Personal Data directly or indirectly from
                    other sources including, affiliates and subsidiaries, our service providers, and our business
                    partners who are third parties. The specific type of data collected will depend on the context of
                    your interactions with us, and the services or products you need or want from us. The following are
                    example of Personal Data that may be collected:
                    <ol>
                      <li>
                        <b>Personal details</b>, such as title, name, surname, gender, age, occupation, job title,
                        position, business type, nationality, date of birth, marital status, marriage certificate,
                        number of family members and child, information on government-issued cards (e.g. identification
                        number, copy of national identification card, passport number, driver's license details), house
                        registration, signature, voice, voice record, picture, photo, photograph, VDO records, video
                        clip, educational backgrounds, workplace, income, weight and height, CCTV records{' '}
                      </li>
                      <li>
                        <b>Contact details</b>, such as address, delivery details, billing details, phone number, mobile
                        phone number, business phone number, email address, business email, LINE ID, Facebook account,
                        Google account, Twitter account, and other account-related to the social networking sites;{' '}
                      </li>
                      <li>
                        <b>Account details</b>, such as credit/debit card holder number, credit/debit card information,
                        bank account number, member ID, customer ID, member type, customer type, customer credit score,
                        service and product applications, joined month and payment details, and copy of bank account/
                        bank book;
                      </li>
                      <li>
                        <b>Transaction details</b>, such as payment information, card usage and transaction data (such
                        as lead and customer data ), campaign response data, payment slip details, refund amount,
                        points, and date and location of purchase, purchase/order number, appointment date for service,
                        complaints and claims, transaction, transaction history, location, transaction status, past
                        sales transaction and other details of products and services you have purchased;{' '}
                      </li>
                      <li>
                        <b>Technical details</b>, such as Internet Protocol (IP) address, web beacon, log, devicetype,
                        hardware-based identifiers such as universal device identifier (UDID) or Mac Address,
                        software-based identifier such as identifier for advertisers for iOS operation system (IDFA), or
                        identifier for advertisers for Andriod operation system (AAID), network, connection details,
                        access details, single sign-on (SSO), login log, access time, time spent on our page, cookies,
                        your login data, search history, browsing detail, browser type and version, time zone setting
                        and location, browser plug-in types and versions, operating system and platform, and other
                        technology on devices you use to access the platform;
                      </li>
                      <li>
                        <b>Behaviour details</b>, such as information about your purchasing behavior and data supplied
                        through the use of our products and services, such as your locationand destination ;{' '}
                      </li>
                      <li>
                        <b>Profile details</b>, such as your username and password, profile, purchase, historical order,
                        past order, purchase history, items bought, item quantity, orders or product recalls made by
                        you, orders via websites, order ID, financial records, PIN, your interests, preference, feedback
                        and survey responses data, satisfaction survey, social media engagement, participation details,
                        loyalty programs, your use of discount codes and promotions, customer order description,
                        customer service, attendance to trade exhibitions and event, and insurance policy details;
                      </li>
                      <li>
                        <b>Usage details</b>, such as information on how you use the websites, platforms, products and
                        services, Q&A record; and/or
                      </li>
                      <li>
                        <b>Marketing and communication details</b>, such as your preference in receiving marketing from
                        us, affiliates and subsidiaries, third parties, business partners and your communication
                        preferences.
                      </li>
                    </ol>
                    In addition, your Personal Data may be collected from our business partners in case you purchase a
                    product from one of our business partners whose products are displayed on our websites. Your
                    Personal Data related to the product you have purchased will be sent to us for the purposes of sale
                    tracking and service improvement.
                    <br />
                    <br />
                    If you provide Personal Data of any third party (such as your friend, parents) to us, e.g., their
                    name, family name, telephone number, and relationship to the policyholder or insured, for the
                    provision of our products and services or for emergency contact, you represent that you have the
                    authority to do so and to permit us to use the Personal Data in accordance with this Privacy Policy.
                    <br />
                    <br />
                    We do not intentionally collect your sensitive data (<b>“Sensitive Data”</b>). However, in case that
                    we do, we will only collect, use, and/or disclose Sensitive Data on the basis of your explicit
                    consent, e.g. drug or food allergy or where permitted by law.
                    <br />
                    <br />
                    We only collect the Personal Data of children, quasi-incompetent person and incompetent person where
                    their parent or guardian has given their consent. We do not knowingly collect Personal Data from
                    customers under the age of 20 without their parental consent when it is required, or from
                    quasi-incompetent person and incompetent person without their legal guardian's consent. In the event
                    that we learn that we have unintentionally collected Personal Data from anyone under the age of 20
                    without parental consent when it is required or from quasi-incompetent person and incompetent person
                    without their legal guardians, we will delete it immediately or process if we can rely on other
                    legal basis apart from consent.
                    <br />
                    <br />
                    <b>2. WHY WE COLLECT, USE AND/OR DISCLOSE YOUR PERSONAL DATA</b>
                    <br />
                    We may collect, use or disclose your Personal Data for the following purposes:
                    <br />
                    <br />
                    <b>2.1 THE PURPOSE OF WHICH YOU HAVE GIVEN YOUR CONSENT:</b>
                    We rely on your consent for the collection, use, and/or disclosure of your Personal Data by us,
                    affiliates and subsidiaries, and our business partners for the following purposes:
                    <br />
                    <ol>
                      <li>
                        <b>Marketing and Communications</b>: To provide marketing communications, information, special
                        offers, promotional materials, tele-marketing, privilege, advertisement, newsletter, and any
                        marketing and communications, both online and offline channels, about products and services from
                        us, affiliates and subsidiaries, and business partners which we cannot rely on other legal
                        bases;
                      </li>
                      <li>
                        <b>Data Analytics Services</b>: To conduct data analytics services <br />
                        If you do not give consent or withdraw your consent for this purpose, we may not be a ble to
                        provide our services to you.
                      </li>
                    </ol>
                    <br />
                    <b>2.2 THE PURPOSE THAT WE MAY RELY ON OTHER LEGAL GROUNDS FOR PROCESSING YOUR PERSONAL DATA</b>
                    <br />
                    We may also rely on (1) contractual basis, for our initiation or fulfilment of a contract with you;
                    (2) legal obligation, for the fulfilment of our legal obligations; (3) legitimate interest, for the
                    purpose of our legitimate interests and the legitimate interests of third parties. We will balance
                    the legitimate interest pursued by us and any relevant third party with your interest and
                    fundamental rights and freedoms in relation to the protection of your Personal Data; (4) vital
                    interest, for preventing or suppressing a danger to a person’s life, body or health; and/or (5)
                    public interest, for the performance of a task carried out in the public interest or for the
                    exercising of official authorities for the following purposes:
                    <ol>
                      <li>
                        <b>To provide products and services to you:</b>To register and enable you to use our products or
                        services, including, but not limited to enable you to use our websites, mobile applications, and
                        platforms ; to process a request for service application or benefits in connection with our
                        products and services; to enter into a contract and manage our contractual relationship with
                        you; to support and perform other activities related to such services or products; to sell our
                        products or services via online and offline channels; to deliver or ship to you the products and
                        services via online sale; to provide bulk sales of our products or services to our corporate
                        customers; to provide our online media performance and digital marketing service; to process
                        transaction between you and our business partners; to complete and manage bookings and to carry
                        out financial transaction and service related to the payments including transaction check and
                        verification and cancellation; to process your orders, delivery, suspension, replacement,
                        reimbursement, refund and exchange of products or servicesand to provide customer service
                        operation;{' '}
                      </li>
                      <li>
                        <b>To provide marketing communications:</b>To provide marketing communications, information,
                        special offers, promotional materials, tele-marketing, privilege, advertisement, newsletter, and
                        any marketing and communications, both online and offline channels, about products and services
                        from us, affiliates and subsidiaries, and business partners;
                      </li>
                      <li>
                        <b>
                          To offer promotions, special offers, loyalty programs, reward programs, prize draws,
                          competitions and other offers/promotions to you:
                        </b>
                        To allow you to participate in promotions, promotional campaign, special offers, promotional
                        offer, loyalty programs, co-registration program with our business partners, sweepstakes,
                        privilege, prize draws, competitions and other offers/promotions (e.g., to send reminder
                        emails), events and seminars. This includes to process and administer your account registration,
                        gift registration, event registration; to process points collection, addition, exchange,
                        earning, redemption, and transfer of points; to examine your entire user history, both online
                        and offline; and to provide and issue gift voucher, gift cards and invoices;{' '}
                      </li>
                      <li>
                        <b>To contact and communicate with you:</b>To provide you with information, marketing
                        communications, campaign, advertisement, required notices, special offers, benefits, and
                        promotional materials of our products or services; to send you news, electronic newsletters,
                        marketing messages and information about the products, services, brands, and operations;{' '}
                      </li>
                      <li>
                        <b>To manage our relationship with you:</b>To communicate with you in relation to the products
                        and services you obtain from us, , affiliates and subsidiaries, and from our business partners;
                        to handle customer service, call center and/or hotline-related queries, request, feedback,
                        complains, claims, disputes or indemnity; to provide technical assistance and deal with
                        technical issues; to process and update your information; and to facilitate your use of the
                        products and services;{' '}
                      </li>
                      <li>
                        <b>To conduct data cleansing, profiling and analytics:</b>To measure your engagement with the
                        products and services; to undertake data cleansing and matching, data profiling and data
                        analytics; to conduct market research, surveys, assessment, behaviour, statistics and
                        segmentation, consumption trends and patterns; to know you better and understand your
                        characteristics; to improve business performance; to better adapt our content to the identified
                        preferences of our customers; to determine the effectiveness of our promotional campaigns; to
                        identify and resolve of issues with existing products and services; to enhance the qualitative
                        information development; to establish whether you already has a relationship with the selected
                        business partners;
                      </li>
                      <li>
                        <b>
                          To select and provides products or services that are likely to be of your interest and
                          tailored to your needs:{' '}
                        </b>
                        To use the result from data cleansing and matching, data profiling and data analytics of your
                        Personal Data to recommend products and services that might be of interest to you from us,
                        affiliates and subsidiaries, and our business partners; to identify your preferences, and
                        personalize your experience; and to develop future editorial content targeted to your interests;
                      </li>
                      <li>
                        <b>To improve business operation, products and services: </b>To evaluate, develop, manage,
                        improve existing and design new services, products, system and business operation for you and
                        all of our customers, including but not limited to, customers of affiliates and subsidiaries,
                        and our business partners; to track and follow-up with your sale transaction (sale tracking) for
                        our service improvement; to identify and resolve issues; to create aggregated and anonymized
                        reports and measure the performance of our physical products, digital properties, and marketing
                        campaigns; and to manage, operate and maintain our payment systems. We may monitor and/or record
                        our call with you to train our staff and improve our services;
                      </li>
                      <li>
                        <b>To learn more about you: </b>To learn more about the products and services you receive, and
                        other products and services you may be interested in receiving, including profiling based on the
                        processing of your Personal Data, for instance by looking at the types of products and services
                        that you use from us, how you like to be contacted and so on;
                      </li>
                      <li>
                        <b>To ensure the function of our websites, mobile applications, and platforms: </b>To
                        administer, operate, track, monitor and manage our websites, mobile applications, and platforms
                        to facilitate and ensure that they function properly, efficiently and securely; to facilitate
                        and enhance your experience on our websites, mobile applications, and platforms; and improve
                        layout and content of our websites, mobile applications, and platforms;
                      </li>
                      <li>
                        <b>To manage IT system: </b>For our own business management purpose including for our IT
                        operations, management of communication system, operation of IT security and IT security audit;
                        internal business management for internal compliance requirements, policies and procedures; and
                        to update our database;
                      </li>
                      <li>
                        <b>To comply with regulatory and compliance obligations:</b>To comply with legal obligations,
                        legal proceedings or government authorities' orders which can include orders from government
                        authorities outside Thailand and/or cooperate with court, regulators, government authority and
                        law enforcement bodies when we reasonably believe that we are legally required to do so and when
                        disclosing your Personal Data is strictly necessary to comply with the said legal obligations,
                        proceedings or government orders. This includes to issue tax invoice or full tax form; to comply
                        with electronic e-payment business, financial; to record and monitor communications; to disclose
                        to tax authorities, financial service regulators, other regulatory and governmental bodies; and
                        to investigate or prevent crime;
                      </li>
                      <li>
                        <b>To protect our interests: </b>To protect the security and integrity of our business; to
                        exercise our rights or protect our interest where it is necessary and lawfully to do so and to
                        prevent and report criminal offences and to protect the security and integrity of our business;
                      </li>
                      <li>
                        <b>To detect, suppress, and prevent fraud/ illegal actions:</b>To authenticate and verify your
                        identity, and to conduct legal and other regulatory compliance checks (e.g., to comply with
                        e-payment business, financial, insurance, and anti-money laundering related laws and
                        regulations) This includes to perform sanction list checking, internal audits and records, asset
                        management, system and other business controls;
                      </li>
                      <li>
                        <b>To transfer in the event of merger:</b>sale, transfer, merger, reorganization or similar
                        event we may transfer your Personal Data to one or more third parties as part of that
                        transaction;
                      </li>
                      <li>
                        <b>Risks:</b>To perform risk management, audit performance and risk assessments; to conduct
                        credit checks and customer financial due diligence; and/or
                      </li>
                      <li>
                        <b>Life:</b>To prevent or suppress a danger to a person’s life, body or health.
                      </li>
                    </ol>
                    Where the Personal Data we collect from you is needed to meet our legal or regulatory obligations or
                    enter into an agreement with you, if you do not provide your Personal Data when requested, we may
                    not be able to provide (or continue to provide) our products and services to you.
                    <br />
                    <br />
                    <b>3. TO WHOM WE MAY DISCLOSE OR CROSS-BOARDER TRANSFER YOUR PERSONAL DATA</b>
                    <br />
                    We may disclose or transfer your Personal Data to the following third parties who process Personal
                    Data in accordance with the purpose under this Privacy Policy. These third parties may be located in
                    Thailand and areas outside Thailand. You can visit their privacy policy to learn more details on how
                    they process your Personal Data.
                    <br />
                    <br />
                    <b>3.1 Our service providers</b>
                    <br />
                    We may use other companies, agents or contractors to perform services on behalf or to assist with
                    the provision of products and services to you. We may share Personal Data including but not limited
                    to (1) infrastructure, software and website developer and IT service providers; (2) logistic service
                    providers; (3) data storage and cloud service providers; (4) data cleansing and matching, data
                    profiling, and data analytics service providers; (5) marketing, advertising media and communications
                    agencies; (6) research agencies; (7) survey agencies; (8) campaign and event organizers; (9) call
                    center service providers; (10) payment, payment system, authentication service providers; (11)
                    outsourced administrative service providers; and/or (12) telecommunications and communication
                    service providers.
                    <br />
                    <br />
                    In the course of providing such services, the service providers may have access to your Personal
                    Data. However, we will only provide our service providers with the Personal Data that is necessary
                    for them to perform the services, and we ask them not to use your Personal Data for any other
                    purposes. We will ensure that all the service providers we work with will keep your Personal Data
                    secure.
                    <br />
                    <br />
                    <b>3.2 Our business partners</b>
                    <br />
                    We may transfer your Personal Data to our business partners including, but not limited to, digital
                    marketing providers; banking and financial providers; reward and loyalty programs operators;
                    insurance providers; telecommunications and communication business operators; financial
                    professionals; asset management providers; investment providers; retail providers; e-commerce
                    providers; investors; co-registration program partners; referral partners whom we may jointly offer
                    products or services; co-membership partners and automotive business operator; data bulk sale
                    partners; data selling vendors; and its affiliates; lead-generation providers; business partners who
                    we provide our lead generation to; research agencies; market survey partners including the their
                    clients; media agencies to conduct market research, surveys, assessment, behaviour, statistics and
                    segmentation, consumption trends and patterns; reporters to advertise our marketing campaigns;
                    independent financial professional who provide the fee quotation of the financial and/or insurance
                    related products or services requested by you and conduct the underwriting process; reinsurers who
                    help to manage our business and reduce the risks associated with underwritten insurance policies by
                    spreading our risks across alternative institutions; claims investigation firms , provided that the
                    receiving business partner agrees to treat your Personal Data in a manner consistent with this
                    Privacy Policy.
                    <br />
                    <br />
                    <b>3.3 Social networking sites</b>
                    <br />
                    If you log in using the social network login system, you explicitly authorize to access and store
                    public data on your social network accounts (e.g. Facebook, Google, Instagram), as well as other
                    data mentioned during use of such social network login system. In addition, we may also communicate
                    your email address to social networks in order to identify whether you are already a user of the
                    concerned social network and in order to post personalized, relevant adverts on your social network
                    account if appropriate.
                    <br />
                    <br />
                    We also partner with certain third parties that allow you to enroll in their services or participate
                    in their promotions. For example, certain companies allow you to use your loyalty program number or
                    online services login to receive or register for their services. Additionally, your social network
                    account provider allows you to connect your social network account to your online services account
                    or log into your online services account from your social network account. When you enroll in those
                    services, we disclose your Personal Data to those third parties. If you do not want to share your
                    Personal Data in this way, do not provide your loyalty or reward program number/ account to third
                    parties, do not use your online services account to register for third-party promotions and do not
                    connect your online services account with accounts on third-party services. Data shared in this way
                    will be governed by the third party’s privacy policy and not this Privacy Policy.
                    <br />
                    <br />
                    <b>3.4 Third parties permitted by law </b>
                    <br />
                    In certain circumstances, we may be required to disclose or share your Personal Data in order to
                    comply with a legal or regulatory obligations. This includes any law enforcement agency, court,
                    regulator, government authority or other third party where we believe this is necessary to comply
                    with a legal or regulatory obligation, or otherwise to protect our rights, the rights of any third
                    party or individuals’ personal safety; or to detect, prevent, or otherwise address fraud, security
                    or safety issues (e.g., Anti-Money Laundering Office (AMLO), Bank of Thailand (BOT), Office of
                    Insurance Commission (OIC) and Revenue Department). <br />
                    <br />
                    <b>3.5 Professional advisors </b>
                    <br />
                    This includes lawyers and auditors who assist in running our business and defending or bringing any
                    legal claims.
                    <br />
                    <br />
                    <b>3.6 Third parties connected with business transfer </b>
                    <br />
                    We may disclose or transfer your Personal Data to our business partner, investor, significant
                    shareholders, assignee or transferee in the event of any reorganization, restructuring, merger,
                    acquisition, sale, purchase, joint venture, assignment, or any similar event involving transfer or
                    other disposition of all or any portion of our business, assets or stock. If any of above events
                    occur, the receiving party will comply with this Privacy Policy to respect your Personal Data.
                    <br />
                    <br />
                    <b>4. INTERNATIONAL TRANSFERS OF YOUR PERSONAL DATA</b>
                    <br />
                    We may disclose or transfer your Personal Data to third parties or servers located overseas, which
                    the destination countries may or may not have the same data protection standards. We take steps and
                    measures to ensure that your Personal Data is securely transferred, that the receiving parties has
                    in place suitable data protection standard and that the transfer is lawful by relying on the
                    derogations permitted under the law.
                    <br />
                    <br />
                    <b>5. HOW LONG DO WE KEEP YOUR PERSONAL DATA</b>
                    <br />
                    We retain your Personal Data for as long as is reasonably necessary to fulfil purpose for which we
                    obtained them and to comply with our legal and regulatory obligations. However, we may have to
                    retain your Personal Data for a longer duration, as required by applicable law.
                    <br />
                    <br />
                    <b>6. COOKIES AND HOW THEY ARE USED</b>
                    <br />
                    If you visit our websites, we will gather certain information automatically from you by using
                    tracking tools and cookies (Cookies are tracking technologies which are used in analyzing trends,
                    administering our websites, tracking users’ movements around the websites, or to remember users’
                    settings. Some of the cookies are necessary because otherwise the site is unable to function
                    properly. Other cookies are convenient for the visitors: they remember your username in a secure way
                    as well as your language preferences.)
                    <br />
                    <br />
                    If you reject cookies, your ability to use some or all of the features or areas of our websites may
                    be
                    <br />
                    <br />
                    <b>7. YOUR RIGHTS AS A DATA SUBJECT</b>
                    <br />
                    Subject to applicable laws and exceptions thereof, you may have the following rights to:
                    <br />
                    <ol>
                      <li>
                        <b>Access:</b>You may have the right to access or request a copy of the Personal Data we are
                        processing about you. For your own privacy and security, we may require you to prove your
                        identity before providing the requested Personal Data to you;
                      </li>
                      <li>
                        <b>Rectification:</b>You may have the right to have incomplete, inaccurate, misleading, or or
                        not up to date Personal Data that we process about you rectified;
                      </li>
                      <li>
                        <b>Data Portability: </b>You may have the right to obtain Personal Data we hold about you, in a
                        structured, electronic format, and to transmit such data to another data controller, where this
                        is (a) Personal Data which you have provided to us, and (b) if we are processing that data on
                        the basis of your consent or to perform a contract with you;
                      </li>
                      <li>
                        <b>Objection: </b>You may have the right to object to certain processing of your Personal Data
                        such as objecting to direct marketing;
                      </li>
                      <li>
                        <b>Restriction: </b>You may have the right to restrict our processing of your Personal Data
                        where you believe such data to be inaccurate, our processing is unlawful, or that we no longer
                        need to process such data for a particular purpose;
                      </li>
                      <li>
                        <b>Withdraw Consent: </b>For the purposes you have consented to our processing of your Personal
                        Data, you have the right to withdraw your consent at any time;
                      </li>
                      <li>
                        <b>Deletion: </b>You may have the right to request that we delete or de-identity Personal Data
                        that we process about you, except we are not obligated to do so if we need to retain such data
                        in order to comply with a legal obligation or to establish, exercise or defend legal claims; and{' '}
                      </li>
                      <li>
                        <b>Lodge a complaint: </b>You may have the right to lodge a complaint to the competent authority
                        where you believe our processing of your Personal Data is unlawful or non-compliance with
                        applicable data protection law.
                      </li>
                    </ol>
                    <br />
                    <b>8. OUR CONTACT DETAIL </b>
                    <br />
                    If you wish to contact us to exercise the rights relating to your Personal Data or if you have any
                    queries about your Personal Data under this Privacy Policy, please contact us or our Data Protection
                    Officer (DPO) at:
                    <br />
                    <ol>
                      <li>
                        World Reward Solutions Co., Ltd.
                        <ol>
                          <li>
                            • World Reward Solutions Company Limited(Head Office) 818/27 The Master @ BTS Udomsuk
                            Udomsuk Rd, Bangna Nuea, Bangna, Bangkok, Thailand 10260
                          </li>
                          <li>• 020169998</li>
                        </ol>
                      </li>
                      <li>
                        Data Protection Officer (DPO)
                        <ol>
                          <li>
                            • World Reward Solutions Company Limited(Head Office) 818/27 The Master @ BTS Udomsuk
                            Udomsuk Rd, Bangna Nuea, Bangna, Bangkok, Thailand 10260
                          </li>
                          <li>• 020169998</li>
                          <li>• dpo@worldrewardsolutions.com</li>
                        </ol>
                      </li>
                    </ol>
                  </>
                ) : (
                  <>
                    <div class="mb-4 text-center text-bold title">
                      Privacy Policy
                      <br />
                      World Reward Solutions Co., Ltd.
                    </div>
                    บริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด (“<b>บริษัทฯ</b>”)
                    ตระหนักถึงความสำคัญของการคุ้มครองข้อมูลส่วนบุคคล ของผู้ที่จะเป็นลูกค้า ลูกค้า หรือผู้ค้าปลีก (“
                    <b>ท่าน</b>”) ในสินค้าและบริการของบริษัทฯ
                    บริษัทฯปฏิบัติตามขั้นตอนเพื่อความปลอดภัยอย่างเคร่งครัดในการเก็บ ใช้ เปิดเผย และ/หรือ
                    ถ่ายโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังต่างประเทศ ข้อมูลที่ท่านแบ่งปันให้กับบริษัทฯ ทำให้บริษัทฯ รวมถึง
                    บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ
                    สามารถนำเสนอสินค้าและบริการที่ตรงกับความต้องการของท่าน
                    พร้อมมอบประสบการณ์และบริการลูกค้าแบบเฉพาะตัวที่ดีที่สุดให้แก่ท่าน
                    <br />
                    <br />
                    นโยบายความเป็นส่วนตัวฉบับนี้ (“<b>นโยบายความเป็นส่วนตัวฯ</b>”) ใช้กับธุรกิจ เว็บไซต์
                    แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ Call Center สายด่วนตอบข้อซักถาม เว็บไซต์เครือข่ายสังคม
                    ช่องทางการสื่อสารออนไลน์ และสถานที่อื่น ๆ ที่บริษัทฯเก็บข้อมูลส่วนบุคคลฯ ของท่าน
                    <br />
                    <br />
                    บริษัทฯอาจเปลี่ยนแปลง และ/หรือปรับปรุงนโยบายความเป็นส่วนตัวฯ นี้เป็นครั้งคราว
                    โดยบริษัทฯจะแจ้งท่านเพิ่มเติมหากมีการปรับปรุงที่สำคัญ บริษัทฯจะระบุวันที่นโยบายความเป็นส่วนตัวฯ
                    ของบริษัทฯได้มีการปรับปรุงครั้งล่าสุดไว้ทางด้านบนของนโยบายความเป็นส่วนตัวฯ ทั้งนี้
                    บริษัทฯสนับสนุนให้ท่านอ่านนโยบายความเป็นส่วนตัวฯ นี้อย่างระมัดระวัง และตรวจสอบนโยบายความเป็นส่วนตัวฯ
                    อย่างสม่ำเสมอเพื่อทบทวนการเปลี่ยนแปลงใด ๆ ที่บริษัทฯอาจดำเนินการตามข้อกำหนดในนโยบายความเป็นส่วนตัวฯ
                    ฉบับนี้
                    <br />
                    <br />
                    <b>1. บริษัทฯเก็บข้อมูลส่วนบุคคลอะไร</b>
                    <br />
                    เพื่อวัตถุประสงค์แห่งนโยบายความเป็นส่วนตัวฯ ฉบับนี้ คำว่า “<b>ข้อมูลส่วนบุคคลฯ</b>” หมายความถึง
                    ข้อมูลที่ระบุหรือที่สามารถระบุตัวตนของท่านได้ ตามรายการที่ระบุด้านล่างนี้
                    <br />
                    <br />
                    บริษัทฯอาจเก็บข้อมูลส่วนบุคคลฯ ของท่านโดยตรงหรือโดยอ้อมจากแหล่งที่มาอื่น ๆ ซึ่งรวมถึง บริษัทในเครือ
                    และบริษัทย่อย ผู้ให้บริการของบริษัทฯ และคู่ค้าทางธุรกิจของบริษัทฯซึ่งเป็นบุคคลที่สาม
                    ประเภทของข้อมูลที่เก็บจะขึ้นอยู่กับบริบทของปฏิสัมพันธ์ระหว่างบริษัทฯกับท่าน
                    และบริการหรือสินค้าที่ท่านประสงค์จากบริษัทฯ ตัวอย่างของข้อมูลส่วนบุคคลฯ ที่อาจเก็บมีดังนี้
                    <ol>
                      <li>
                        <b>รายละเอียดส่วนบุคคล</b>เช่น คำนำหน้าชื่อ ชื่อ นามสกุล เพศ อายุ อาชีพ ตำแหน่งงาน ตำแหน่ง
                        ประเภทธุรกิจ สัญชาติ วันเดือนปีเกิด สถานภาพทางการสมรส ทะเบียนสมรส จำนวนสมาชิกในครอบครัวและบุตร
                        ข้อมูลบนบัตรที่ออกโดยหน่วยงานราชการ (เช่น เลขประจำตัวประชาชน สำเนาบัตรประจำตัวประชาชน
                        หมายเลขหนังสือเดินทาง รายละเอียดใบอนุญาตขับขี่) ทะเบียนบ้าน ลายมือชื่อ เสียง บันทึกเสียง รูป ภาพ
                        รูปถ่าย บันทึกวิดีโอ คลิปวิดีโอ ประวัติทางการศึกษา สถานที่ทำงาน รายได้ น้ำหนักและส่วนสูง
                        บันทึกโทรทัศน์วงจรปิด{' '}
                      </li>
                      <li>
                        <b>รายละเอียดในการติดต่อ </b>เช่น ที่อยู่ รายละเอียดในการจัดส่ง รายละเอียดในการเรียกเก็บเงิน
                        หมายเลขโทรศัพท์ หมายเลขโทรศัพท์เคลื่อนที่ หมายเลขโทรศัพท์ที่ทำงาน อีเมล อีเมลที่ทำงาน
                        ชื่อบัญชีไลน์ บัญชีเฟซบุ๊ก บัญชีกูเกิล บัญชีทวิตเตอร์ และบัญชีอื่น ๆ
                        ที่เกี่ยวกับเว็บไซต์เครือข่ายสังคม{' '}
                      </li>
                      <li>
                        <b>รายละเอียดทางบัญชี </b>เช่น หมายเลขบัตรเครดิต/เดบิต ข้อมูลบัตรเครดิต/เดบิต หมายเลขบัญชีธนาคาร
                        รหัสสมาชิก รหัสลูกค้า ประเภทสมาชิก ประเภทลูกค้า คะแนนเครดิตลูกค้า ใบสมัครขอรับสินค้าและบริการ
                        เดือนที่เข้าเป็นสมาชิก/รับบริการ และรายละเอียดการชำระเงิน และสำเนาบัญชีธนาคาร/สมุดบัญชีธนาคาร
                      </li>
                      <li>
                        <b>รายละเอียดธุรกรรม</b>เช่น ข้อมูลการชำระเงิน ข้อมูลการใช้บัตรและข้อมูลธุรกรรม (เช่น
                        ข้อมูลและบันทึกการใช้และธุรกรรม ข้อมูลกลุ่มลูกค้าเป้าหมาย (Lead) ข้อมูลการตอบสนองต่อแคมเปญโฆษณา
                        รายละเอียดสลิปชำระเงินเกี่ยวกับการคืนเงิน จำนวนเงินที่คืน คะแนน วันและสถานที่ที่ใช้จ่าย
                        หมายเลขการสั่งจอง/คำสั่งจอง วันที่นัดรับบริการ ข้อร้องเรียนและข้อเรียกร้อง ธุรกรรม
                        ประวัติธุรกรรม สถานที่ สถานะธุรกรรม ธุรกรรมการขายที่ผ่านมา และพฤติกรรมการใช้จ่าย
                        ตลอดจนรายละเอียดอื่น ๆ ของสินค้าและบริการที่ท่านได้จองกับบริษัทฯ
                      </li>
                      <li>
                        <b>รายละเอียดทางเทคนิค</b>เช่น Internet Portal Address (IP Address) Universal Device Identifier
                        (UDID) หรือ MAC Address รหัสอ้างอิงซอฟต์แวร์ เช่น ข้อมูลตัวระบุโฆษณาสำหรับระบบปฏิบัติการไอโอเอส
                        (IDFA) หรือข้อมูลตัวระบุโฆษณาสำหรับระบบปฏิบัติการแอนดรอยด์ (AAID) เครือข่าย
                        รายละเอียดการเชื่อมต่อ รายละเอียดการเข้าถึง
                        การเข้าถึงการใช้บริการของระบบด้วยการพิสูจน์ตัวตนเพียงครั้งเดียว บันทึกการเข้าสู่ระบบ (Login Log)
                        บันทึกเวลาการเข้าใช้ระบบ ระยะเวลาที่ใช้ในการเยี่ยมชมหน้าเพจของบริษัทฯ คุกกี้
                        ข้อมูลการเข้าสู่ระบบของท่าน ประวัติการค้นหา รายละเอียดการค้นหา ประเภทและเวอร์ชั่นของเบราว์เซอร์
                        ที่ตั้งและตำแหน่งของเขตเวลา ประเภทและเวอร์ชั่นของเบราว์เซอร์ปลั๊กอิน ระบบและแพลตฟอร์มปฏิบัติการ
                        และเทคโนโลยีอื่น ๆ บนอุปกรณ์ที่ท่านใช้ในการเข้าถึงแพลตฟอร์ม
                      </li>
                      <li>
                        <b>รายละเอียดพฤติกรรม</b>เช่น ข้อมูลที่มาจากการที่ท่านใช้สินค้าและบริการของบริษัทฯ เช่น
                        ตำแหน่งที่ท่านอยู่ ตำแหน่งที่ท่านเดินทาง
                      </li>
                      <li>
                        <b>รายละเอียดโปรไฟล์ </b>เช่น ชื่อผู้ใช้และรหัสผ่าน โปรไฟล์ การใช้จ่าย ประวัติคำสั่งจอง
                        การสั่งจองที่ผ่านมา ประวัติการจอง รายการที่จอง จำนวนที่จองต่อรายการ
                        การยกเลิกคำสั่งจองหรือการคืนสินค้าจากท่าน คำสั่งจองผ่านทางเว็บไซต์ รหัสคำสั่งจอง
                        บันทึกทางการเงิน หมายเลขยืนยันตัวตน (PIN) ข้อมูลเกี่ยวกับความสนใจ ความชื่นชอบ
                        ผลตอบรับและผลการตอบแบบสำรวจ ผลสำรวจความพึงพอใจ การใช้โซเชียลมีเดีย รายละเอียดการเข้าร่วมกิจกรรม
                        โปรแกรมสะสมคะแนน (Loyalty Program) การใช้รหัสส่วนลดและโปรโมชั่นของท่าน รายละเอียดคำสั่งจอง
                        การบริการลูกค้า (CRM){' '}
                      </li>
                      <li>
                        <b>รายละเอียดการใช้</b>เช่น ข้อมูลการใช้เว็บไซต์ แพลตฟอร์ม สินค้า และบริการของท่าน บันทึกถาม-ตอบ
                        และ/หรือ
                      </li>
                      <li>
                        <b>รายละเอียดการตลาดและการสื่อสาร</b>เช่น ความชื่นชอบของท่านในการรับข่าวสารด้านการตลาดจากบริษัทฯ
                        บริษัทในเครือ และบริษัทย่อย บุคคลที่สาม คู่ค้าทางธุรกิจ และความชื่นชอบในการติดต่อสื่อสารของท่าน
                      </li>
                    </ol>
                    นอกจากนี้ ข้อมูลส่วนบุคคลฯ ของท่านอาจถูกเก็บรวบรวมจากคู่ค้าทางธุรกิจของบริษัทฯ
                    ในกรณีที่ท่านจองสินค้าจากคู่ค้าทางธุรกิจผ่านเว็บไซต์ของบริษัทฯ ทั้งนี้
                    บริษัทฯจะได้รับข้อมูลส่วนบุคคลฯ ของท่าน ในส่วนที่เกี่ยวข้องกับสินค้าที่ท่านได้จอง
                    เพื่อวัตถุประสงค์ในการติดตามผลธุรกรรมการขายและการพัฒนาการให้บริการ
                    <br />
                    <br />
                    ในกรณีที่ท่านให้ข้อมูลส่วนบุคคลฯ ของบุคคลอื่นใด (เช่น เพื่อน ผู้ปกครอง) กับบริษัทฯ เช่น ชื่อ นามสกุล
                    หมายเลขโทรศัพท์ ไม่ว่าจะเป็นไปเพื่อการจัดส่งสินค้าและบริการของบริษัทฯ
                    หรือเพื่อการติดต่อในกรณีฉุกเฉิน
                    ท่านรับรองว่าท่านมีอำนาจที่จะดำเนินการเช่นนั้นได้และอนุญาตให้บริษัทฯใช้ข้อมูลส่วนบุคคลฯ
                    ดังกล่าวตามนโยบายความเป็นส่วนตัวฯ ฉบับนี้
                    <br />
                    <br />
                    บริษัทฯ ไม่มีเจตนาที่จะเก็บ ข้อมูลส่วนบุคคลที่มีความอ่อนไหว (“<b>ข้อมูลที่มีความอ่อนไหวฯ</b>”)
                    ของท่าน อย่างไรก็ดี ในกรณีที่บริษัทฯ ดำเนินการดังกล่าว บริษัทฯ จะเก็บ ใช้ และ/หรือ
                    เปิดเผยข้อมูลที่มีความอ่อนไหวฯ บนฐานของความยินยอมโดยชัดแจ้งจากท่าน เช่น ประวัติการแพ้ยา
                    ประวัติการแพ้อาหาร หรือเท่าที่กฎหมายอนุญาตให้กระทำได้เท่านั้น
                    <br />
                    <br />
                    บริษัทฯจะเก็บข้อมูลส่วนบุคคลฯ ของผู้เยาว์ บุคคลเสมือนไร้ความสามารถ และบุคคลไร้ความสามารถ
                    เมื่อผู้ปกครอง ผู้พิทักษ์ หรือผู้อนุบาล (แล้วแต่กรณี) ของบุคคลดังกล่าวได้ให้ความยินยอมแล้วเท่านั้น
                    บริษัทฯไม่มีเจตนาที่จะเก็บข้อมูลส่วนบุคคลฯ ของลูกค้าที่มีอายุต่ำกว่า 20 ปี
                    โดยปราศจากความยินยอมของผู้ปกครองหากกฎหมายกำหนดให้ต้องได้รับความยินยอมจากบุคคลดังกล่าว
                    หรือของลูกค้าที่เป็นบุคคลเสมือนไร้ความสามารถหรือบุคคลไร้ความสามารถโดยปราศจากความยินยอมจากผู้พิทักษ์หรือผู้อนุบาล
                    (แล้วแต่กรณี) ตามกฎหมาย ในกรณีที่บริษัทฯพบว่าบริษัทฯได้เก็บข้อมูลส่วนบุคคลฯ ของผู้ที่มีอายุต่ำกว่า
                    20 ปี โดยปราศจากความยินยอมจากผู้ปกครองตามที่กฎหมายกำหนด หรือของผู้ที่เป็นบุคคลเสมือนไร้ความสามารถ
                    หรือบุคคลไร้ความสามารถโดยปราศจากความยินยอมจากผู้พิทักษ์หรือผู้อนุบาล (แล้วแต่กรณี) โดยมิได้เจตนา
                    บริษัทฯจะลบข้อมูลส่วนบุคคลฯ ดังกล่าวโดยทันที เว้นแต่บริษัทฯจะสามารถอาศัยฐานทางกฎหมายอื่น
                    นอกเหนือจากความยินยอม
                    <br />
                    <br />
                    <b>2. ทำไมบริษัทฯจึงเก็บ ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคลฯ ของท่าน</b>
                    <br />
                    บริษัทฯอาจเก็บ ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลฯ ของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้
                    <br />
                    <br />
                    <b>2.1 วัตถุประสงค์ตามที่ท่านได้ให้ความยินยอมไว้</b>
                    <br />
                    บริษัทฯอาศัยความยินยอมของท่านในการเก็บ ใช้ และ/หรือ เปิดเผยข้อมูลส่วนบุคคลฯ ของท่าน โดยบริษัทฯ
                    บริษัทในกลุ่ม บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ จะใช้ข้อมูลส่วนบุคคลฯ ของท่าน
                    เพื่อวัตถุประสงค์ดังต่อไปนี้
                    <br />
                    <ol>
                      <li>
                        <b>การตลาดและการติดต่อสื่อสาร</b>เพื่อการติดต่อสื่อสารด้านการตลาด การให้ข้อมูล ข้อเสนอพิเศษ
                        เอกสารส่งเสริมการขาย การตลาดทางโทรศัพท์ สิทธิพิเศษ การโฆษณา จดหมายข่าว
                        การตลาดและการติดต่อสื่อสารอื่นใด ทั้งช่องทางออนไลน์และออฟไลน์ เกี่ยวกับสินค้าและบริการจากบริษัทฯ
                        บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ ซึ่งบริษัทฯไม่สามารถอาศัยฐานทางกฎหมายอื่น ๆ
                        ได้
                      </li>
                      <li>
                        <b>การวิเคราะห์ข้อมูล</b>: เพื่อให้บริการวิเคราะห์ข้อมูล
                        <br />
                        หากท่านไม่ประสงค์ให้ความยินยอม หรือต้องการเพิกถอนความยินยอมของท่าน เพื่อวัตถุประสงค์ข้างต้น
                        อาจทำให้บริษัทฯไม่สามารถให้บริการของบริษัทฯแก่ท่านได้
                      </li>
                    </ol>
                    <br />
                    <b>2.2 วัตถุประสงค์โดยอาศัยเหตุทางกฎหมายเพื่อดำเนินการกับข้อมูลส่วนบุคคลฯ ของท่าน</b>
                    <br />
                    บริษัทฯ อาจอาศัย (1) ฐานสัญญา เพื่อการเริ่มต้นหรือการปฏิบัติตามสัญญาต่อท่าน (2) ฐานหน้าที่ตามกฎหมาย
                    เพื่อการปฏิบัติหน้าที่ตามกฎหมายของบริษัทฯ (3) ฐานประโยชน์อันชอบธรรม
                    เพื่อประโยชน์อันชอบธรรมของบริษัทฯและบุคคลที่สาม ทั้งนี้
                    บริษัทฯจะดำเนินการให้เกิดความสมดุลระหว่างประโยชน์อันชอบธรรมของบริษัทฯและบุคคลที่สามที่เกี่ยวข้อง
                    กับผลประโยชน์ของท่าน รวมถึงสิทธิและเสรีภาพขั้นพื้นฐานเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลฯ ของท่าน
                    (4) ฐานประโยชน์สำคัญต่อชีวิต เพื่อการป้องกันหรือยับยั้งอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคลใด
                    ๆ และ/หรือ (5) ฐานประโยชน์สาธารณะ เพื่อการดำเนินภารกิจ เพื่อประโยชน์สาธารณะ
                    หรือเพื่อการใช้อำนาจของเจ้าหน้าที่ ทั้งนี้ เพื่อวัตถุประสงค์ดังต่อไปนี้
                    <ol>
                      <li>
                        <b>เพื่อให้สินค้าและบริการแก่ท่าน</b>:
                        เพื่อลงทะเบียนและให้ท่านสามารถใช้สินค้าหรือบริการของบริษัทฯ ซึ่งรวมถึงแต่ไม่จำกัดเพียง
                        เพื่อให้ท่านสามารถใช้เว็บไซต์ แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ และแพลตฟอร์มของบริษัทฯ
                        เพื่อเข้าทำสัญญาและจัดการนิติสัมพันธ์ระหว่างบริษัทฯ กับท่าน เพื่อสนับสนุนและดำเนินกิจกรรมอื่น ๆ
                        เกี่ยวกับบริการหรือสินค้าดังกล่าว เพื่อขายสินค้าหรือบริการของบริษัทฯ
                        ผ่านช่องทางออนไลน์และออฟไลน์ เพื่อค้าส่งสินค้าหรือบริการของบริษัทฯ แก่ลูกค้าองค์กรของบริษัทฯ
                        เพื่อให้บริการสื่อออนไลน์และการตลาดดิจิทัล
                        เพื่อประมวลธุรกรรมระหว่างท่านและคู่ค้าทางธุรกิจของบริษัทฯ
                        เพื่อจัดการการจองและเพื่อการดำเนินธุรกรรมทางการเงิน ตลอดจนบริการที่เกี่ยวข้องกับการชำระเงิน
                        ซึ่งรวมถึงการตรวจสอบ การพิสูจน์ยืนยัน และการยกเลิกธุรกรรม เพื่อดำเนินการเกี่ยวกับคำสั่งจอง
                        การจัดส่ง การระงับชั่วคราว การทดแทน การชดเชยเงินคืน การคืน
                        และการแลกเปลี่ยนสินค้าหรือบริการของท่าน และเพื่อให้การบริการลูกค้า
                      </li>
                      <li>
                        <b>เพื่อการติดต่อสื่อสารด้านการตลาด</b>: เพื่อการติดต่อสื่อสารด้านการตลาด การให้ข้อมูล
                        ข้อเสนอพิเศษ เอกสารส่งเสริมการขาย การตลาดทางโทรศัพท์ สิทธิพิเศษ การโฆษณา จดหมายข่าว
                        การตลาดและการติดต่อสื่อสารอื่นใด ทั้งช่องทางออนไลน์และออฟไลน์ เกี่ยวกับสินค้าและบริการจากบริษัทฯ
                        บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจ
                      </li>
                      <li>
                        <b>
                          เพื่อเสนอโปรโมชั่น ข้อเสนอพิเศษ โปรแกรมสะสมแต้มและของรางวัล การจับรางวัล การแข่งขัน
                          และข้อเสนออื่น ๆ แก่ท่าน
                        </b>
                        : เพื่อให้ท่านสามารถเข้าร่วมโปรโมชั่น แคมเปญส่งเสริมการขาย ข้อเสนอพิเศษ ข้อเสนอส่งเสริมการขาย
                        โปรแกรมสะสมแต้ม โปรแกรมลงทะเบียนร่วมกับคู่ค้าทางธุรกิจของบริษัทฯ การชิงโชค สิทธิพิเศษ
                        การจับรางวัล การแข่งขัน และข้อเสนออื่น ๆ (เช่น เพื่อส่งอีเมลแจ้งเตือน) งานอีเว้นท์ และงานสัมมนา
                        ทั้งนี้ รวมถึงเพื่อดำเนินการและบริหารจัดการด้านการลงทะเบียนบัญชี การลงทะเบียนรับของขวัญ
                        การลงทะเบียนเข้างานอีเว้นท์ของท่าน เพื่อประมวลผลการเก็บ เพิ่ม แลกเปลี่ยน ได้รับ แลก
                        และโอนคะแนนสะสม เพื่อประเมินประวัติการใช้งานทั้งหมดของท่านทั้งทางออนไลน์และออฟไลน์
                        และเพื่อให้และออกบัตรกำนัล บัตรของขวัญ และใบแจ้งหนี้
                      </li>
                      <li>
                        <b>เพื่อติดต่อสื่อสารกับท่าน</b>: เพื่อให้ข้อมูล สื่อสารทางการตลาด แคมเปญโฆษณา โฆษณา
                        การแจ้งเตือนที่จำเป็น ข้อเสนอพิเศษ สิทธิประโยชน์
                        และเอกสารส่งเสริมการขายสินค้าหรือบริการของบริษัทฯแก่ท่าน เพื่อส่งข่าวสาร
                        จดหมายข่าวอิเล็กทรอนิกส์ ข้อความการตลาด และข้อมูลเกี่ยวกับสินค้า บริการ แบรนด์
                        และการดำเนินงานแก่ท่าน
                      </li>
                      <li>
                        <b>เพื่อจัดการความสัมพันธ์ของบริษัทฯกับท่าน</b>:
                        เพื่อติดต่อสื่อสารกับท่านเกี่ยวกับสินค้าและบริการที่ท่านได้รับจากบริษัทฯ บริษัทในเครือ
                        และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทฯ เพื่อดูแลบริการลูกค้า Call Center และ/หรือ
                        สายด่วนตอบข้อซักถาม คำขอ ผลตอบรับ ข้อร้องเรียน ข้อเรียกร้อง ข้อพิพาท หรือการชดเชยค่าเสียหาย
                        เพื่อให้ความช่วยเหลือทางเทคนิคและจัดการกับปัญหาทางเทคนิค เพื่อประมวลผลและปรับปรุงข้อมูลของท่าน
                        และเพื่ออำนวยความสะดวกแก่ท่านในการใช้สินค้าและบริการ
                      </li>
                      <li>
                        <b>เพื่อการตรวจสอบ แจกแจง และวิเคราะห์ข้อมูล</b>:
                        เพื่อวัดการมีส่วนร่วมของท่านกับสินค้าและบริการ, เพื่อตรวจสอบและจับคู่ข้อมูล
                        แจกแจงคุณลักษณะของข้อมูล และวิเคราะห์ข้อมูล เพื่อวิจัยด้านการตลาด สำรวจ ประเมิน พฤติกรรม สถิติ
                        และการแบ่งส่วนตลาด แนวโน้มและรูปแบบการบริโภค เพื่อรู้จักท่านให้มากขึ้นและเข้าใจลักษณะของท่าน
                        เพื่อพัฒนาการดำเนินงานทางธุรกิจ เพื่อปรับปรุงเนื้อหาของบริษัทฯ
                        ให้เข้ากับความชื่นชอบเฉพาะตัวของลูกค้าของบริษัทฯได้ดียิ่งขึ้น
                        เพื่อประเมินประสิทธิภาพแคมเปญส่งเสริมการขายของบริษัทฯ
                        เพื่อระบุและแก้ปัญหาในสินค้าและบริการที่มีอยู่ในปัจจุบัน
                        เพื่อเสริมการพัฒนาข้อมูลเชิงคุณภาพให้ดีขึ้น
                      </li>
                      <li>
                        <b>เพื่อคัดสรรและเสนอสินค้าหรือบริการที่น่าจะอยู่ในความสนใจและเหมาะสมกับความต้องการของท่าน</b>
                        เพื่อให้บริษัทฯ บริษัทในเครือ และบริษัทย่อย
                        ตลอดจนคู่ค้าทางธุรกิจของบริษัทฯใช้ผลลัพธ์จากการตรวจสอบและจับคู่ข้อมูล
                        การแจกแจงคุณลักษณะของข้อมูล และการวิเคราะห์ข้อมูลจากข้อมูลส่วนบุคคลฯ ของท่าน
                        เพื่อนำเสนอสินค้าและบริการที่อาจอยู่ในความสนใจแก่ท่าน
                        เพื่อระบุความชื่นชอบของท่านและมอบประสบการณ์แบบเฉพาะตัวให้กับท่าน
                        และเพื่อพัฒนาเนื้อหาบทความในอนาคตให้ตรงกับความสนใจของท่าน
                      </li>
                      <li>
                        <b>เพื่อพัฒนาการดำเนินงานทางธุรกิจ สินค้า และบริการ</b>: เพื่อประเมินผล พัฒนา จัดการ
                        และปรับปรุงบริการ สินค้า ระบบ และการดำเนินงานทางธุรกิจที่มีอยู่ในปัจจุบัน ตลอดจนออกแบบบริการ
                        สินค้า ระบบ และการดำเนินงานทางธุรกิจใหม่ ให้แก่ท่านและลูกค้าทั้งหมดของบริษัทฯ
                        ซึ่งรวมถึงแต่ไม่จำกัดเพียง บริษัทในเครือ และบริษัทย่อย ตลอดจนคู่ค้าทางธุรกิจของบริษัทฯ
                        เพื่อติดตามผลธุรกรรมการจองขายของท่าน (การติดตามการขาย) เพื่อพัฒนาการบริการของบริษัทฯ
                        เพื่อระบุและแก้ไขปัญหาต่าง ๆ เพื่อจัดทำรายงานรวมแบบไม่ระบุชื่อ
                        และวัดผลการดำเนินงานของสินค้าทางกายภาพ ทรัพย์สินดิจิทัล และแคมเปญการตลาด และเพื่อจัดการ
                        ดำเนินการ และดูแลรักษาระบบการชำระเงินของบริษัทฯ ทั้งนี้ อาจมีการฟังการสนทนา และ/หรือ
                        บันทึกสายการสนทนาทางโทรศัพท์ระหว่างบริษัทฯ กับท่านเพื่อให้บริการ รวมทั้งฝึกอบรมพนักงานของบริษัทฯ
                        และพัฒนาการให้บริการของบริษัทฯ
                      </li>
                      <li>
                        <b>เพื่อรู้จักท่านให้มากขึ้น</b>: เพื่อเรียนรู้เกี่ยวกับสินค้าและบริการที่ท่านใช้
                        และสินค้าและบริการอื่น ๆ ที่ท่านอาจสนใจรับให้มากยิ่งขึ้น
                        ซึ่งรวมถึงการแจกแจงคุณลักษณะจากการประมวลผลข้อมูลส่วนบุคคลฯ ของท่าน เช่น
                        พิจารณาจากประเภทของสินค้าและบริการที่ท่านได้รับจากบริษัทฯ
                        ความพึงพอใจของท่านที่จะได้รับการติดต่ออย่างไร เป็นต้น
                      </li>
                      <li>
                        <b>
                          เพื่อประสิทธิภาพในการทำงานของเว็บไซต์ แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่
                          และแพลตฟอร์มของบริษัทฯ
                        </b>
                        : เพื่อบริหารจัดการ ดำเนินการ ติดตาม สังเกตุ และจัดการเว็บไซต์
                        แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่
                        และแพลตฟอร์มของบริษัทฯเพื่อช่วยอำนวยความสะดวกและสามารถทำงานได้อย่างเหมาะสม มีประสิทธิภาพ
                        และปลอดภัย เพื่ออำนวยความสะดวกและทำให้ประสบการณ์ของท่านในการใช้เว็บไซต์
                        แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ และแพลตฟอร์มของบริษัทฯดียิ่งขึ้น
                        และเพื่อพัฒนาแผนผังและเนื้อหาเว็บไซต์ แอปพลิเคชันสำหรับอุปกรณ์เคลื่อนที่ และแพลตฟอร์มของบริษัทฯ
                      </li>
                      <li>
                        <b>เพื่อจัดการระบบเทคโนโลยีสารสนเทศ</b>: เพื่อวัตถุประสงค์ในการจัดการธุรกิจของบริษัทฯ
                        ซึ่งรวมถึงการดำเนินงานเทคโนโลยีสารสนเทศของบริษัทฯ การจัดการระบบการสื่อสาร
                        การดำเนินการและการตรวจสอบการรักษาความปลอดภัยด้านเทคโนโลยีสารสนเทศ
                        เพื่อการจัดการธุรกิจภายในตามข้อกำหนด นโยบาย และขั้นตอนเกี่ยวกับการกำกับดูแลกิจการภายใน
                        และเพื่ออัพเดทฐานข้อมูลของบริษัทฯ
                      </li>
                      <li>
                        <b>เพื่อปฏิบัติหน้าที่ตามหลักเกณฑ์และการกำกับดูแล</b>: เพื่อปฏิบัติหน้าที่ตามกฎหมาย
                        กระบวนการทางกฎหมาย หรือคำสั่งของเจ้าหน้าที่รัฐ
                        ซึ่งอาจรวมถึงคำสั่งของเจ้าหน้าที่รัฐภายนอกประเทศไทย และ/หรือ ให้ความร่วมมือกับศาล
                        หน่วยงานกำกับดูแล เจ้าหน้าที่รัฐ และหน่วยงานที่บังคับใช้กฎหมาย เมื่อมีเหตุอันควรเชื่อได้ว่า
                        บริษัทฯมีหน้าที่ตามกฎหมายที่จะต้องปฏิบัติตาม และเมื่อการเปิดเผยข้อมูลส่วนบุคคลฯ
                        ของท่านเป็นความจำเป็นอย่างยิ่งยวดเพื่อปฏิบัติตามหน้าที่ตามกฎหมาย กระบวนการทางกฎหมาย
                        หรือคำสั่งของรัฐดังกล่าว ซึ่งหมายรวมถึง การออกใบกำกับภาษีหรือแบบแสดงรายการภาษี
                        เพื่อปฏิบัติหน้าที่ตามกฎหมายที่เกี่ยวข้องกับธุรกิจการชำระเงินอิเล็กทรอนิกส์ (e-Payment) การเงิน
                        เพื่อบันทึกและดูแลการติดต่อสื่อสาร เพื่อการเปิดเผยต่อเจ้าหน้าที่สรรพากร
                        หน่วยงานกำกับดูแลธุรกิจให้บริการทางการเงิน หรือหน่วยงานที่มีหน้าที่กำกับดูแลและหน่วยงานอื่น ๆ
                        ของรัฐ ตลอดจนเพื่อการสืบสวนหรือป้องกันอาชญากรรม
                      </li>
                      <li>
                        <b>เพื่อปกป้องประโยชน์ของบริษัทฯ</b>:
                        เพื่อรักษาความปลอดภัยและจริยธรรมในการดำเนินธุรกิจของบริษัทฯ
                        เพื่อใช้สิทธิหรือปกป้องประโยชน์ของบริษัทฯเมื่อมีความจำเป็นและกฎหมายอนุญาตให้กระทำได้
                        เพื่อติดตามเหตุการณ์ต่าง ๆ
                        เพื่อป้องกันและรายงานการกระทำความผิดทางอาญาและเพื่อรักษาความปลอดภัยและจริยธรรมในธุรกิจของบริษัทฯ{' '}
                      </li>
                      <li>
                        <b>เพื่อสืบหา ยับยั้ง และป้องกันการฉ้อฉล/การกระทำผิดกฎหมาย</b>:
                        เพื่อพิสูจน์และยืนยันตัวตนของท่าน และเพื่อตรวจสอบการปฏิบัติตามกฎหมายและหลักเกณฑ์อื่น ๆ (เช่น
                        เพื่อปฏิบัติตามกฎหมายและหลักเกณฑ์ที่เกี่ยวกับธุรกิจการชำระเงินอิเล็กทรอนิกส์ (e-Payment) การเงิน
                        และเพื่อป้องกันการฉ้อฉลตลอดจนธุรกรรมที่ต้องสงสัย) การตรวจสอบและบันทึกภายใน
                        การบริหารจัดการทรัพย์สิน ระบบและมาตรการควบคุมทางธุรกิจอื่น ๆ
                      </li>
                      <li>
                        <b>เพื่อโอนในกรณีที่มีการควบรวมกิจการ</b>: เมื่อมีการขาย โอน ควบรวมกิจการ ปรับโครงสร้างองค์กร
                        หรือเหตุการณ์ในทำนองเดียวกัน บริษัทฯ อาจมีการถ่ายโอนข้อมูลส่วนบุคคลฯ
                        ของท่านไปยังบุคคลอื่นใดซึ่งเป็นส่วนหนึ่งของการดำเนินธุรกรรมดังกล่าว{' '}
                      </li>
                      <li>
                        <b>ความเสี่ยง</b>: เพื่อดำเนินการบริหารจัดการความเสี่ยง ตรวจสอบ และประเมินความเสี่ยง
                        เพื่อตรวจสอบเครดิตและสอบทานสถานะทางการเงินของลูกค้า
                      </li>
                      <li>
                        <b>ชีวิต</b>: เพื่อป้องกันหรือยับยั้งอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคลใด ๆ
                      </li>
                    </ol>
                    เมื่อบริษัทฯ มีความจำเป็นต้องเก็บข้อมูลส่วนบุคคลฯ
                    ของท่านเพื่อการปฏิบัติหน้าที่ตามกฎหมายหรือเพื่อการเข้าทำสัญญากับท่าน
                    หากท่านไม่ประสงค์ให้ข้อมูลส่วนบุคคลฯ ของท่านกับบริษัทฯ เมื่อมีการร้องขอ บริษัทฯ อาจไม่สามารถให้
                    (หรืออาจต้องยุติการให้) สินค้าและบริการของบริษัทฯ แก่ท่าน
                    <br />
                    <br />
                    <b>3. บุคคลที่บริษัทฯอาจเปิดเผยหรือถ่ายโอนข้อมูลส่วนบุคคลฯ ของท่าน</b>
                    <br />
                    บริษัทฯอาจเปิดเผยหรือถ่ายโอนข้อมูลส่วนบุคคลฯ
                    ของท่านให้แก่บุคคลที่สามดังต่อไปนี้เพื่อการดำเนินการกับข้อมูลส่วนบุคคลฯ
                    ตามวัตถุประสงค์ภายใต้นโยบายความเป็นส่วนตัวฯ ฉบับนี้ อนึ่ง
                    บุคคลที่สามดังกล่าวอาจมีภูมิลำเนาอยู่ในหรือนอกราชอาณาจักรไทย
                    ท่านสามารถอ่านนโยบายความเป็นส่วนตัวของบุคคลที่สามเหล่านี้เพื่อศึกษารายละเอียดเพิ่มเติมว่าข้อมูลส่วนบุคคลฯ
                    ของท่านได้ถูกดำเนินการอย่างไร
                    <br />
                    <br />
                    <b>3.1 ผู้ให้บริการของบริษัทฯ</b>
                    <br />
                    บริษัทฯอาจใช้บริษัท ตัวแทน
                    หรือผู้รับจ้างรายอื่นเพื่อให้บริการในนามของบริษัทฯหรือเพื่อช่วยบริษัทฯในการนำส่งสินค้าและบริการให้แก่ท่าน
                    ดังนั้น บริษัทฯอาจแบ่งปันข้อมูลส่วนบุคคลฯ ให้แก่บุคคลดังนี้ ซึ่งรวมถึงแต่ไม่จำกัดเพียง (1)
                    ผู้ให้บริการและพัฒนาด้านโครงสร้างพื้นฐาน ซอฟต์แวร์ เว็บไซต์ และเทคโนโลยีสารสนเทศ (2)
                    ผู้ให้บริการทางคมนาคม (3) ผู้ให้บริการพื้นที่จัดเก็บข้อมูลและระบบคลาวด์ (4) ผู้ให้บริการตรวจสอบ
                    แจกแจง และวิเคราะห์ข้อมูล (5) ตัวแทนหรือเอเจนซี่การตลาด การโฆษณา และการสื่อสาร (6) หน่วยงานวิจัย (7)
                    หน่วยงานสำรวจ (8) ผู้จัดแคมเปญโฆษณาและงานอีเว้นท์ (9) ผู้ให้บริการทางโทรศัพท์ (Call Center) (10)
                    ผู้ให้บริการการชำระเงิน ระบบการชำระเงิน การพิสูจน์ตัวตน (11) ผู้รับดูแลงานด้านการบริหารจัดการ
                    และ/หรือ (12) ผู้ประกอบธุรกิจด้านโทรคมนาคมและการสื่อสาร
                    <br />
                    <br />
                    ภายในช่วงระยะเวลาที่ให้บริการ ผู้ให้บริการดังกล่าวอาจมีการเข้าถึงข้อมูลส่วนบุคคลฯ ของท่าน
                    อย่างไรก็ดี บริษัทฯจะให้ผู้ให้บริการดังกล่าวเข้าถึงข้อมูลส่วนบุคคลฯ
                    เท่าที่จำเป็นเพื่อการให้บริการเท่านั้น โดยบริษัทฯจะขอให้ผู้ให้บริการไม่ใช้ข้อมูลส่วนบุคคลฯ
                    ของท่านเพื่อวัตถุประสงค์อื่นใด ทั้งนี้ บริษัทฯ
                    จะดูแลผู้ให้บริการทุกรายที่บริษัทฯทำงานด้วยจะรักษาข้อมูลส่วนบุคคลฯ ของท่านอย่างปลอดภัย
                    <br />
                    <br />
                    <b>3.2 คู่ค้าทางธุรกิจของบริษัทฯ</b>
                    <br />
                    บริษัทฯ อาจโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังคู่ค้าทางธุรกิจของบริษัทฯ
                    ซึ่งรวมถึงแต่ไม่จำกัดเพียงผู้ให้บริการการตลาดดิจิทัล ผู้ให้บริการด้านการธนาคารและการเงิน
                    ผู้ประกอบธุรกิจโปรแกรมสะสมแต้มและของรางวัล ผู้ให้บริการด้านการประกันภัย
                    ผู้ประกอบธุรกิจโทรคมนาคมและการสื่อสาร ผู้ประกอบอาชีพทางการเงิน
                    ผู้ให้บริการด้านการบริหารจัดการทรัพย์สิน ผู้ให้บริการด้านการลงทุน ผู้ประกอบการค้าปลีก
                    ผู้ประกอบการด้านพาณิชย์อิเล็กทรอนิกส์ ผู้ลงทุน คู่ค้าในโปรแกรมร่วมลงทะเบียน
                    คู่ค้าที่บริษัทฯแนะนำซึ่งอาจร่วมกับบริษัทฯในการเสนอสินค้าหรือบริการ คู่ค้าที่มีสมาชิกร่วม
                    ผู้ให้บริการกิจกรรมทางการตลาดแบบ Lead Generation คู่ค้าทางธุรกิจที่บริษัทฯ
                    ให้บริการกิจกรรมทางการตลาดแบบ Lead Generation หน่วยงานวิจัย
                    คู่ค้าที่สำรวจตลาดรวมถึงลูกค้าของคู่ค้านั้น ตัวแทนหรือเอเจนซี่สื่อโฆษณา เพื่อทำการวิจัยด้านการตลาด
                    สำรวจ ประเมิน พฤติกรรม สถิติ และการแบ่งส่วนตลาด แนวโน้มและรูปแบบการบริโภค
                    ผู้รายงานเพื่อโฆษณาแคมเปญการตลาดของบริษัทฯ ผู้ประกอบอาชีพทางการเงินอิสระ
                    ซึ่งให้ใบเสนอราคาสำหรับสินค้าหรือบริการทางการเงิน
                    โดยที่คู่ค้าทางธุรกิจผู้รับข้อมูลนั้นตกลงที่จะปฏิบัติต่อข้อมูลส่วนบุคคลฯ
                    ของท่านในลักษณะที่สอดคล้องกับนโยบายความเป็นส่วนตัวฯ ฉบับนี้
                    <br />
                    <br />
                    <b>3.3 เว็บไซต์เครือข่ายสังคม </b>
                    <br />
                    กรณีท่านลงชื่อเข้าใช้งานโดยใช้ระบบการลงชื่อเข้าใช้งานของเว็บไซต์เครือข่ายสังคม
                    ถือว่าท่านได้ให้อำนาจโดยชัดแจ้งแก่บริษัทฯในการเข้าถึงและจัดเก็บข้อมูลที่เป็นสาธารณะในบัญชีเครือข่ายสังคมของท่าน
                    (เช่น เฟซบุ๊ก กูเกิล อินสตาแกรม)
                    รวมทั้งข้อมูลอื่นที่เกิดขึ้นระหว่างการใช้ระบบลงชื่อเข้าใช้งานจากเครือข่ายสังคมดังกล่าว นอกจากนี้
                    บริษัทฯอาจมีการสื่อสารอีเมลของท่านไปยังเครือข่ายสังคม
                    เพื่อพิจารณาว่าท่านเป็นผู้ใช้งานในเครือข่ายสังคมนั้นอยู่แล้วหรือไม่
                    และเพื่อแสดงโฆษณาบนบัญชีเครือข่ายสังคมของท่านเมื่อเหมาะสม
                    <br />
                    <br />
                    บริษัทฯ
                    อาจมีความร่วมมือกับบุคคลที่สามที่อนุญาตให้ท่านลงทะเบียนเข้าใช้บริการหรือเข้าร่วมโปรโมชั่นของบุคคลดังกล่าว
                    ตัวอย่างเช่น บางบริษัทอนุญาตให้ท่านใช้หมายเลขโปรแกรมสะสมแต้ม
                    หรือชื่อเข้าใช้บริการออนไลน์ของท่านเพื่อรับหรือลงทะเบียนเพื่อรับบริการของบุคคลที่สามดังกล่าว
                    นอกจากนี้
                    ผู้ให้บริการบัญชีเครือข่ายสังคมของท่านยังได้ยินยอมให้ท่านเชื่อมต่อบัญชีเครือข่ายสังคมของท่านเข้ากับบัญชีบริการออนไลน์ของท่านหรือลงชื่อเข้าใช้บัญชีบริการออนไลน์ของท่านจากบัญชีเครือข่ายสังคมของท่าน
                    เมื่อท่านลงทะเบียนเข้ารับบริการเหล่านั้น บริษัทฯจะเปิดเผยข้อมูลส่วนบุคคลฯ
                    ของท่านต่อบุคคลที่สามดังกล่าว หากท่านไม่ประสงค์ที่จะแบ่งปันข้อมูลส่วนบุคคลฯ ของท่านในลักษณะเช่นนี้
                    โปรดอย่าให้หมายเลขหรือชื่อบัญชีโปรแกรมสะสมแต้มหรือรางวัลแก่บุคคลที่สาม
                    อย่าใช้บัญชีบริการออนไลน์เพื่อลงทะเบียนรับโปรโมชั่นของบุคคลที่สาม
                    และอย่าเชื่อมต่อบัญชีบริการออนไลน์ของท่านกับบัญชีบริการของบุคคลที่สาม
                    ข้อมูลที่มีการแบ่งปันกันในลักษณะนี้จะอยู่ภายใต้นโยบายความเป็นส่วนตัวของบุคคลที่สาม
                    และไม่อยู่ภายใต้นโยบายความเป็นส่วนตัวฯ ฉบับนี้
                    <br />
                    <br />
                    <b>3.4 บุคคลที่สามที่ได้รับอนุญาตตามกฎหมาย</b>
                    <br />
                    ในบางกรณี บริษัทฯอาจต้องเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลฯ
                    ของท่านเพื่อปฏิบัติหน้าที่ตามกฎหมายหรือข้อกำหนด ซึ่งหมายความรวมถึงต่อหน่วยงานที่บังคับใช้กฎหมาย ศาล
                    ผู้กำกับดูแล เจ้าหน้าที่ของรัฐ
                    หรือบุคคลอื่นใดที่บริษัทฯเชื่อว่าจำเป็นเพื่อปฏิบัติหน้าที่ตามกฎหมายหรือข้อกำหนด
                    หรือเพื่อปกป้องสิทธิของบริษัทฯ สิทธิของบุคคลที่สาม หรือความปลอดภัยของบุคคลใด ๆ หรือเพื่อสืบหา
                    ป้องกัน หรือจัดการกับปัญหาการฉ้อโกง ความมั่นคง หรือความปลอดภัย
                    <br />
                    <br />
                    <b>3.5 ที่ปรึกษาวิชาชีพ</b>
                    <br />
                    หมายรวมถึงนักกฎหมายและผู้ตรวจสอบบัญชีซึ่งมีส่วนช่วยเหลือในการดำเนินธุรกิจและต่อสู้หรือหยิบยกข้อเรียกร้องทางกฎหมายอย่างหนึ่งอย่างใด
                    <br />
                    <br />
                    <b>3.6 บุคคลที่สามที่เกี่ยวข้องกับการโอนธุรกิจ</b>
                    <br />
                    บริษัทฯ อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลฯ ของท่านไปยังคู่ค้าทางธุรกิจ ผู้ลงทุน
                    ผู้ถือหุ้นที่มีนัยสำคัญ หรือผู้รับโอนของบริษัทฯ ในกรณีที่มีการปรับโครงสร้างองค์กร
                    การเปลี่ยนแปลงโครงสร้างบริษัท การควบรวมกิจการ การเข้าจองกิจการ การขาย การจอง การร่วมทุน การโอน
                    หรือเหตุการณ์อื่นใดในทำนองเดียวกันที่มีการโอนหรือการจำหน่ายไปซึ่งธุรกิจ ทรัพย์สิน หรือหุ้นของบริษัทฯ
                    ไม่ว่าทั้งหมดหรือแต่บางส่วน ทั้งนี้ หากเกิดเหตุการณ์หนึ่งเหตุการณ์ใดข้างต้น
                    ฝ่ายผู้รับข้อมูลจะปฏิบัติต่อข้อมูลส่วนบุคคลฯ ของท่านตามนโยบายความเป็นส่วนตัวฯ ฉบับนี้
                    <br />
                    <br />
                    <b>4. การถ่ายโอนข้อมูลส่วนบุคคลฯ ระหว่างประเทศ</b>
                    <br />
                    บริษัทฯ อาจเปิดเผยหรือโอนข้อมูลส่วนบุคคลฯ
                    ของท่านไปยังบุคคลที่สามหรือเซิร์ฟเวอร์ที่ตั้งอยู่ในต่างประเทศ
                    ซึ่งประเทศปลายทางอาจมีหรือไม่มีมาตรฐานการคุ้มครองข้อมูลในระดับเดียวกัน บริษัทฯ
                    ได้ดำเนินการตามขั้นตอนและมาตรการต่าง ๆ เพื่อให้มั่นใจว่าข้อมูลส่วนบุคคลฯ ของท่านได้โอนไปอย่างปลอดภัย
                    ฝ่ายผู้รับข้อมูลมีมาตรฐานการคุ้มครองข้อมูลที่เหมาะสมและเป็นการโอนที่ถูกต้องตามกฎหมาย
                    โดยอาศัยข้อยกเว้นตามกฎหมายที่อนุญาตให้ส่งหรือโอนข้อมูลได้
                    <br />
                    <br />
                    <b>5. บริษัทฯ เก็บรักษาข้อมูลส่วนบุคคลฯ ของท่านไว้เป็นระยะเวลาเท่าใด</b>
                    <br />
                    บริษัทฯเก็บรักษาข้อมูลส่วนบุคคลฯ
                    ของท่านไว้เป็นระยะเวลาเท่าที่จำเป็นตามสมควรเพื่อบรรลุวัตถุประสงค์ที่บริษัทฯได้รับข้อมูล
                    และเพือปฏิบัติหน้าที่ของบริษัทฯตามกฎหมายและข้อกำหนด อย่างไรก็ตาม
                    บริษัทฯอาจจำเป็นต้องเก็บรักษาข้อมูลส่วนบุคคลฯ ของท่านไว้เป็นระยะเวลานานกว่านั้นตามที่กฎหมายกำหนด
                    <br />
                    <br />
                    <b>6. คุกกี้และการใช้คุกกี้</b>
                    <br />
                    หากท่านเยี่ยมชมเว็บไซต์ของบริษัทฯ
                    บริษัทฯจะรวบรวมข้อมูลจากท่านโดยอัตโนมัติโดยใช้เครื่องมือติดตามและคุกกี้ ท(คุกกี้ คือ
                    เทคโนโลยีติดตามที่ใช้ในการวิเคราะห์แนวโน้ม บริหารจัดการเว็บไซต์ของบริษัทฯ
                    ติดตามความเคลื่อนไหวของผู้ใช้งานเว็บไซต์ หรือจดจำการตั้งค่าของผู้ใช้งาน
                    คุกกี้บางส่วนมีความจำเป็นเนื่องจากหากไม่มีคุกกี้เหล่านั้นเว็บไซต์จะไม่สามารถทำงานได้อย่างเหมาะสม
                    สำหรับคุกกี้ส่วนอื่น ๆ มีไว้เพื่อความสะดวกของผู้เยี่ยมชม เช่น
                    คุกกี้ที่จดจำชื่อผู้ใช้งานของท่านอย่างปลอดภัย รวมถึงภาษาที่ท่านใช้อีกด้วย)
                    <br />
                    <br />
                    หากท่านปฏิเสธคุกกี้
                    ความสามารถของท่านในการใช้งานเว็บไซต์ของบริษัทฯบางฟังก์ชั่นหรือบางหน้าหรือทั้งหมดอาจถูกจำกัด
                    <br />
                    <br />
                    <b>7. สิทธิของท่านในฐานะเจ้าของข้อมูล</b>
                    <br />
                    ภายใต้บังคับของกฎหมายและข้อยกเว้นตามกฎหมาย ท่านอาจมีสิทธิดังต่อไปนี้
                    <br />
                    <ol>
                      <li>
                        <b>การเข้าถึง</b>: ท่านอาจมีสิทธิเข้าถึงหรือขอสำเนาข้อมูลส่วนบุคคลฯ
                        เกี่ยวกับท่านที่บริษัทฯดำเนินการอยู่ ทั้งนี้ เพื่อความเป็นส่วนตัวและความปลอดภัยของตัวท่านเอง
                        บริษัทฯอาจขอให้ท่านพิสูจน์ยืนยันตัวตนก่อนให้ข้อมูลส่วนบุคคลฯ ที่ท่านขอแก่ท่าน
                      </li>
                      <li>
                        <b>การแก้ไขให้ถูกต้อง</b>: หากพบว่าข้อมูลส่วนบุคคลฯ ของท่านที่บริษัทฯดำเนินการอยู่
                        ซึ่งไม่สมบูรณ์ ไม่ถูกต้อง ทำให้เข้าใจผิด หรือไม่เป็นปัจจุบัน
                        ท่านอาจมีสิทธิแก้ไขข้อมูลดังกล่าวให้ถูกต้อง
                      </li>
                      <li>
                        <b>การขอรับข้อมูล</b>: ท่านอาจมีสิทธิในการได้รับข้อมูลส่วนบุคคลฯ
                        ของท่านที่บริษัทฯมีในรูปแบบอิเล็กทรอนิกส์
                        และสิทธิในการส่งข้อมูลดังกล่าวไปยังผู้ควบคุมข้อมูลส่วนบุคคลรายอื่น ทั้งนี้ เฉพาะ (ก)
                        ข้อมูลส่วนบุคคลฯ ที่ท่านได้ให้ไว้กับบริษัทฯ และ (ข)
                        บริษัทฯได้ดำเนินการกับข้อมูลดังกล่าวนั้นบนฐานความยินยอมจากท่านหรือเพื่อปฏิบัติตามสัญญาต่อท่าน
                      </li>
                      <li>
                        <b>การคัดค้าน</b>: ท่านอาจมีสิทธิคัดค้านการดำเนินการบางอย่างกับข้อมูลส่วนบุคคลฯ ของท่าน เช่น
                        ปฏิเสธการตลาดแบบตรง
                      </li>
                      <li>
                        <b>การจำกัด</b>: ท่านอาจมีสิทธิจำกัดการดำเนินการของบริษัทฯต่อข้อมูลส่วนบุคคลฯ
                        ของท่านหากท่านเชื่อว่าข้อมูลดังกล่าวไม่ถูกต้อง การดำเนินการของบริษัทฯไม่ชอบด้วยกฎหมาย
                        หรือบริษัทฯไม่มีความจำเป็นต้องดำเนินการกับข้อมูลดังกล่าวเพื่อวัตถุประสงค์ใดวัตถุประสงค์หนึ่งอีกต่อไป
                      </li>
                      <li>
                        <b>การถอนความยินยอม</b>:
                        ท่านอาจถอนความยินยอมของท่านในเวลาใดก็ได้สำหรับวัตถุประสงค์ที่ท่านได้ให้ความยินยอมไว้กับบริษัทฯเพื่อดำเนินการกับข้อมูลส่วนบุคคลฯ
                        ของท่าน
                      </li>
                      <li>
                        <b>การลบ</b>: ท่านอาจมีสิทธิขอให้บริษัทฯลบหรือทำให้ข้อมูลส่วนบุคคลฯ
                        ของท่านที่บริษัทฯดำเนินการอยู่เป็นข้อมูลที่ไม่อาจระบุตัวตนได้
                        เว้นแต่เป็นกรณีที่บริษัทฯไม่ได้มีหน้าที่ในการดำเนินการเช่นนั้นหากบริษัทฯต้องเก็บรักษาข้อมูลดังกล่าวไว้เพื่อปฏิบัติหน้าที่ตามกฎหมาย
                        หรือเพื่อการใช้สิทธิทางกฎหมายของบริษัทฯ
                      </li>
                      <li>
                        <b>การยื่นคำร้อง</b>: ท่านอาจมีสิทธิยื่นคำร้องต่อเจ้าหน้าที่ที่มีอำนาจ
                        หากท่านเชื่อว่าการดำเนินการของบริษัทฯต่อข้อมูลส่วนบุคคลฯ
                        ของท่านไม่ชอบด้วยกฎหมายหรือไม่ปฏิบัติตามกฎหมายว่าด้วยการคุ้มครองข้อมูลที่มีผลบังคับใช้
                      </li>
                    </ol>
                    <br />
                    <b>8. รายละเอียดการติดต่อของบริษัทฯ</b>
                    <br />
                    หากท่านประสงค์ที่จะติดต่อบริษัทฯเพื่อใช้สิทธิที่เกี่ยวข้องกับข้อมูลส่วนบุคคลฯ ของท่าน
                    หรือหากท่านมีข้อสอบถามใด ๆ เกี่ยวกับข้อมูลส่วนบุคคลฯ ของท่านภายใต้นโยบายความเป็นส่วนตัวฯ ฉบับนี้
                    กรุณาติดต่อบริษัทฯหรือเจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคลของบริษัทฯได้ที่
                    <br />
                    <ol>
                      <li>
                        บริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด
                        <ol>
                          <li>
                            • บริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด เลขที่ 818/27 เดอะมาสเตอร์แอทบีทีเอสอุดมสุข
                            ถนนอุดมสุข แขวงบางนาเหนือ เขตบางนา กรุงเทพมหานคร 10260
                          </li>
                          <li>• 020169998</li>
                        </ol>
                      </li>
                      <li>
                        เจ้าหน้าที่คุ้มครองข้อมูลส่วนบุคคล
                        <ol>
                          <li>
                            • บริษัท เวิลด์ รีวอร์ด โซลูชั่น จำกัด เลขที่ 818/27 เดอะมาสเตอร์แอทบีทีเอสอุดมสุข
                            ถนนอุดมสุข แขวงบางนาเหนือ เขตบางนา กรุงเทพมหานคร 10260
                          </li>
                          <li>• 020169998</li>
                          <li>• dpo@worldrewardsolutions.com</li>
                        </ol>
                      </li>
                    </ol>
                  </>
                )
              }
            </Translation>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = ({ coupon, popup }) => {
  return { coupon, popup }
}

export default connect(mapStateToProps, {})(PrivacyPolicy)
