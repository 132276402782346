import React, { Component } from 'react'
import PopupLayout from './layoutModal'
import '../../assets/css/app.css'

class SuccessModal extends Component {
  constructor(props) {
    super(props)
    this.state = { selected: 1 }
  }

  render() {
    return (
      <PopupLayout display={this.props.display}>
        <div className="detail-2">
          <div className="modal-header">
            <h5 className="modal-title" id="consent_form_title">
              เสร็จสิ้นเรียบร้อย
            </h5>
          </div>
          <div className="modal-body text-center">
            <h5 className="modal-title"> รหัสอ้างอิงของคุณคือ {this.props.ticketId} </h5>
            <h6>
              {' '}
              โปรดเก็บรหัสอ้างอิงของคุณเพื่อเป็นหลักฐานการสำรองรถ ท่านจะได้รับ SMS และ Email ยืนยันการสำรองรถภายใน 24
              ชั่วโมง{' '}
            </h6>
            <p>
              {' '}
              <span>
                <i className="icon-envelope"></i> booking@worldrewardsolutions.com
              </span>
              <br />
              <i className="icon-phone"></i> Phone: 02-016-9950
              <br />
              <i className="icon-clock"></i> Everyday: 24 Hours
            </p>
          </div>
          <div className="modal-footer text-center d-block pt-0">
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={() => {
                this.props.closePopup()
                window.location.reload()
              }}
            >
              กลับสู่หน้าหลัก
            </button>
          </div>
        </div>
      </PopupLayout>
    )
  }
}

export default SuccessModal
