import { applyMiddleware, compose, createStore } from 'redux'
import reducers from '../reducers'
//import createHistory from "history/createHashHistory";
import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas'

//const history = createHistory();
const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(...middlewares)))
  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

//export { history };
